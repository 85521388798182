/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";

const getRowsVerificacion = (data, handleSelect, handleCheck, solicitudesChecked) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              Solicitud de verificación N° {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
            <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox>
              <Tooltip title={item.estado} placement="top">
                <SoftBadge
                  color={item.estado === "Pendiente" ? "sistemasAmarillo" : "sistemasGris"}
                  badgeContent={item.estado}
                />
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {dayjs(item?.createdAt).format("DD/MM/YYYY")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip title="Ver detalles" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={() => handleSelect(item)}
              >
                arrow_forward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columnsVerificacion = [
  { name: "id", desc: "ID", align: "center", orderField: "id" },
  { name: "nombre", desc: "Nombre", align: "center", orderField: "id" },
  { name: "estado", desc: "Estado", align: "center", width: "1%" },
  { name: "user", desc: "Creado por", align: "left", orderField: "idUser" },
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt" },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columnsVerificacion, getRowsVerificacion };
