import { useState } from "react";
import { Modal, Fade, Backdrop, Card, Grid, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
  py: 4,
};

export default function ModalRechazar({ open, handleClose, functionDelete }) {
    const [motivoRechazo, setMotivoRechazo] = useState("");
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3}>
            <SoftTypography variant="h6">Rechazar presupuesto</SoftTypography>
          </SoftBox>
          <SoftBox px={3}>
            <Grid container justifyContent="start" alignItems="center" pb={1} pt={2}>
              <Grid item xs={12}>
                <SoftTypography variant="h6" fontWeight="light">
                    ¿Esta seguro que quiere rechazar el presupuesto? Presione &quot;Rechazar&quot; para confirmar. 
                </SoftTypography>
              </Grid>
           
              <Grid item xs={12}>
                <InputLabel htmlFor="motivoRechazo">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Motivo del rechazo
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  variant="outlined"
                  name="motivoRechazo"
                  multiline
                  rows={4}
                  value={motivoRechazo}
                  onChange={(event) => setMotivoRechazo(event.target.value)}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3} pt={3}>
            <SoftBox mr={2}>
              <SoftButton variant="gradient" color="dark" onClick={handleClose} circular>
                Cancelar
              </SoftButton>
            </SoftBox>
            <SoftBox>
              <SoftButton variant="gradient" color="error" 
              onClick={()=>{
                functionDelete(motivoRechazo);
                setMotivoRechazo("");
              }}
              circular
              >
                Rechazar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalRechazar.defaultProps = {
  functionDelete: () => {},
};

ModalRechazar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    functionDelete: PropTypes.func,
};
