// React

// Components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// @mui material components
import { Card, CircularProgress, Icon, Tab, Tabs } from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cotizacion from "../components/Cotizacion";
import Fases from "../components/Fases";
import ProyectoDetalles from "../components/ProyectoDetalles";
import ModalEnviarPresupuesto from "../components/ModalEnviarPresupuesto";
import ModalMensajeFinal from "../components/ModalMensajeFinal";

// Data

function EditarPresupuesto() {
  const { state } = useLocation();
  const idProyecto = state?.idProyecto;
  const idPresupuesto = state?.idPresupuesto;
  const [proyecto, setProyecto] = useState();
  const [presupuesto, setPresupuesto] = useState();
  const [proyectoOld, setProyectoOld] = useState();
  const [openModalPresupuestoCreado, setOpenModalPresupuestoCreado] = useState(false);
  const [openModalEnviarPresupuesto, setOpenModalEnviarPresupuesto] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [modalMensajeFinal,setModalMensajeFinal] = useState(false);
  const [textoFinal,setTextoFinal] = useState({
    titulo: "",
    descripcion: "",
  });
  const [step, setStep] = useState(1);
  const steps = [
    {
      title: "Detalles del proyecto",
      icon: "description",
    },
    {
      title: "Fases",
      icon: "format_list_numbered",
    },
    {
      title: "Cotización",
      icon: "price_check",
    },
  ];
  const navigate = useNavigate();

  const { loading: loadingCertificado, data: dataCertificado, refetch } = useQuery(
    gql`
      query getCertificadoLastByProyecto($idProyecto: ID!) {
        certificadoLastByProyecto(idProyecto: $idProyecto) {
          fecha
          porcentajeTotal
          numeroCertificado
          fases {
            id
            nombre
            descripcion
            nombreUnidad
            fechaInicioEstimado
            fechaFinEstimado
            cantidad
            porcentajeGanancia
            subtotal
            porcentajeAvance
            numero
            manoDeObra {
              id
              nombre
              horas
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            materiales {
              id
              producto {
                id
                nombre
                unidadMedida
              }
              nombre
              cantidad
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            concepto {
              id
              nombre
              tipoMoneda
              precio
              cotizacion
              porcentajeAvance
              porcentajeAcumulado
              incParcial
              montoAvance
            }
            presupuesto {
              id
              nombre{
                id
                razonSocial
                nombre
                apellido
              }
              file
              aprobado
              motivoAprobacion
              observaciones
              nroPresupuesto
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
              porcentajeAvance
              porcentajeAcumulado
            }
          }
        }
      }
    `,
    {
      variables: {
        idProyecto: idProyecto,
      }
    },
  );

  const { loading: loadingProyecto, data: dataProyecto } = useQuery(
    gql`
      query getProyecto($id: ID!) {
        proyecto(id: $id) {
          id
          nombre
          direccion
          fechaInicioEstimada
          fechaFinEstimada
          tipo
          tipoMoneda
          cliente {
            id
            nombre
            apellido
            tipoCliente
            razonSocial
          }
          vendedor {
            id
            nombre
            apellido
          }
          responsable {
            id
            nombre
            apellido
          }
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
          sucursal{
            id 
            nombre
            sede{
              id
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        id: idProyecto,
      },
    }
  );

  const { data: dataPresupuesto } = useQuery(
    gql`
      query getPresupuesto($id: ID!) {
        presupuesto(id: $id) {
          __typename @skip(if: true)
          nombreContacto
          numero
          ordenCompra
          nroContrato
          fechaInicioEstimada
          fechaFinEstimada
          terminosPago
          importeDeposito
          ocultarFases
          notasExtra
          fases {
            __typename @skip(if: true)
            id
            idPresupuesto
            nombre
            descripcion
            nombreUnidad
            fechaInicioEstimado
            fechaFinEstimado
            cantidad
            porcentajeGanancia
            subtotal
            numero
            etapa{
              __typename @skip(if: true)
              id
              nombre
            }
            manoDeObra {
              id
              nombre
              horas
              tipoMoneda
              precio
              cotizacion
            }
            materiales {
              id
              producto {
                id
                nombre
                unidadMedida
              }
              nombre
              cantidad
              tipoMoneda
              precio
              cotizacion
            }
            concepto {
              id
              nombre
              tipoMoneda
              precio
              cotizacion
            }
            presupuesto {
              id
              nombre{
                id
                razonSocial
                nombre
                apellido
              }
              file
              aprobado
              motivoAprobacion
              observaciones
              nroPresupuesto
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
            }
          }
        }
      }
    `,
    {
      variables: {
        id: idPresupuesto,
      },
    }
  );

  const [addPresupuestoToProyecto, { loading: loadingSaveAll }] = useMutation(
    gql`
      mutation addPresupuestoToProyecto($input: PresupuestoAddInput!) {
        addPresupuestoToProyecto(input: $input) {
          proyecto {
            id
          }
        }
      }
    `
  );

  const formatoManoDeObra = (manoDeObra) => {
    let manoDeObraFormateada = [];
    manoDeObra?.forEach((item) => {
      manoDeObraFormateada.push({
        nombre: item?.nombre ? item?.nombre : null,
        horas: item?.horas ? parseFloat(item?.horas) : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });

    return manoDeObraFormateada;
  };

  const formatoMateriales = (materiales) => {
    let materialesFormateados = [];
    materiales?.forEach((item) => {
      materialesFormateados.push({
        idProducto: item?.producto ? item?.producto?.id : null,
        nombre: item?.nombre ? item?.nombre : item?.producto?.nombre ? item?.producto?.nombre : null,
        cantidad: item?.cantidad ? item?.cantidad : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });

    return materialesFormateados;
  };

  const formatoConcepto = (conceptos)=>{
    let conceptosFormateados = [];
    conceptos?.forEach((item) => {
      conceptosFormateados.push({
        nombre: item?.nombre ? item?.nombre : null,
        tipoMoneda: item?.tipoMoneda ? item?.tipoMoneda : null,
        precio: item?.precio ? item?.precio : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : 0,
      })
    });
    return conceptosFormateados;
  }

  const formatoPresupuesto = (presupuestos)=>{
    let presupuestosFormateados = [];
    presupuestos?.forEach((item) => {
      const dataPresupuesto = {
        fechaPago: item?.fechaPago ? item?.fechaPago : null,
        tipoMoneda: item?.tipoMoneda ?item?.tipoMoneda : null,
        precio: item?.precio ? parseFloat(item?.precio) : null,
        cotizacion: item?.cotizacion ? parseFloat(item?.cotizacion) : null,
        medioPago: item?.medioPago ? item?.medioPago : null,
        observacionPago: item?.observacionPago ? item?.observacionPago : null,
        montoAnticipo: item?.montoAnticipo ? parseFloat(item?.montoAnticipo) : null,
        porcentajeAnticipo: item?.porcentajeAnticipo ? parseFloat(item?.porcentajeAnticipo) : null,
        nroPresupuesto: item?.nroPresupuesto ? item?.nroPresupuesto : null,
        idProveedor: item?.nombre ? item?.nombre?.id : null,
        nombre: item?.nombre?.razonSocial ? item?.nombre?.razonSocial : item?.nombre?.nombre ? item?.nombre?.nombre+" "+item?.nombre?.apellido : null,
        motivoAprobacion: item?.motivoAprobacion ? item?.motivoAprobacion : null,
        observaciones: item?.observaciones ? item?.observaciones : null,
        aprobado: item?.aprobado ? item?.aprobado : false,
      }

      if(typeof item?.file === 'string' && item?.file){
        presupuestosFormateados.push({
          ...dataPresupuesto,
          urlFile: item?.file ? item.file : null,
        })
      }else if(typeof item?.file === 'object' && item?.file){
        presupuestosFormateados.push({
          ...dataPresupuesto,
          file: item?.file ? item.file : null,
        })
      } else {
        presupuestosFormateados.push(dataPresupuesto)
      }
    });
    return presupuestosFormateados;
  }

  const handleSave = () => {
    let formatoFases = [];
    if (proyecto?.fases.length > 0) {
      proyecto?.fases.forEach((fase) => {
        formatoFases.push({
          nombre: fase?.nombre ?? null,
          descripcion: fase?.descripcion ?? null,
          nombreUnidad: fase?.nombreUnidad ?? null,
          fechaInicioEstimado: fase?.fechaInicioEstimado ?? null,
          fechaFinEstimado: fase?.fechaFinEstimado ?? null,
          cantidad: fase?.cantidad ? Number(fase?.cantidad) : null,
          porcentajeGanancia: fase?.porcentajeGanancia
            ? parseFloat(fase?.porcentajeGanancia)
            : null,
          subtotal: fase?.subtotal ?? null,
          numero: fase?.numero ? Number(fase?.numero) : null,
          idEtapa: fase?.idEtapa ?? null,
          manoDeObra: fase?.manoDeObra?.length > 0 ? formatoManoDeObra(fase?.manoDeObra) : [],
          materiales: fase?.materiales?.length > 0 ? formatoMateriales(fase?.materiales) : [],
          concepto: fase?.concepto?.length > 0 ? formatoConcepto(fase?.concepto) : [],
          presupuesto: fase?.presupuesto?.length > 0 ? formatoPresupuesto(fase?.presupuesto) : [],
        });
      });
    }

    let presupuestoData = {
      ...presupuesto,
    };
    delete presupuestoData.fases;

    addPresupuestoToProyecto({
      variables: {
        input: {
          idProyecto: proyecto?.id,
          etapas: proyecto?.etapas,
          fases: formatoFases,
          presupuesto: presupuestoData,
        },
      },
    })
      .then(() => {
        setTextoFinal({
          titulo: "Presupuesto editado con éxito!",
          descripcion: "El presupuesto fue editado correctamente, puedes ver el estado en la sección de 'Proyectos'.",
        });
        setModalMensajeFinal(true);
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el proyecto", "error");
      }).finally(() => {
        setOpenModalEnviarPresupuesto(false);
      });
  };

  const saveAndSend = () => {

    let formatoFases = [];
    if (proyecto?.fases?.length > 0) {
      proyecto?.fases.forEach((fase) => {
        formatoFases.push({
          nombre: fase?.nombre ?? null,
          descripcion: fase?.descripcion ?? null,
          nombreUnidad: fase?.nombreUnidad ?? null,
          fechaInicioEstimado: fase?.fechaInicioEstimado ?? null,
          fechaFinEstimado: fase?.fechaFinEstimado ?? null,
          cantidad: fase?.cantidad ? Number(fase?.cantidad) : null,
          porcentajeGanancia: fase?.porcentajeGanancia
            ? parseFloat(fase?.porcentajeGanancia)
            : null,
          subtotal: fase?.subtotal ?? null,
          numero: fase?.numero ? Number(fase?.numero) : null,
          idEtapa: fase?.idEtapa ?? null,
          manoDeObra: fase?.manoDeObra?.length > 0 ? formatoManoDeObra(fase?.manoDeObra) : [],
          materiales: fase?.materiales?.length > 0 ? formatoMateriales(fase?.materiales) : [],
          concepto: fase?.concepto?.length > 0 ? formatoConcepto(fase?.concepto) : [],
          presupuesto: fase?.presupuesto?.length > 0 ? formatoPresupuesto(fase?.presupuesto) : [],
        });
      });
    }

    let presupuestoData = {
      ...presupuesto,
    };
    delete presupuestoData.fases;

    addPresupuestoToProyecto({
      variables: {
        input: {
          idProyecto: proyecto?.id,
          etapas: proyecto?.etapas,
          fases: formatoFases,
          presupuesto: presupuestoData,
        },
      },
    })
      .then(() => {
        setTextoFinal({
          titulo: "Presupuesto enviado con éxito!",
          descripcion: "El presupuesto fue enviado correctamente, puedes ver el estado en la sección de 'Proyectos'.",
        });
        setModalMensajeFinal(true);
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el proyecto", "error");
      }).finally(() => {
        setOpenModalEnviarPresupuesto(false);
      });
  };

  const etapasProyecto = ()=>{
    let arrayEtapas = []; 
    dataPresupuesto.presupuesto.fases.forEach(fase => {
      if (!arrayEtapas.some(etapa => etapa.id === fase.etapa.id)) {
        arrayEtapas.push(fase.etapa);
      }
    });
    //ordenar por id de forma asc
    arrayEtapas.sort((a, b) => a.id - b.id);
    return arrayEtapas;
  }

  useEffect(() => {
    if (dataProyecto && dataPresupuesto && dataCertificado) {
      if(!dataCertificado.certificadoLastByProyecto){
        let arrayFases = dataPresupuesto.presupuesto.fases?.map((fase)=>{
          return({
            ...fase,
            idEtapa: fase?.etapa?.id
          });
        });
        setProyecto({
          ...dataProyecto.proyecto,
          idSucursal: dataProyecto.proyecto.sucursal ? dataProyecto.proyecto.sucursal.id : null,
          fases: arrayFases,
          etapas: etapasProyecto(),
        });
      }else{
        controlCertificado();
      }
      setPresupuesto({
        ...dataPresupuesto.presupuesto,
      });
    }
  }, [dataProyecto, dataPresupuesto, dataCertificado]);

  const controlCertificado = ()=>{
    const arrayFases = [];

    dataPresupuesto?.presupuesto?.fases?.map((fase)=>{
      let idEtapa = fase?.etapa?.id ? fase?.etapa?.id : null;
      if(dataCertificado?.certificadoLastByProyecto?.fases?.some((item)=>item.numero == fase?.numero)){
        const index = dataCertificado?.certificadoLastByProyecto?.fases?.findIndex((item)=>item.numero == fase?.numero);
        if(dataCertificado?.certificadoLastByProyecto?.fases[index]?.porcentajeAvance > 0){
          arrayFases.push({
            ...fase,
            idEtapa: idEtapa,
            certificadoActivo: true
          });
        }else{
          arrayFases.push({
            ...fase,
            idEtapa: idEtapa,
            certificadoActivo: false
          });
        }        
      }else{
        arrayFases.push({
          ...fase,
          idEtapa: idEtapa,
          certificadoActivo: false
        });
      }
    });

    setProyecto({
      ...dataProyecto.proyecto,
      idSucursal: dataProyecto.proyecto.sucursal ? dataProyecto.proyecto.sucursal.id : null,
      fases: arrayFases,
      etapas: etapasProyecto(),
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && (
                  <ProyectoDetalles proyecto={proyecto} setProyecto={setProyecto} large readOnly />
                )}
                {step === 1 && <Fases proyecto={proyecto} setProyecto={setProyecto} large />}
                {step === 2 && (
                  <Cotizacion presupuesto={presupuesto} setPresupuesto={setPresupuesto} large />
                )}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox
                  mr={2}
                  sx={{
                    width: {
                      sm: "100%",
                      xl: "60%",
                      xxl: "50%",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Tabs
                    value={step}
                    onChange={(e, value) => {
                      setStep(value);
                    }}
                  >
                    {steps.map((thisStep, index) => (
                      <Tab
                        key={index}
                        icon={
                          <Icon
                            fontSize="small"
                            color={step === index ? "primary" : "dark"}
                            sx={{
                              verticalAlign: "middle",
                            }}
                          >
                            {thisStep.icon}
                          </Icon>
                        }
                        iconPosition="start"
                        label={
                          <SoftTypography
                            variant="body2"
                            fontWeight={step === index ? "bold" : "normal"}
                          >
                            {thisStep.title}
                          </SoftTypography>
                        }
                        sx={{
                          cursor: "pointer",
                        }}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="end"
                  alignItems="center"
                >
                  <SoftBox
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="primary"
                      circular
                      onClick={step === 0 ? () => navigate("/proyectos") : () => setStep(step - 1)}
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      disabled={loadingSaveAll}
                    >
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === 0 ? "close" : "arrow_back"}
                      </Icon>
                      &nbsp;{step === 0 ? "Cancelar" : "Volver"}
                    </SoftButton>
                  </SoftBox>
                  {step === 2 && (
                    <SoftBox
                      mt={{
                        xs: 2,
                        sm: 0,
                      }}
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                    >
                      <SoftButton
                        color="sistemasGris"
                        circular
                        fullWidth={{
                          xs: true,
                          sm: false,
                        }}
                        onClick={()=>setOpenModalEnviarPresupuesto(true)}
                        disabled={
                          (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                          loadingSaveAll ||
                          !proyecto?.nombre ||
                          !proyecto?.tipo ||
                          (proyecto.tipo === "Cliente" && !proyecto?.cliente) ||
                          (proyecto?.fechaFinEstimada &&
                            proyecto?.fechaInicioEstimada &&
                            dayjs(proyecto?.fechaInicioEstimada).isAfter(
                              dayjs(proyecto?.fechaFinEstimada)
                            )) ||
                          (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada) ||
                          (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                          (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                          (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                        }
                      >
                        Visualizar y Enviar&nbsp;
                        {loadingSaveAll ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          <Icon sx={{ fontWeight: "light" }}>send</Icon>
                        )}
                      </SoftButton>
                    </SoftBox>
                  )}
                  <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={step === 2 ? handleSave : () => setStep(step + 1)}
                      disabled={
                        loadingSaveAll || 
                        (step === 1 && (!proyecto?.fases || proyecto?.fases?.length == 0)) || 
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.cantidad && fase?.cantidad <= 0 ))) ||
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.nombre == "" || fase?.nombre?.trim() == ""))) ||
                        (step === 2 && presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                        (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                        (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      }
                    >
                      {step === 2 ? "Guardar" : "Siguiente"} &nbsp;
                      {loadingSaveAll ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          {step === 2 ? "save" : "arrow_forward"}
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>

                  {step === 1 && (
                    <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                    ml={{
                      xs: 0,
                      sm: 2,
                    }}
                  >
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={handleSave}
                      disabled={
                        loadingSaveAll || 
                        (step === 1 && (!proyecto?.fases || proyecto?.fases?.length == 0)) || 
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.cantidad && fase?.cantidad <= 0 ))) ||
                        (step === 1 && proyecto?.fases?.length > 0 && proyecto?.fases?.some((fase) => (fase?.nombre == "" || fase?.nombre?.trim() == ""))) ||
                        (presupuesto?.ocultarFases && !presupuesto?.notasExtra) ||
                        (proyecto?.etapas?.some((etapa) => proyecto?.fases?.every((fase) => fase?.idEtapa !== etapa?.id))) ||
                        (proyecto?.etapas?.some((etapa)=> !etapa?.nombre))
                      }
                    >
                      Guardar &nbsp;
                      {loadingSaveAll ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          save
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                  )}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>

        <ModalMensajeFinal
         open={modalMensajeFinal}
         handleClose={() =>{
           setModalMensajeFinal(false);
           setTextoFinal({
             titulo: "",
             descripcion: "",
           });
         }}
         titulo={textoFinal?.titulo}
         descripcion={textoFinal?.descripcion}
        />
        
        <ModalEnviarPresupuesto
          open={openModalEnviarPresupuesto}
          handleClose={() => setOpenModalEnviarPresupuesto(false)}
          proyecto={proyecto}
          presupuesto={presupuesto}
          guardarEnviar={saveAndSend}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditarPresupuesto;
