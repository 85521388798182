// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { useEffect, useState } from "react";
import { makeNiceText } from "utils/formatters";
import { Icon, Tooltip } from "@mui/material";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { formatMoneyPunto } from "utils/formatters";
import { getUproColor } from "utils/colors";

function TableItem({ material,cantidad, handleUpdateMaterial, disabled }) {
  const { light } = colors;
  const { borderWidth } = borders;

  const [materialData, setMaterialData] = useState({
    ...material,
    cantidadRecibida: material?.cantidadRecibida ? material?.cantidadRecibida : 0,
    superaOrdenProducto: false
  });
  
  useEffect(() => {
    setMaterialData({
      ...material,
      cantidadRecibida: material?.cantidadRecibida ? material?.cantidadRecibida : 0,
      superaOrdenProducto: false
    });
  }, [material]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // useEffect(() => {
  //   handleUpdateMaterial(materialData);
  // }, [materialData]);

  const validarFila = ()=>{
    let contadorFilasValidas = 0;

    if(materialData?.cantidadRecibida > 0){
      contadorFilasValidas++;
    }

    return contadorFilasValidas > 0
  }

  return (
    <TableRow hover
    selected={validarFila()}
    >
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} >
            <Tooltip title={material?.tipo == "concepto" ? "Mano de obra" :  "Material"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: material?.tipo == "concepto" ? getUproColor("sistemasGris") : getUproColor("sistemasAmarillo"),
                }}
                  fontSize="lg"
                >
                  {material?.tipo == "concepto" ? "schedule" : "inventory_2" }
                </Icon>
              </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.nombre?.length > 70
            ? makeNiceText(material?.nombre).slice(0, 70) + "..."
            : makeNiceText(material?.nombre)}
          {material?.disabled && (
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="error"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
            >
              &nbsp; (Deshabilitado)
            </SoftTypography>
          )}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {cantidad ? `${material?.cantidad * cantidad} ${material?.producto?.unidadMedida || "u"}` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.tipo == "materiales" ? `${material?.cantComprada} ${material?.producto?.unidadMedida || "u"}` : material?.cantComprada}
        </SoftTypography>
      </SoftBox>
      <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
          sx={{
            verticalAlign: "center",
          }}
        >
          <SoftTypography verticalAlign={"middle"} variant="caption"  color="secondary"  fontWeight="bold">
          {material?.ordenPrevia ? `${material?.restante}u` : `${material?.cantComprada}u`}
          </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox>
          <SoftTypography
            verticalAlign="text-top"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            $ {formatMoneyPunto(material?.precioCompra)}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        pr={disabled ? 3 : 0}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <Tooltip 
          title={(materialData?.cantidadRecibida < 0 || 
            material?.cantidadRecibida > materialData?.cantComprada ||
            (material?.restante && material?.cantidadRecibida > material?.restante))
          ? "La cantidad ingresada es superior a la cantidad comprada" : ""}
          placement="top"
        >
          <SoftBox>
            <SoftInputNumber
              placeholder="Cantidad recibida"
              name="cantidadRecibida"
              value={materialData?.cantidadRecibida}
              error={materialData?.cantidadRecibida < 0 || 
                material?.cantidadRecibida > materialData?.cantComprada ||
                (material?.restante && material?.cantidadRecibida > material?.restante)
              }
              // onChange={handleChanges}
              onChange={(e)=>{
                if((e.target.value > materialData?.cantComprada ||
                  (material?.restante && (e.target.value > material?.restante)))){
                  setMaterialData((prevState) => ({
                    ...prevState,
                    cantidadRecibida:  e.target.value,
                    superaOrdenProducto: true
                  }));
                  handleUpdateMaterial({
                    ...materialData,
                    cantidadRecibida: e.target.value,
                    superaOrdenProducto: true
                  });
                }else{
                  setMaterialData((prevState) => ({
                    ...prevState,
                    cantidadRecibida: e.target.value,
                    superaOrdenProducto: false
                  }));
                  handleUpdateMaterial({
                    ...materialData,
                    cantidadRecibida: e.target.value,
                    superaOrdenProducto: false
                  });
                }
              }}
            />
          </SoftBox>
        </Tooltip>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          $
          {material?.precioCompra &&
            materialData?.cantidadRecibida &&
            formatMoneyPunto(materialData?.cantidadRecibida * material?.precioCompra)}
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  cantidad: PropTypes.number.isRequired,
  handleUpdateMaterial: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TableItem;
