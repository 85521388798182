// React
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import TableItemRecepcion from "../TableItemRecepcion";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import ModalConfirm from "components/Modals/ConfirmMsg";
import { formatDate } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "50%" },
  overflow: "auto",
  p: 3,
};

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left", width: "1%" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "left" },
];

export default function ModalNuevaRecepcion({
  open,
  handleClose,
  movimiento,
  idSucursal,
  refetchProductos,
}) {
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [productosRecepcion, setProductosRecepcion] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const [saveRecepcion, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const [addRecepcionMovimiento] = useMutation(
    gql`
      mutation addRecepcionMovimiento($idMovimiento: ID!, $idRecepcion: ID!) {
        addRecepcionMovimiento(idMovimiento: $idMovimiento, idRecepcion: $idRecepcion)
      }
    `
  );

  const handleUpdateProductoRecepcion = (material) => {
    setProductosRecepcion(
      productosRecepcion.map((item) => {
        if (item.id == material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const save = async () => {
    let detalles = productosRecepcion.map((item) => ({
      idProducto: item.id,
      idSucursal: movimiento.sucursalDestino.id,
      cantidad: item.cantidad,
    }));

    let res = await saveRecepcion({
      variables: {
        input: {
          tipo: "Remito Sedes - Ingreso",
          movimientoDetalle: detalles,
          idSucursalOrigen: movimiento.sucursalOrigen.id,
          idSucursalDestino: movimiento.sucursalDestino.id,
        },
      },
    }).catch(() => {
      handleSnackbar("Ha ocurrido un error al guardar la recepción", "error");
    });

    if (res?.data?.saveMovimiento?.id) {
      await addRecepcionMovimiento({
        variables: {
          idMovimiento: movimiento.id,
          idRecepcion: res?.data?.saveMovimiento?.id,
        },
      }).then(() => {
        handleSnackbar("La recepción se ha guardado correctamente", "success");
        handleClose();
        refetchProductos();
      }).catch(() => {
        handleSnackbar("Ha ocurrido un error al guardar la recepción", "error");
      });
    }
  };

  const handleSave = () => {
    let detalles = productosRecepcion.map((item) => ({
      idProducto: item.id,
      idSucursal: movimiento.sucursalDestino.id,
      cantidad: item.cantidad,
    }));

    let totalCount = 0;
    let countDiff = 0;
    detalles = detalles.forEach((recibido) => {
      let enviado = movimiento.movimientoDetalles.find(
        (enviado) => enviado.producto.id == recibido.idProducto
      );
      countDiff += Math.abs(recibido.cantidad + enviado.cantidad);
      totalCount += Math.abs(enviado.cantidad);
    });

    if(countDiff > 0){
      setOpenConfirmar(true);
      return
    }
    save();
  };

  useEffect(() => {
    if (movimiento && open) {
      let productosCargados = movimiento.movimientoDetalles
        .map((item) => ({
          ...item.producto,
          id: item.producto.id,
        }));
      setProductosRecepcion(productosCargados);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Recepcionar transferencia</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">close</Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox px={3}>
                  <SoftTypography variant="caption">Enviado por {
                    movimiento?.user?.nombre ? movimiento?.user?.nombre + " " + movimiento?.user?.apellido : movimiento?.user?.username
                  }</SoftTypography>
                </SoftBox>
                <SoftBox p={3} pt={0}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <InputLabel htmlFor="sucursalOrigen">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Movimiento
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {movimiento?.id}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="sucursalOrigen">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sede de origen
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {movimiento?.sucursalOrigen?.nombre +
                          " - " +
                          movimiento?.sucursalOrigen?.sede?.nombre}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="sucursalDestino">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sede de destino
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {movimiento?.sucursalDestino?.nombre +
                          " - " +
                          movimiento?.sucursalDestino?.sede?.nombre}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Observaciones
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {movimiento?.observaciones}
                      </SoftTypography>
                    </Grid>
                  </Grid>
                  {movimiento?.userProvisorio && (
                    <SoftBox display="flex" pt={2}>
                        <SoftTypography variant="caption" color="secondary">
                          <strong>Recepción provisoria</strong><br />
                          {movimiento.userProvisorio?.nombre} {movimiento.userProvisorio?.apellido} el &nbsp; 
                          {formatDate(movimiento.comentarios?.find(c => c.idUser === movimiento.userProvisorio.id).fecha, "dd/MM/yyyy hh:mm")}:<br/>
                          {movimiento.comentarios?.find(c => c.idUser === movimiento.userProvisorio.id).comentario}
                        </SoftTypography>
                    </SoftBox>
                  )}
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRecepcion.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>{renderColumnsSelect}</TableRow>
                        </SoftBox>
                        <TableBody>
                          {productosRecepcion.map((material, index) => {
                            return (
                              <TableItemRecepcion
                                key={index}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoRecepcion}
                                disabled={false}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              <Card sx={{ marginTop: 2 }}>
                <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                  <SoftBox mr={2}>
                    <SoftButton color="primary" circular onClick={handleClose}>
                      <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                      &nbsp;Cancelar
                    </SoftButton>
                  </SoftBox>
                  <SoftBox>
                    <SoftButton
                      color="sistemasGris"
                      circular
                      disabled={
                        loadingSave ||
                        productosRecepcion.length === 0 ||
                        productosRecepcion.some(
                          (item) =>
                            (!item.cantidad && item.cantidad !== 0) ||
                            item?.cantidad?.toString().includes(".")
                        )
                      }
                      onClick={handleSave}
                    >
                      {loadingSave ? (
                        <CircularProgress size={15} color="white" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>save</Icon>
                      )}
                      &nbsp;Guardar
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
                <ModalConfirm
                  open={openConfirmar}
                  handleClose={() => setOpenConfirmar(false)}
                  icon="warning"
                  title="Atención"
                  message={(
                    <>
                      <SoftTypography variant="body2">
                        Hay diferencias en las cantidad enviada respecto a la recepcionada. 
                      </SoftTypography>
                      <SoftTypography variant="body2">
                        ¿Está seguro que desea continuar de todas maneras?
                      </SoftTypography>
                    </>
                  )}
                  func={save}
                />
              </Card>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevaRecepcion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  movimiento: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
