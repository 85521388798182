import {
  Backdrop,
  Card,
  Divider,
  Fade,
  Icon,
  Modal,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
// Kaizen Dashboard components
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { UserContext } from "context/user";
import { useEffect } from "react";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", md: "50%", lg: "40%", xl: "30%" },
  py: 4,
  px: 4,
  overflow: "auto",
};

export default function ModalProductoStock({ open, handleClose, idProducto, idSede }) {
  const [data, setData] = useState({});
  let tipos = ["Material", "Consumible", "Recurso"];
  let icons = ["category", "recycling", "layers"];
  const [openSucursalMenu, setOpenSucursalMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSede, setSelectedSede] = useState(null);
  const { user, verificarAcceso } = useContext(UserContext);

  const [getProducto, { loading }] = useLazyQuery(
    gql`
      query getProducto($idProducto: ID, $idSede: ID, $stockPorSede: Boolean = false) {
        productos(filter: { id: $idProducto }) {
          id
          tipo
          codigo
          nombre
          stockSede(idSede: $idSede) @skip(if: $stockPorSede){
            cantidad
            enPrestamo
            stockMinimo
            sucursal {
              nombre
              administraStock
              sede {
                nombre
              }
            }
          },
          stockPorSede @include(if: $stockPorSede){
            sede {
              id
              nombre
            }
            cantidad
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: dataSedes, loading: loadingSedes } = useQuery(
    gql`
      query getSedes($filter: JSON) {
        sedes(filter: $filter) {
          id
          nombre
        }
      }
    `,
  );

  const handleOpenSucursalMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSucursalMenu(true);
  };

  const handleCloseSucursalMenu = () => {
    setAnchorEl(null);
    setOpenSucursalMenu(false);
  };

  useEffect(() => {
    if (open && idProducto && idSede) {
      getProducto({
        variables: {
          idProducto,
          idSede: selectedSede,
          stockPorSede: selectedSede === -1 ? true : false,
        }
      }).then(
        ({ data }) => {
          if (data.productos[0]) {
            setData(data.productos[0]);
          }
        }
      );
    } else {
      setData({});
      setSelectedSede(idSede);
    }
  }, [open, selectedSede]);

  useEffect(() => {
    if (idSede) {
      setSelectedSede(idSede);
    }
  }, [idSede]);

  const renderSucursalMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openSucursalMenu)}
      onClose={handleCloseSucursalMenu}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setSelectedSede(-1);
          handleCloseSucursalMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p" fontWeight="medium">
              Todas las sedes
            </SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color:
                  selectedSede === -1
                    ? getUproColor("sistemasAmarillo")
                    : getUproColor("sistemasGris"),
              }}
            >
              home_work
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      {verificarAcceso(28) ? dataSedes?.sedes?.map((sede, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setSelectedSede(sede.id);
            handleCloseSucursalMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p" fontWeight="medium">
                {sede.nombre}
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    sede.id === selectedSede
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                location_on
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )) : dataSedes?.sedes?.filter(sede => sede.id === idSede).map((sede, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setSelectedSede(sede.id);
            handleCloseSucursalMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p" fontWeight="medium">
                {sede.nombre}
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    sede.id === selectedSede
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                location_on
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftBox
                  display="flex"
                  alignItems="center"
                  onClick={handleOpenSucursalMenu}
                  mr={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <Icon color="primary">location_on</Icon>
                  <SoftTypography
                    variant="h6"
                    sx={{
                      cursor: "pointer",
                    }}
                    ml={1}
                  >
                    {loadingSedes ? (
                      <Skeleton width={100} />
                    ) : dataSedes?.sedes?.find(
                      (sede) => sede.id == (selectedSede)
                    ) ? (
                      dataSedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre?.length > 28 ? (
                        dataSedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre?.substring(0, 25) + "..."
                      ) : (
                        dataSedes?.sedes?.find((sede) => sede.id === selectedSede)?.nombre
                      )
                    ) : (
                      "Todas las sedes"
                    )}
                  </SoftTypography>
                  <Icon color="dark">{openSucursalMenu ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
                </SoftBox>
              </SoftBox>
              <SoftBox>
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </SoftBox>
            </SoftBox>

            {renderSucursalMenu}

            <SoftBox>
              {loading && <Loading />}
              {!loading && data && (
                <MiniStatisticsCard
                  direction="left"
                  title={{ text: `${data.nombre || ""} ${data.codigo}` || "" }}
                  count={(data.stockSede || data.stockPorSede)?.reduce((acc, stock) => acc + stock.cantidad, 0) || 0}
                  percentage={{ text: "en total" }}
                  icon={{
                    color: "primary",
                    component: icons[tipos.indexOf(data.tipo)] || "layers",
                  }}
                />
              )}
            </SoftBox>
            <Divider />
            {loading && <Loading />}
            {!loading && !(data.stockSede?.length || data.stockPorSede?.length) && <SinDatos />}
            <SoftBox
              display="flex"
              flexDirection="column"
              gap={2}
              sx={{ maxHeight: "50vh", overflowY: "scroll" }}
            >
              {!loading && data.stockSede
                ?.filter((s) => s.cantidad > 0 || s.enPrestamo > 0 || s.stockMinimo > 0)
                .map((stock, index) => (
                  <SoftBox key={index}>
                    <MiniStatisticsCard
                      direction="left"
                      title={{ text: stock.sucursal.nombre || "" }}
                      count={(
                        <Tooltip
                          placement="bottom-start"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                p: 1,
                                bgcolor: 'common.white',
                                '& .MuiTooltip-arrow': {
                                  color: 'common.white',
                                },
                                boxShadow: 5,
                              },
                            },
                          }}
                          title={(
                            <>
                              <SoftBox display="flex" justifyContent="space-between" gap={2}>
                                <SoftBox display="flex" flexDirection="column" textAlign="left">
                                  <SoftTypography variant="caption">Disponible:</SoftTypography>
                                  {stock?.enPrestamo > 0 && (
                                    <SoftTypography variant="caption">En préstamo:</SoftTypography>
                                  )}
                                </SoftBox>
                                <SoftBox display="flex" flexDirection="column" textAlign="right">
                                  <SoftTypography variant="caption">{formatMoneyPunto(stock?.cantidad || 0)}</SoftTypography>
                                  {stock?.enPrestamo > 0 && (
                                    <SoftTypography variant="caption">{formatMoneyPunto(stock?.enPrestamo || 0)}</SoftTypography>
                                  )}
                                </SoftBox>
                              </SoftBox>
                              <Divider sx={{ m: 1 }} />
                              <SoftBox display="flex" justifyContent="space-between" gap={2}>
                                <SoftBox display="flex" flexDirection="column" textAlign="left">
                                  <SoftTypography variant="caption">Total:</SoftTypography>
                                  {stock?.stockMinimo > 0 && (
                                    <SoftTypography variant="caption">Mínimo:</SoftTypography>
                                  )}
                                </SoftBox>
                                <SoftBox display="flex" flexDirection="column" textAlign="right">
                                  <SoftTypography variant="caption" fontWeight="bold">{formatMoneyPunto(stock?.cantidad + stock?.enPrestamo || 0)}</SoftTypography>
                                  {stock?.stockMinimo > 0 && (
                                    <SoftTypography variant="caption" sx={
                                      (stock?.cantidad + stock?.enPrestamo) <= stock?.stockMinimo ? {
                                        color: "red",
                                        fontWeight: "bold"
                                      } : {}
                                    }>({formatMoneyPunto(stock?.stockMinimo || 0)})</SoftTypography>
                                  )}
                                </SoftBox>
                              </SoftBox>
                            </>
                          )}
                        >
                          <SoftBox
                            component="span"
                            display="flex"
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                            }}
                          >

                            {stock?.enPrestamo > 0 ? (<>
                              {formatMoneyPunto(stock?.cantidad || 0)}
                              &nbsp;
                              <SoftTypography variant="h6" fontWeight="bold">
                                ({formatMoneyPunto(stock?.cantidad + stock?.enPrestamo || 0)})
                              </SoftTypography>
                            </>) : (
                              formatMoneyPunto(stock?.cantidad || 0)
                            )}
                            {stock?.stockMinimo && (stock?.cantidad + stock?.enPrestamo) <= stock?.stockMinimo && (
                              <>
                                &nbsp;
                                <Icon fontSize="small" color="error">
                                  error_outline
                                </Icon>
                              </>
                            )}
                            &nbsp;
                            <SoftTypography variant="button" pt={0.5}>en stock</SoftTypography>
                          </SoftBox>
                        </Tooltip>
                      )}
                      icon={{
                        color: stock.sucursal.administraStock ? "dark" : "secondary",
                        component: stock.sucursal.administraStock ? "warehouse" : "inventory_2",
                      }}
                    />
                  </SoftBox>
                ))
              }
              {!loading && data.stockPorSede
                ?.filter((s) => s.cantidad > 0)
                .map((stock, index) => (
                  <SoftBox key={index}>
                    <MiniStatisticsCard
                      direction="left"
                      title={{ text: stock.sede.nombre || "" }}
                      count={stock.cantidad}
                      percentage={{ text: "en total" }}
                      icon={{
                        color: "dark",
                        component: "location_city",
                      }}
                    />
                  </SoftBox>
                ))}
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalProductoStock.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idProducto: PropTypes.string,
  idSede: PropTypes.string,
};
