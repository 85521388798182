// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

// Custom UI components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalConfirmarDevolucion({
  open,
  handleClose,
  setDevolucion,
  handleSave,
  productos,
}) {
  const [data, setData] = useState();

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios(filter: { tipoUser: "Empleado" }, order: { field: "nombre", order: ASC }) {
          id
          nombre
          apellido
          username
        }
      }
    `
  );

  const handleChanges = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) {
      setDevolucion(data);
    }
  }, [data]);

  useEffect(() => {
    setData();
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                pb={0}
              >
                <SoftBox>
                  <SoftTypography variant="h6">Confirmar devolución de prestamo</SoftTypography>
                  <SoftBox display="flex" alignItems="center">
                    {productos.some((item) => item.cantidad !== item.stock?.cantidad) > 0 && (
                      <SoftTypography variant="h7" color="error">
                        Se devuelven menos productos que los prestados, se realizará un ajuste de
                        stock
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <SoftBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="responsable">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Empleado que retorna los productos
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="responsable"
                      value={data?.responsable || ""}
                      onChange={handleChanges}
                    >
                      {usuarios?.usuarios?.map((usuario) => (
                        <MenuItem key={usuario.id} value={usuario.id}>
                          {usuario?.nombre && usuario?.apellido
                            ? `${usuario.nombre} ${usuario.apellido}`
                            : usuario.username}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="observaciones">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Observaciones
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="observaciones"
                      placeholder="Inserte un comentario"
                      type="text"
                      name="observaciones"
                      value={data?.observaciones || ""}
                      onChange={handleChanges}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="end" alignItems="center" p={3} pt={0}>
              <SoftBox mr={2}>
                <SoftButton color="primary" circular onClick={handleClose}>
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  color="sistemasGris"
                  circular
                  disabled={!data?.responsable}
                  onClick={handleSave}
                >
                  <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalConfirmarDevolucion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setDevolucion: PropTypes.func,
  handleSave: PropTypes.func,
  productos: PropTypes.array,
};
