/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";

const getRows = (data, cantidad) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: item?.horas ? getUproColor("sistemasGris") : getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                {item?.horas ? "schedule" : "inventory_2"}
              </Icon>
            }
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item?.horas ? "Mano de obra" : item?.cantidad ? "Material" : "Concepto"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: item?.horas ? getUproColor("sistemasGris") :  item?.cantidad ? getUproColor("sistemasAmarillo") : getUproColor("sistemasNegro"),
                }}
                  fontSize="lg"
                >
                  {item?.horas ? "schedule" : item?.cantidad ? "inventory_2" : "inventory_1"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.horas ? item?.nombre : item?.producto?.nombre}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.producto?.nombre ? item?.producto?.nombre : item?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.cantidad}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.horas ? `${item?.horas * cantidad}hs` : item?.cantidad ? `${item?.cantidad * cantidad} ${item?.producto?.unidadMedida || "u"}` : "1"}
            </SoftTypography>
          </SoftBox>
        ),
        moneda: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={`$${formatMoneyPunto(item?.tipoMoneda || 0)}`}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.tipoMoneda ||  "-"}
            </SoftTypography>
          </SoftBox>
        ),
        precio: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={`$${formatMoneyPunto(item?.precio || 0)}`}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {`$${formatMoneyPunto(item?.precio || 0)}`}
            </SoftTypography>
          </SoftBox>
        ),
        cotizacion: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={`$${formatMoneyPunto(item?.cotizacion || 0)}`}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {`$${formatMoneyPunto(item?.cotizacion || 0)}`}
            </SoftTypography>
          </SoftBox>
        ),
        subtotal: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={`$${formatMoneyPunto(item?.precio * cantidad)}`}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.horas ? `$${formatMoneyPunto(item?.precio * item?.horas * cantidad)}` : item?.cantidad ? `$${formatMoneyPunto(item?.precio * item?.cantidad * cantidad)}` : `$${formatMoneyPunto(item?.precio || 0)}`}
            </SoftTypography>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true },
  { name: "precio", desc: "Precio Unitario", align: "left", noOrder: true },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
  { name: "subtotal", desc: "Subtotal", align: "left", noOrder: true },
];

export default { columns, getRows };
