// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Icon, Modal, Skeleton, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { formatMoneyPunto } from "utils/formatters";
import ModalVerPresupuesto from "../VerPresupuestoPDF";
import ModalRechazar from "./ModalRechazar";
import ModalAprobar from "./ModalAprobar";
import SoftBadge from "components/SoftBadge";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalPresupuestos({ open, handleClose, refetch, selectedProyecto }) {
  const { handleSnackbar } = useContext(MessageManager);
  const [selectedPresupuestoId, setSelectedPresupuestoId] = useState(null);
  const [modalRechazarPresupuesto, setModalRechazarPresupuesto] = useState(false);
  const [modalAprobarPresupuesto, setModalAprobarPresupuesto] = useState(false);
  const [getPresupuestos, { data: presupuestos, loading, refetch: refetchPresupuestos }] =
    useLazyQuery(gql`
      query getPresupuestos($idProyecto: ID) {
        presupuestos(filter: { idProyecto: $idProyecto }) {
          id
          numero
          version
          createdAt
          rechazado
          motivoRechazo
          fases {
            id
            nombre
            cantidad
            nombreUnidad
            porcentajeGanancia
            manoDeObra {
              id
              horas
              precio
            }
            materiales {
              id
              precio
              cantidad
            }
            concepto {
              id
              precio
            }
            presupuesto{
              id
              aprobado
              precio
            }
          }
        }
      }
    `);

  const [saveProyecto, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProyecto($input: ProyectoInput!) {
        saveProyecto(input: $input) {
          id
        }
      }
    `
  );

  const [savePresupuesto, { loading: loadingPresupuesto }] = useMutation(gql`
    mutation savePresupuesto($input: PresupuestoInput!) {
      savePresupuesto(input: $input) {
        id
      }
    }
  `);

  useEffect(() => {
    if (open && selectedProyecto) {
      getPresupuestos({ variables: { idProyecto: selectedProyecto.id } });
    }
  }, [open, selectedProyecto]);

  useEffect(() => {
    refetchPresupuestos();
  }, [open]);

  const getTotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        // let content = fase?.manoDeObra?.concat(fase?.materiales) || [];
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        subtotal += subtotal * (porcentaje / 100) || 0;
        total += subtotal;
      });
    }

    return total;
  };

  const getTotalNuevo = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        let subtotal = 0;
        const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        const calcularTotal = (items, cantidad = 1) => {
          return items.reduce((acc, item) => {
            if (item.horas) {
              return acc + item.precio * item.horas * cantidad;
            } else if (item.cantidad) {
              return acc + item.precio * item.cantidad * cantidad;
            } else {
              return acc + item.precio;
            }
          }, 0);
        };
      
        if (fase?.nombreUnidad) {
          const cantidad = parseInt(fase?.cantidad) || 0;
          const content = [
            ...(fase?.manoDeObra || []),
            ...(fase?.materiales || []),
            ...(fase?.concepto || []),
          ];
          subtotal = calcularTotal(content, cantidad);
        } else if (fase?.presupuesto?.some((item) => item.aprobado == 1)) {
          subtotal = fase.presupuesto.find((item) => item.aprobado == 1)?.precio || 0;
        } else {
          const content = [...(fase?.concepto || [])];
          subtotal = calcularTotal(content);
        }
      
        subtotal += subtotal * (porcentaje / 100);
        total += subtotal;
      });
    }

    return total;
  }

  const handleRechazarPresupuesto = (motivo) => {
    savePresupuesto({
      variables: {
        input: {
          id: selectedPresupuestoId,
          rechazado: true,
          motivoRechazo: motivo,
        },
      },
    })
      .then(() => {
        handleSnackbar("Presupuesto fue rechazado", "success");
        refetchPresupuestos();
      })
      .catch((e) => {
        console.log(e);
        handleSnackbar("Error al rechazar presupuesto", "error");
      })
      .finally(() => {
        setSelectedPresupuestoId(null);
        setModalRechazarPresupuesto(false);
      });
  };

  const handleAprobarPresupuesto = (motivo) => {
    const nroPresupuesto =  presupuestos?.presupuestos?.find(
      (presupuesto) => presupuesto.id === selectedPresupuestoId
    )?.numero;
    saveProyecto({
      variables: {
        input: {
          id: selectedProyecto.id,
          estado: "En_Proceso",
          nroPresupuestoAprobado: nroPresupuesto,
          idPresupuestoAprobado: selectedPresupuestoId,
          motivoAprobado: motivo,
        },
      },
    })
      .then(() => {
        handleSnackbar("El Presupuesto fue aprobado", "success");
        refetch();
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        handleSnackbar("Error al rechazar presupuesto", "error");
      }).finally(() => {
        setSelectedPresupuestoId(null);
        setModalAprobarPresupuesto(false);
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Presupuestos</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftBox mr={2}>
                  <Tooltip title="Agregar nuevo presupuesto" placement="top">
                    <Link
                      to={`/proyectos/nuevoPresupuesto`}
                      state={{ idProyecto: selectedProyecto?.id }}
                    >
                      <SoftButton color="primary" circular>
                        Agregar Presupuesto&nbsp;
                        <Icon>add</Icon>
                      </SoftButton>
                    </Link>
                  </Tooltip>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox py={3}>
              <SoftBox mb={2}>
                {loading ? (
                  <SoftBox>
                    <Skeleton variant="text" width={"100%"} />
                    <Skeleton variant="text" width={"100%"} />
                  </SoftBox>
                ) : presupuestos?.presupuestos?.length > 0 ? (
                  presupuestos?.presupuestos?.map((presupuesto, index) => (
                    <Card key={presupuesto.id} sx={{ mb: 2 }}>
                      <SoftBox
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <SoftBox display="flex">
                          <SoftBox lineHeight={1}>
                            <SoftBox>
                              <SoftTypography variant="button">
                                {`Presupuesto Nº${presupuesto.numero} v${presupuesto.version}`} -{" "}
                                {presupuesto?.createdAt
                                  ? dayjs(presupuesto?.createdAt).format("DD/MM/YYYY")
                                  : ""}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" alignItems="center" gap={1}>
                              <SoftTypography variant="h6" fontWeight="bold" color={"primary"}>
                                {`$${formatMoneyPunto(getTotalNuevo(presupuesto.fases))}`}
                              </SoftTypography>
                              {presupuesto?.rechazado && (
                                <SoftBadge badgeContent={"Rechazado"} color="error" />
                              )}
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>

                        <SoftBox display="flex" alignItems="center" gap={1}>
                          {(presupuesto?.rechazado == null || presupuesto?.rechazado == false) && (
                            <>
                              <SoftBox>
                                <Tooltip placement="top" title="Rechazar Presupuesto">
                                  <SoftButton
                                    onClick={() => {
                                      setSelectedPresupuestoId(presupuesto.id);
                                      setModalRechazarPresupuesto(true);
                                    }}
                                    circular
                                    iconOnly
                                    color="error"
                                  >
                                    <Icon>close</Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                              <SoftBox>
                                <Tooltip placement="top" title="Aceptar Presupuesto">
                                  <SoftButton
                                    onClick={() => {
                                      setSelectedPresupuestoId(presupuesto.id);
                                      setModalAprobarPresupuesto(true);
                                    }}
                                    circular
                                    iconOnly
                                    color="success"
                                  >
                                    <Icon>check</Icon>
                                  </SoftButton>
                                </Tooltip>
                              </SoftBox>
                            </>
                          )}
                          <SoftBox>
                            <Tooltip placement="top" title="Ver Presupuesto">
                              <SoftButton
                                onClick={() => setSelectedPresupuestoId(presupuesto.id)}
                                circular
                                iconOnly
                                color="info"
                              >
                                <Icon>picture_as_pdf</Icon>
                              </SoftButton>
                            </Tooltip>
                          </SoftBox>
                          <SoftBox>
                            <Link
                              to={`/proyectos/editarPresupuesto`}
                              state={{
                                idProyecto: selectedProyecto?.id,
                                idPresupuesto: presupuesto.id,
                              }}
                            >
                              <Tooltip placement="top" title="Editar Presupuesto">
                                <SoftButton circular iconOnly color="sistemasGris">
                                  <Icon>edit</Icon>
                                </SoftButton>
                              </Tooltip>
                            </Link>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  ))
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="sistemasGris"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cerrar
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <ModalVerPresupuesto
              open={
                selectedPresupuestoId !== null &&
                !modalRechazarPresupuesto &&
                !modalAprobarPresupuesto
              }
              handleClose={() => setSelectedPresupuestoId(null)}
              idProyecto={selectedPresupuestoId}
            />
            <ModalRechazar
              open={modalRechazarPresupuesto}
              handleClose={() => {
                setSelectedPresupuestoId(null);
                setModalRechazarPresupuesto(false);
              }}
              functionDelete={handleRechazarPresupuesto}
            />
            <ModalAprobar
              open={modalAprobarPresupuesto}
              handleClose={() => {
                setSelectedPresupuestoId(null);
                setModalAprobarPresupuesto(false);
              }}
              functionAprobar={handleAprobarPresupuesto}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPresupuestos.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedProyecto: PropTypes.object,
};
