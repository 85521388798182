/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatDate } from "utils/formatters";

const getRows = (data, handleSelect) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  import_export
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.id}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalOrigen: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalOrigen?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalDestino: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalDestino?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.movimientoDetalles.length +
                " productos"}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {formatDate(item?.createdAt, "dd/MM/yyyy")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip title="Recepcionar" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={() => handleSelect(item)}
              >
                arrow_downward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt"},
  { name: "sucursalOrigen", desc: "Sede Origen", align: "left"},
  { name: "sucursalDestino", desc: "Sede Destino", align: "left"},
  { name: "cantidad", desc: "Cantidad de productos", align: "center"},
  { name: "user", desc: "Usuario", align: "left", orderField: "idUser"},
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };