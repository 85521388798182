// React
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import TableItem from "../TableItem";
import { MessageManager } from "context";
import { formatMoneyPunto } from "utils/formatters";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  // { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  // { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantTotal", desc: "Cant. Total", align: "left" },
  { name: "cantComprada", desc: "Cant. Comprada", align: "left" },
  { name: "cantRestante", desc: "Restante", align: "left" },
  { name: "precio", desc: "Precio Unit.", align: "left"},
  { name: "cantidad", desc: "Cant. Recibida", align: "center", width: "150px" },
  { name: "subtotal", desc: "subtotal", align: "center" },
  // { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

export default function ModalNuevaRecepcion({
  open,
  handleClose,
  selectedRemito,
  dataOrden,
  refetchProductos,
}) {
  const [remito, setRemito] = useState();
  const [productosRemito, setProductosRemito] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  
  const [saveRemito, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const handleChanges = (event) => {
    setRemito({ ...remito, [event.target.name]: event.target.value });
  };

  const handleUpdateProductoRemito = (material) => {
    const updateRemito =  productosRemito.map((item) => {
      if (item.id === material.id) {
        return material;
      }
      return item;
    });
    setProductosRemito(updateRemito);
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const handleSave = () => {

    const movimientoDetalle = productosRemito.filter((item) => (item?.cantidadRecibida && item?.cantidadRecibida > 0)).map((element) => ({
        idProducto: element?.producto?.id,
        precio: element?.precioCompra,
        cantidad: element.cantidadRecibida,
        superaOrdenProducto: element?.superaOrdenProducto ? element?.superaOrdenProducto : false,
        idSucursal: dataOrden?.proyecto?.sucursal?.id,
    }));
    
    const dataRemito = {
      tipo: "Recepcion Orden de Compra",
      movimientoDetalle: movimientoDetalle,
      idOrdenDeCompra: dataOrden?.id,
      idSucursalDestino: dataOrden?.proyecto?.sucursal?.id,
      idProveedor: dataOrden?.proveedor?.id,
      fechaEntrega: remito?.fechaEntrega ? remito?.fechaEntrega : null,
      // ordenPago: remito?.op ? remito?.op : null,
      // numeroExpediente: remito?.expediente ? remito?.expediente : null,
      // fechaOrdenPago: remito?.fechaOP ? remito?.fechaOP : null,
      nroRemito: remito?.nroRemito ? remito?.nroRemito : null,
    };

    saveRemito({
      variables: {
        input: dataRemito,
      },
    })
      .then(() => {
        handleSnackbar("La recepción se ha guardado correctamente", "success");
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al guardar la recepción de materiales", "error");
      });
  };

  useEffect(() => {
    if (open) {
      setRemito({
        fechaEntrega: dayjs().format("YYYY-MM-DD"),
      });
      setProductosRemito(dataOrden?.detalle);
    }else{
      setRemito();
      setProductosRemito([]);
    }
  }, [open]);

  const handleCompletarRecepcion =()=>{
    const completarRecepcion = productosRemito.map((item) => {
      let cantidadRecepcion = item?.ordenPrevia ? item?.restante : item?.cantComprada;
      return(
        {
          ...item,
          cantidadRecibida: cantidadRecepcion,
        }
      )
    });

    setProductosRemito(completarRecepcion);
  }

  const validarBotonCompletar = ()=>{
    return productosRemito?.every((item) => item?.cantidadRecibida > 0 && ( (item?.ordenPrevia && (item?.cantidadRecibida >= item?.restante)) || (!item?.ordenPrevia && (item?.cantidadRecibida >= item?.cantComprada))));
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Datos del remito</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="sede">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Deposito de destino
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {
                          dataOrden?.proyecto?.sucursal?.nombre ? (
                            `${dataOrden?.proyecto?.sucursal?.sede?.nombre} - ${dataOrden?.proyecto?.sucursal?.nombre}`
                          ) : (
                            <Skeleton width={100} />
                          )
                        }
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel htmlFor="proveedor">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Proveedor
                          </SoftTypography>
                        </InputLabel>
                      </SoftBox>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {
                          dataOrden?.proveedor?.razonSocial ||
                          dataOrden?.proveedor?.nombre +
                            " " +
                            dataOrden?.proveedor?.apellido
                        }
                      </SoftTypography>
                    </Grid>
                    
                    {/* <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="op">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Orden de Pago
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.ordenPago}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="op"
                          placeholder="Inserte la orden de pago"
                          type="text"
                          name="op"
                          value={remito?.op || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="expediente">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nº de Expediente
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.numeroExpediente}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="expediente"
                          placeholder="Inserte el número de expediente"
                          type="text"
                          name="expediente"
                          value={remito?.expediente || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="fechaOP">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Fecha de Orden de Pago
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.fechaOrdenPago}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="fechaOP"
                          type="date"
                          name="fechaOP"
                          value={remito?.fechaOP || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid> */}

                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nroRemito">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nº de Remito
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.nroRemito}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="nroRemito"
                          placeholder="Inserte el número de remito"
                          type="text"
                          name="nroRemito"
                          value={remito?.nroRemito || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="fechaEntrega">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Fecha de Entrega
                          {!selectedRemito && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedRemito ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito.fechaEntrega}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="fechaEntrega"
                          type="date"
                          name="fechaEntrega"
                          value={remito?.fechaEntrega || ""}
                          onChange={handleChanges}
                        />
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>

                  <Tooltip title="Marcar la recepción completa de todos los productos" placement="top">
                    <SoftButton
                    circular
                    color="primary"
                    onClick={handleCompletarRecepcion}
                    disabled={validarBotonCompletar()}
                    >
                      <Icon>check</Icon>&nbsp;
                      Recepcion completa
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>{renderColumnsBase}</TableRow>
                        </SoftBox>
                        <TableBody>
                          {productosRemito.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                cantidad={dataOrden?.proyectoFase?.cantidad}
                                handleUpdateMaterial={handleUpdateProductoRemito}
                                disabled={selectedRemito ? true : false}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {!selectedRemito && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <SoftBox>
                      <SoftBox>
                        <SoftButton color="sistemasGris" circular>
                          Total:
                          <Icon sx={{ fontWeight: "light" }}>attach_money</Icon>
                          {formatMoneyPunto(
                            productosRemito.reduce((acc, item) => {
                              return acc + (item?.cantidadRecibida || 0) * item?.precioCompra;
                            }, 0)
                          )}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="end" alignItems="center">
                      <SoftBox mr={2}>
                        <SoftButton color="primary" circular onClick={handleClose}>
                          <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                          &nbsp;Cancelar
                        </SoftButton>
                      </SoftBox>
                      <SoftBox>
                        <SoftButton
                          color="sistemasGris"
                          circular
                          disabled={
                            loadingSave ||
                            !dataOrden?.proveedor?.id ||
                            !dataOrden?.proyecto?.sucursal?.id ||
                            !remito?.fechaEntrega ||
                            productosRemito.length === 0 ||
                            productosRemito.every(
                              (item) =>
                                !item.cantidadRecibida ||
                                item.cantidadRecibida == 0 
                            ) 
                          }
                          onClick={handleSave}
                        >
                          {loadingSave ? (
                            <CircularProgress size={15} color="white" />
                          ) : (
                            <Icon sx={{ fontWeight: "light" }}>save</Icon>
                          )}
                          &nbsp;Guardar
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevaRecepcion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedRemito: PropTypes.object,
  dataOrden: PropTypes.object,
  refetchProductos: PropTypes.func,
};
