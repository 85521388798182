// React
import PropTypes from "prop-types";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";

// Custom UI components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { useContext, useEffect, useRef, useState } from "react";
import { getUproColor } from "utils/colors";
import { validateNumber } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflow: "visible",
};

export default function ModalStockRapido({ open, handleClose, idSucursal, refetch }) {
  const [producto, setProducto] = useState();
  const [newStock, setNewStock] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const inputRef = useRef(null);
  const stockRef = useRef(null);
  const { handleSnackbar } = useContext(MessageManager);

  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          nombre
          categoria
          disabled
          precio
          stock(idSucursal: $idSucursal) {
            cantidad
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [saveMovimiento, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const handleSearch = (event) => {
    const searchValue = event.target.value;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              { id: { _like: `%${searchValue}%` } },
              { nombre: { _like: `%${searchValue}%` } },
              { categoria: { _like: `%${searchValue}%` } },
            ],
          },
          idSucursal: idSucursal,
        },
      });
    }, 500);

    setSearchTimeout(newTimeout);
  };

  const handleSave = () => {
    const diferencia = producto?.stock?.cantidad ? newStock - producto?.stock?.cantidad : newStock;
    saveMovimiento({
      variables: {
        input: {
          tipo: "Ajuste - Stock Inicial",
          observaciones: "Ajuste rápido de stock",
          idSucursalOrigen: idSucursal,
          idSucursalDestino: idSucursal,
          movimientoDetalle: [
            {
              idProducto: producto.id,
              cantidad: parseInt(diferencia),
              idSucursal: idSucursal,
            },
          ],
        },
      },
    })
      .then(() => {
        setProducto();
        setNewStock();
        refetch();
        setTimeout(() => {
          if (inputRef.current) {
            const input = inputRef.current.querySelector("input");
            input.focus();
          }
        }, 100);
        handleSnackbar("Stock actualizado correctamente", "success");
      })
      .catch((error) => {
        handleSnackbar("Error al actualizar el stock", "error");
      });
  };

  useEffect(() => {
    if (open) {
      setProducto();
      setNewStock();
      setTimeout(() => {
        if (inputRef.current) {
          const input = inputRef.current.querySelector("input");
          input.focus();
        }
      }, 100);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                pb={0}
              >
                <SoftBox>
                  <SoftTypography variant="h6">Carga de stock rápido</SoftTypography>
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <SoftBox px={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="producto">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Seleccionar producto
                      </SoftTypography>
                    </InputLabel>
                    {producto ? (
                      <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                        <SoftTypography variant="h6" fontWeight="bold">
                          <SoftBox display="flex" alignItems="center">
                            <Icon
                              fontSize="small"
                              sx={{
                                color:
                                  producto.tipo === "Material"
                                    ? getUproColor("sistemasAmarillo")
                                    : producto.tipo === "Consumible"
                                    ? getUproColor("sistemasGris")
                                    : getUproColor("sistemasAmarillo"),
                              }}
                            >
                              {producto.tipo === "Material"
                                ? "category"
                                : producto.tipo === "Consumible"
                                ? "recycling"
                                : "layers"}
                            </Icon>
                            &nbsp;
                            {`${producto.id} - ${producto.nombre}`}
                          </SoftBox>
                        </SoftTypography>
                        <Tooltip placement="top" title="Cambiar producto">
                          <SoftButton
                            color="primary"
                            circular
                            iconOnly
                            onClick={() => {
                              setProducto();
                              setNewStock();
                            }}
                            sx={{ ml: 2 }}
                          >
                            <Icon>close</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                    ) : (
                      <Autocomplete
                        ref={inputRef}
                        value={producto}
                        noOptionsText="No hay resultados"
                        onChange={(event, newValue) => {
                          setProducto(newValue);
                          setNewStock(newValue?.stock?.cantidad || 0);
                          if (stockRef.current) {
                            const input = stockRef.current.querySelector("input");
                            input.focus();
                          }
                        }}
                        id="asynchronous-demo"
                        sx={{ width: "100%" }}
                        open={openSelectionMenu}
                        onOpen={() => {
                          setOpenSelectionMenu(true);
                          getProductos({
                            variables: {
                              page: 1,
                              filter: { disabled: false },
                              idSucursal: idSucursal,
                            },
                          });
                        }}
                        onClose={() => {
                          setOpenSelectionMenu(false);
                        }}
                        onKeyDown={handleSearch}
                        getOptionLabel={(option) =>
                          `${option.id} - ${option.nombre} (${option.tipo})`
                        }
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={productos?.productos || []}
                        loading={loading}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              onChange={handleSearch}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          );
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="cantidad">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nuevo stock
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="cantidad"
                      placeholder="Inserte el nuevo stock"
                      type="number"
                      ref={stockRef}
                      inputProps={{ min: 0 }}
                      name="cantidad"
                      onChange={(e) => setNewStock(e.target.value)}
                      error={newStock && (newStock < 0 || !validateNumber(newStock))}
                      value={newStock || ""}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" &&
                          !loadingSave &&
                          producto &&
                          newStock &&
                          newStock !== producto?.stock?.cantidad &&
                          validateNumber(newStock) &&
                          newStock >= 0
                        ) {
                          handleSave();
                        }
                        if (e.key === "Escape") {
                          setProducto();
                          setNewStock();
                          setTimeout(() => {
                            if (inputRef.current) {
                              const input = inputRef.current.querySelector("input");
                              input.focus();
                            }
                          }, 100);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
              p={3}
            >
              <SoftBox>
                <SoftButton
                  color="sistemasGris"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={handleSave}
                  disabled={
                    loadingSave ||
                    !producto ||
                    !newStock ||
                    newStock === producto?.stock?.cantidad ||
                    !validateNumber(newStock) ||
                    newStock < 0
                  }
                >
                  {loadingSave ? (
                    <CircularProgress color="white" size={15} />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;
                  {!loadingSave ? "Guardar" : "Guardando"}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalStockRapido.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetch: PropTypes.func,
};
