// React
import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import ModalDelete from "components/Modals/Delete";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Pagination,
  Skeleton,
  Tooltip,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import PropTypes from "prop-types";

// Data
import dataDeposito from "./data/dataDeposito";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { getUproColor } from "utils/colors";
import SoftInput from "components/SoftInput";
// import ModalUsuarios from "./ModalUsuario";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import ModalAddDeposito from "./ModalAddDeposito";
// import ModalPlantilla from "./ModalPlantilla";
// import ModalEditarPlantilla from "./ModalEditar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
};

function ModalSucursales({ open, handleClose,refetch }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [selectedDeposito, setSelectedDeposito] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataDeposito;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedDeposito(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteSucursal] = useMutation(
    gql`
      mutation deleteSucursal($id: ID!) {
        deleteSucursal(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch: refetchSucursales } = useQuery(
    gql`
      query getSucursales($search: String, $page: Int, $order: [Order]) {
        paginationInfo {
          pages
          total
        }
        sucursales(search: $search, pagination: { page: $page, limit: 10 }, order: $order) {
          id
          nombre
          sede{
            id
          }
          administraStock
          principal
          mantenimiento
          tipo
          direccion
          localidad{
            id
            nombre
            provincia{
                id
                nombre
            }
          }
          responsable{
            id
            nombre
            apellido
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}> 
        <MenuItem
            disabled={false}
            onClick={() => {
                setOpenModalAdd(true);
                handleCloseMenu();
            }}
            >
            <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                width: "100%",
                }}
            >
                <SoftBox>
                <SoftTypography variant="p">Editar</SoftTypography>
                </SoftBox>
                <SoftBox>
                <Icon
                    fontSize="small"
                    sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasAmarillo"),
                    }}
                >
                    edit
                </Icon>
                </SoftBox>
            </SoftBox>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenModalDelete(true);
            handleCloseMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">Eliminar</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    selectedDeposito?.tipoUser === "Administrador"
                      ? getUproColor()
                      : getUproColor("sistemasGris"),
                }}
              >
                delete
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteSucursal({ variables: { id } });
      refetchSucursales();
      refetch();
      handleSnackbar("Deposito eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar(error?.message || "Error al eliminar deposito", "error");
    } finally {
       setSelectedDeposito();
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data.sucursales, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                    <SoftBox
                        display={{
                        xs: "flex-row",
                        sm: "flex",
                        }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <SoftTypography variant="h6">Deposito</SoftTypography>
                       
                            <SoftBox
                            display={{
                                xs: "block",
                                sm: "none",
                            }}
                            >
                                <Tooltip title="Agregar nuevo cliente" placement="top">
                                    <SoftButton
                                    color="primary"
                                    circular
                                    iconOnly
                                    onClick={() => setOpenModalAdd(true)}
                                    >
                                    <Icon>add</Icon>
                                    </SoftButton>
                                </Tooltip>
                            </SoftBox>
                      
                        </SoftBox>
                        <SoftBox
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                        mt={{
                            xs: 2,
                            sm: 0,
                        }}
                        >
                        <SoftBox
                            mr={{
                            xs: 0,
                            sm: 2,
                            }}
                            sx={{
                            width: {
                                xs: "100%",
                                sm: "auto",
                            },
                            }}
                        >
                            <SoftInput
                            placeholder="Escribe aquí..."
                            icon={{ component: "search", direction: "left" }}
                            onChange={handleSearch}
                            />
                        </SoftBox>
                       
                            <SoftBox
                            display={{
                                xs: "none",
                                sm: "block",
                            }}
                            >
                                <Tooltip title="Agregar nuevo plantilla" placement="top">
                                    <SoftButton
                                    color="primary"
                                    circular
                                    onClick={() => setOpenModalAdd(true)}
                                    >
                                    <Icon>add</Icon>
                                    &nbsp;Agregar
                                    </SoftButton>
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                    <DataTable
                        columns={columns}
                        rows={rows}
                        order={orderBy}
                        setOrder={handleOrderBy}
                    />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.sucursales.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </Card>
            {renderMenu}
           
            <ModalAddDeposito
            open={openModalAdd}
            handleClose={()=>{
                setOpenModalAdd(false);
                setSelectedDeposito();
            }}
            refetch={()=>{
                refetchSucursales();
                refetch();
            }}
            selectDeposito={selectedDeposito}
            />
            <ModalDelete
              open={openModalDelete}
              handleClose={() => {
                setOpenModalDelete(false)
                setSelectedDeposito();
              }}
              nombre={`el deposito ${selectedDeposito?.nombre}`}
              functionDelete={() => {
                handleDeleteUser(selectedDeposito.id);
                setOpenModalDelete(false);
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSucursales.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
};

export default ModalSucursales;