// React

// Components
import { gql, useQuery } from "@apollo/client";
import { Card, Grid, Icon, Menu, MenuItem, Skeleton, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { UserContext } from "context/user";
import { useContext, useState } from "react";
import { getUproColor } from "utils/colors";
import ModalStock from "./components/ModalStock";
import Movimientos from "./components/Movimientos";
import Solicitudes from "./components/Solicitudes";
import ModalStockRapido from "../productos/components/ModalStockRapido";

function Sucursal() {
  const [openSucursalMenu, setOpenSucursalMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [openModalStock, setOpenModalStock] = useState(false);
  const [openModalMantenimiento, setOpenModalMantenimiento] = useState(false);
  const [openModalStockRapido, setOpenModalStockRapido] = useState(false);

  const { user, verificarAcceso } = useContext(UserContext);

  const { data: dataSucurales, loading: loadingSucursales } = useQuery(
    gql`
      query getSucursales($filter: JSON) {
        sucursales(filter: $filter) {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `,
    {
      variables: {
        filter: {
          id: user?.sucursales ? user?.sucursales.map((sucursal) => sucursal.id) : undefined,
          administraStock: false,
        },
      },
      onCompleted: (data) => {
        if (data?.sucursales?.length > 0) {
          setSelectedSucursal(data?.sucursales[0].id);
        }
      },
    }
  );

  const handleOpenSucursalMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSucursalMenu(true);
  };

  const handleCloseSucursalMenu = () => {
    setAnchorEl(null);
    setOpenSucursalMenu(false);
  };

  const renderSucursalMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openSucursalMenu)}
      onClose={handleCloseSucursalMenu}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "scroll",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      {dataSucurales?.sucursales?.map((sucursal, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setSelectedSucursal(sucursal.id);
            handleCloseSucursalMenu();
          }}
          sx={{
            minWidth: { xs: "75vw", sm: "auto" },
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p">
                {sucursal.nombre + " - " + sucursal.sede.nombre}
              </SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    sucursal.id === selectedSucursal
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                location_on
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={2}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                  <SoftBox>
                    <SoftBox
                      display="flex"
                      alignItems="center"
                      onClick={
                        dataSucurales?.sucursales?.length > 0 ? handleOpenSucursalMenu : null
                      }
                    >
                      <Icon color="primary">location_on</Icon>
                      <SoftTypography
                        variant="h6"
                        sx={{
                          cursor: "pointer",
                        }}
                        ml={1}
                      >
                        {loadingSucursales ? (
                          <Skeleton width={100} />
                        ) : dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.nombre ? (
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.nombre +
                          " - " +
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.sede.nombre
                        ) : (
                          "No hay sucursales disponibles"
                        )}
                      </SoftTypography>
                      <Icon color="dark">
                        {openSucursalMenu ? "arrow_drop_up" : "arrow_drop_down"}
                      </Icon>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox display="flex" gap={2}>
                    {verificarAcceso(31) && selectedSucursal && (
                      <Tooltip title="Solicitar mantenimiento" placement="top">
                        <SoftBox>
                          <SoftButton
                            color="sistemasGris"
                            sx={{ display: { xs: "none", sm: "flex" } }}
                            circular
                            onClick={() => setOpenModalMantenimiento(true)}
                          >
                            <Icon>handyman</Icon>
                            &nbsp; Mantenimiento
                          </SoftButton>
                          <SoftButton
                            color="sistemasGris"
                            sx={{ display: { xs: "block", sm: "none" } }}
                            circular
                            iconOnly
                            onClick={() => setOpenModalMantenimiento(true)}
                          >
                            <Icon>handyman</Icon>
                          </SoftButton>
                        </SoftBox>
                      </Tooltip>
                    )}
                    {selectedSucursal && (
                      <Tooltip title="Stock inicial rápido" placement="top">
                        <SoftBox>
                          <SoftButton
                            sx={{ display: { xs: "none", sm: "flex" } }}
                            color="sistemasGris"
                            circular
                            onClick={() => setOpenModalStockRapido(true)}
                          >
                            <Icon>electric_bolt</Icon>
                            &nbsp; Stock rápido
                          </SoftButton>
                          <SoftButton
                            sx={{ display: { xs: "block", sm: "none" } }}
                            color="sistemasGris"
                            circular
                            iconOnly
                            onClick={() => setOpenModalStockRapido(true)}
                          >
                            <Icon>electric_bolt</Icon>
                          </SoftButton>
                        </SoftBox>
                      </Tooltip>
                    )}
                    {verificarAcceso(27) && selectedSucursal && (
                      <Tooltip title="Ver stock" placement="top">
                        <SoftBox>
                          <SoftButton
                            sx={{ display: { xs: "none", sm: "flex" } }}
                            color="primary"
                            circular
                            onClick={() => setOpenModalStock(true)}
                          >
                            <Icon>warehouse</Icon>
                            &nbsp; Stock
                          </SoftButton>
                          <SoftButton
                            sx={{ display: { xs: "block", sm: "none" } }}
                            color="primary"
                            circular
                            iconOnly
                            onClick={() => setOpenModalStock(true)}
                          >
                            <Icon>warehouse</Icon>
                          </SoftButton>
                        </SoftBox>
                      </Tooltip>
                    )}
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
        {renderSucursalMenu}

        {selectedSucursal ? (
          <Grid container spacing={2}>
            {verificarAcceso(11) && (
              <Grid item xs={12} md={verificarAcceso(12) ? 7 : 12}>
                <Card>
                  <Movimientos idSucursal={selectedSucursal} />
                </Card>
              </Grid>
            )}
            {verificarAcceso(12) && (
              <Grid item xs={12} md={verificarAcceso(11) ? 5 : 12}>
                <Card>
                  <Solicitudes idSucursal={selectedSucursal} />
                </Card>
              </Grid>
            )}
          </Grid>
        ) : (
          <Card>
            <Grid container textAlign={"center"} py={3}>
              <Grid item xs={12}>
                <Icon
                  sx={{
                    width: "50px",
                  }}
                >
                  error_outline
                </Icon>
              </Grid>
              <Grid item xs={12}>
                <SoftTypography variant="h6">No hay sucursal seleccionada</SoftTypography>
              </Grid>
              <Grid item xs={12}>
                <SoftTypography variant="caption" component="p" color="text">
                  Debe seleccionar una sucursal para ver su información
                </SoftTypography>
              </Grid>
            </Grid>
          </Card>
        )}
      </SoftBox>
      <ModalStock
        open={openModalStock}
        handleClose={() => setOpenModalStock(false)}
        idSucursal={selectedSucursal}
      />
      <ModalStockRapido
        open={openModalStockRapido}
        handleClose={() => setOpenModalStockRapido(false)}
        idSucursal={selectedSucursal}
        refetch={() => {}}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Sucursal;
