// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "55%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCambioEtapa({ open, handleClose,etapas,fase,guardarFase}) {
    const [nuevoEtapa,setNuevaEtapa] = useState(null);

    useEffect(() => {
        if(open){
            setNuevaEtapa(fase?.idEtapa);
        }
    }, [open]);

    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        >
        <Fade in={open}>
            <Card sx={style}>
            <SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                    <SoftTypography variant="h6">Mover Fase</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                        <Icon fontSize="medium" 
                        onClick={() => {
                        handleClose();
                        }}
                        sx={{ cursor: "pointer" }}>
                        close
                        </Icon>
                    </Tooltip>
                    </SoftBox>
                </SoftBox>
                </SoftBox>

                <SoftBox mb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                            <InputLabel htmlFor="cantidad">
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Etapa
                                <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                    &nbsp;*
                                </SoftTypography>
                                </SoftTypography>
                            </InputLabel>
                            </SoftBox>
                            <Select
                            label="Etapa"
                            name="etapa"
                            value={nuevoEtapa || -1 }
                            onChange={(e) => setNuevaEtapa(e.target.value)}
                            >
                            <MenuItem value={-1} disabled>Seleccione una etapa</MenuItem>
                            {
                                etapas?.map((etapa, index) => (
                                <MenuItem key={index} value={etapa.id}>{etapa.nombre}</MenuItem>
                                ))
                            }
                            </Select>
                        </Grid>
                    </Grid>
                </SoftBox>
            
                <SoftBox
                display={{
                    xs: "flex-row",
                    sm: "flex",
                }}
                justifyContent="flex-end"
                alignItems="center"
                >
                <SoftBox
                    display={{
                    xs: "flex-row",
                    sm: "flex",
                    }}
                    justifyContent="end"
                    alignItems="center"
                >
                    <SoftBox
                    mr={{
                        xs: 0,
                        sm: 2,
                    }}
                    mt={{
                        xs: 2,
                        sm: 0,
                    }}
                    >
                    <SoftButton
                        color="primary"
                        circular
                        fullWidth={{
                        xs: true,
                        sm: false,
                        }}
                        onClick={()=>{
                         handleClose();
                        }}
                    >
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                    </SoftButton>
                    </SoftBox>
                    <SoftBox
                    mt={{
                        xs: 2,
                        sm: 0,
                    }}
                    >
                    <SoftButton
                        color="sistemasGris"
                        circular
                        fullWidth={{
                        xs: true,
                        sm: false,
                        }}
                        onClick={() => {
                            guardarFase({
                                ...fase,
                                idEtapa: nuevoEtapa
                            });
                        }}
                        disabled={!nuevoEtapa || (nuevoEtapa == fase?.idEtapa)}
                    >
                        <Icon sx={{ fontWeight: "light" }}>save</Icon>
                        &nbsp;Guardar
                    </SoftButton>
                    </SoftBox>
                </SoftBox>
                </SoftBox>
            </SoftBox>
            </Card>
        </Fade>
        </Modal>
    );
}

ModalCambioEtapa.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  etapas: PropTypes.array,
  fase: PropTypes.object,
  guardarFase: PropTypes.func,
};
