// React
import { useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, MenuItem, Modal, Select, Tooltip } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { UserContext } from "context/user";
import solicitudesData from "../../data/solicitudesData";
import ModalSolicitud from "../ModalSolicitud";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
};

export default function ModalSolicitudes({ open, handleClose, idSucursal, refetchProductos }) {
  const [openModalNuevaSolicitud, setOpenModalNuevaSolicitud] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedSolicitud, setSelectedSolicitud] = useState();
  const [sucursales, setSucursales] = useState([]);
  const { columns, getRows } = solicitudesData;
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "createdAt",
    orden: "DESC",
  });
  const [filter, setFilter] = useState({
    estado: "Todos los estados",
    tipo: "Prestamos y Transferencias",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const {
    data: solicitudes,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getSolicitudes($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        solicitudes(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          estado
          tipo
          vencimiento
          user {
            id
            username
          }
          sucursal {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          movimiento {
            id
            observaciones
            vencimiento
            movimientoDetalles {
              id
              cantidad
              producto {
                id
                nombre
                codigo
                tipo
                categoria
              }
            }
            user {
              id
              username
            }
          }
          observaciones
          solicitudDetalles {
            id
            producto {
              tipo
              id
              codigo
              nombre
              categoria
            }
            cantidad
          }
          createdAt
        }
      }
    `,
    {
      variables: {
        filter: {
          idSucursal: sucursales,
          estado:
            filter.estado === "Todos los estados"
              ? ["Aceptado", "En curso", "Finalizado"]
              : filter.estado,
          tipo:
            filter.tipo === "Prestamos y Transferencias"
              ? ["Prestamo", "Transferencia"]
              : [filter.tipo],
        },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const handleOpenSolicitud = (solicitud) => {
    setSelectedSolicitud(solicitud);
    setOpenModalNuevaSolicitud(true);
  };

  const handleChangeFilter = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (solicitudes && solicitudes?.solicitudes) {
      setRows(getRows(solicitudes.solicitudes, handleOpenSolicitud, idSucursal));
      setPagesInfo(solicitudes?.paginationInfo);
    }
  }, [solicitudes]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData?.sucursales?.find((sucursal) => sucursal?.id === idSucursal);
      setSucursales(
        sucursalesData?.sucursales
          .filter((sucursal) => sucursal?.sede?.id === thisSede?.sede?.id)
          .map((sucursal) => sucursal?.id)
      );
    }
  }, [sucursalesData, idSucursal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" p={3}>
              <SoftBox display="flex" alignItems="center">
                <SoftTypography variant="h6">Solicitudes</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="end">
                <SoftBox mr={2}>
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <SoftBox mr={2}>
                  <Select value={filter.estado} name="estado" onChange={handleChangeFilter}>
                    <MenuItem value="Todos los estados">Todos los estados</MenuItem>
                    <MenuItem value="Aceptado">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox>
                          <SoftTypography variant="p">Nuevo</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("sistemasAmarillo"),
                            }}
                          >
                            new_releases
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                    <MenuItem value="En curso">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox>
                          <SoftTypography variant="p">En curso</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("sistemasNegro"),
                            }}
                          >
                            schedule_send
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                    <MenuItem value="Finalizado">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox>
                          <SoftTypography variant="p">Finalizado</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("sistemasGris"),
                            }}
                          >
                            done_all
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                  </Select>
                </SoftBox>
                <SoftBox mr={2}>
                  <Select value={filter.tipo} name="tipo" onChange={handleChangeFilter}>
                    <MenuItem value="Prestamos y Transferencias">
                      Prestamos y Transferencias
                    </MenuItem>
                    <MenuItem value="Prestamo">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox>
                          <SoftTypography variant="p">Prestamos</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("sistemasAmarillo"),
                            }}
                          >
                            autorenew
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                    <MenuItem value="Transferencia">
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <SoftBox>
                          <SoftTypography variant="p">Transferencias</SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <Icon
                            fontSize="small"
                            sx={{
                              verticalAlign: "middle",
                              color: getUproColor("sistemasNegro"),
                            }}
                          >
                            import_export
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </MenuItem>
                  </Select>
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              {loading ? (
                <Loading />
              ) : solicitudes?.solicitudes?.length > 0 ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={solicitudes?.solicitudes?.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
            <ModalSolicitud
              open={openModalNuevaSolicitud}
              handleClose={() => {
                setOpenModalNuevaSolicitud(false);
                setSelectedSolicitud();
              }}
              selectedSolicitud={selectedSolicitud}
              idSucursal={idSucursal}
              refetchSolicitudes={() => {
                refetch();
                refetchProductos();
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSolicitudes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
