import * as React from "react";
import { Modal, Fade, Backdrop, Card, Grid, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
  py: 4,
};

export default function ModalAprobar({ open, handleClose, functionAprobar }) {
  const [motivoAprobacion, setMotivoAprobacion] = React.useState("");
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3}>
            <SoftTypography variant="h6">Aprobar presupuesto</SoftTypography>
          </SoftBox>
          <SoftBox px={3}>
            <Grid container justifyContent="start" alignItems="center" pb={1} pt={2}>
              <Grid item xs={12}>
                <SoftTypography variant="h6" fontWeight="light">
                  ¿Esta seguro que quiere aprobar el presupuesto? Presione &quot;Aprobar&quot; para confirmar
                </SoftTypography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="motivoAprobacion">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Motivo de la aprobación
                </SoftTypography>
              </InputLabel>
              <SoftInput
                variant="outlined"
                name="motivoAprobacion"
                multiline
                rows={4}
                value={motivoAprobacion}
                onChange={(event) => setMotivoAprobacion(event.target.value)}
              />
            </Grid>
          </SoftBox>

          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3} pt={3}>
            <SoftBox mr={2}>
              <SoftButton variant="gradient" color="dark" onClick={handleClose} circular>
                Cancelar
              </SoftButton>
            </SoftBox>
            <SoftBox>
              <SoftButton variant="gradient" color="primary" 
               onClick={()=>{
                functionAprobar(motivoAprobacion);
                setMotivoAprobacion("");
              }}
              circular
              >
                Aprobar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAprobar.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  functionAprobar: PropTypes.func,
};
