// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, CircularProgress, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import SoftInput from "components/SoftInput";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "50%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalCertificado({ open, handleClose, refetch, selectedProyecto }) {
  const [proyecto, setProyecto] = useState();
  const [proyectoOld, setProyectoOld] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [saveProyecto, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProyecto($input: ProyectoInput!) {
        saveProyecto(input: $input) {
          id
        }
      }
    `
  );

  const handleSave = () => {
    saveProyecto({
      variables: {
        input: {
          ...proyecto,
        },
      },
    })
      .then(() => {
        proyecto.id
          ? handleSnackbar("Proyecto actualizado correctamente", "success")
          : handleSnackbar("Proyecto creado correctamente", "success");
        refetch();
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el proyecto", "error");
      });
  };

  const hayCambios = (oldData, newData) => {
    let cambios = false;
    if (oldData && newData) {
      Object.keys(oldData).forEach((key) => {
        if (oldData[key] !== newData[key]) {
          cambios = true;
        }
      });
      Object.keys(newData).forEach((key) => {
        if (oldData.hasOwnProperty(key) && !newData.hasOwnProperty(key)) {
          cambios = true;
        }
      });
    }
    return cambios;
  };

  useEffect(() => {
    setProyecto();
    if (open && selectedProyecto) {
      let proyectoData = {
        ...selectedProyecto,
        idCliente: selectedProyecto?.cliente?.id,
        idResponsable: selectedProyecto?.responsable?.id,
        idVendedor: selectedProyecto?.vendedor?.id,
        idProvincia: selectedProyecto?.localidad?.provincia?.id,
        idLocalidad: selectedProyecto?.localidad?.id,
      };
      delete proyectoData.cliente;
      delete proyectoData.responsable;
      delete proyectoData.vendedor;
      delete proyectoData.localidad;
      delete proyectoData.__typename;
      Object.keys(proyectoData).forEach(
        (key) => proyectoData[key] == null && delete proyectoData[key]
      );
      setProyectoOld(proyectoData);
      setProyecto(proyectoData);
    }
  }, [open, selectedProyecto]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Certificar avance</SoftTypography>
              </SoftBox>

              <SoftBox display="flex" justifyContent="flex-end">
                <SoftBox mr={2}>
                  <Tooltip title="Agregar nuevo presupuesto" placement="top">
                    <Link
                      to={`/proyectos/nuevoPresupuesto`}
                      state={{ idProyecto: selectedProyecto?.id }}
                    >
                      <SoftButton color="primary" circular>
                        Agregar Certificado&nbsp;
                        <Icon>add</Icon>
                      </SoftButton>
                    </Link>
                  </Tooltip>
                </SoftBox>
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              <SoftBox mb={2}>
                <Card>
                  <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
                    <SoftBox display="flex">
                      <SoftBox lineHeight={1}>
                        <SoftTypography variant="button">{`Fase Nº1`}</SoftTypography>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="h6" fontWeight="bold" color={"primary"}>
                            Cimientos
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox>
                        <SoftTypography variant="h5">50%</SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" mx={2} alignItems="center">
                        <Icon color="primary" fontSize="small">
                          arrow_forward
                        </Icon>
                      </SoftBox>
                      <SoftBox
                        sx={{
                          width: "120px",
                        }}
                      >
                        <SoftInput
                          type="number"
                          icon={{
                            component: "percent",
                            direction: "right",
                          }}
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
              <SoftBox>
                <Card>
                  <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
                    <SoftBox display="flex">
                      <SoftBox lineHeight={1}>
                        <SoftTypography variant="button">{`Fase Nº2`}</SoftTypography>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="h6" fontWeight="bold" color={"primary"}>
                            Construcción de paredes
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox>
                        <SoftTypography variant="h5">0%</SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" mx={2} alignItems="center">
                        <Icon color="primary" fontSize="small">
                          arrow_forward
                        </Icon>
                      </SoftBox>
                      <SoftBox
                        sx={{
                          width: "120px",
                        }}
                      >
                        <SoftInput
                          type="number"
                          icon={{
                            component: "percent",
                            direction: "right",
                          }}
                        />
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="sistemasGris"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={null}
                >
                  {loadingSave ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>verified</Icon>
                  )}
                  &nbsp;Certificar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalCertificado.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedProyecto: PropTypes.object,
};
