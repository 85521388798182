// React

// Components
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Icon,
  InputLabel,
  Skeleton,
} from "@mui/material";
import SinDatos from "components/Carteles/SinDatos";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import ModalVencimientoDocumentacion from "layouts/compras/ModalProveedores/VencimientoDocumentacion";
import { useContext, useEffect, useState } from "react";
import DocumentacionItem from "./components/DocumentoItem";
import ModalMotivoRechazo from "./components/MotivoRechazo";
import { makeNiceText } from "utils/formatters";

function PerfilProveedor() {
  const [openModalVencimiento, setOpenModalVencimiento] = useState(false);
  const [fechaVencimiento, setFechaVencimiento] = useState(dayjs().toDate());
  const [selectedFile, setSelectedFile] = useState(null);
  const [openModalFinalizar, setOpenModalFinalizar] = useState(false);
  const [loadingSolicitud, setLoadingSolicitud] = useState(false);
  const { user } = useContext(UserContext);
  const [openModalMotivo, setOpenModalMotivo] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const [files, setFiles] = useState([
    {
      nombre: "Acta de Asamblea Ordinaria en Sociedades",
      tipo: "General",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Balance Periodo 2021 Certificado por el Consejo Profesional de Ciencias Economicas",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Balance Periodo 2022 Certificado por el Consejo Profesional de Ciencias Economicas",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Balance Periodo 2023 Certificado por el Consejo Profesional de Ciencias Economicas",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "CBU Emitido y Certificado por el Banco",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Certificado Anual Ambiental de la Provincia de Origen",
      tipo: "Rubro",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Certificado de Morosos Alimentarios",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Certificado Fiscalia de Estado de las Autoridades",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Certificado de Deudores Morosos Certificado Fiscalia de Estado del Proveedor",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Certificado de No Poseer Juicio con el Estado Provincial",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Constancia de Inscripcion en AFIP",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Constancia de Inscripcion Ingresos Brutos o Convenio Multilateral",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Contrato Constitutivo Certificado por Escribano",
      tipo: "General",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Copia de D.N.I Certificada por Autoridad Competente",
      tipo: "General",
      obligatorio: true,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Decreto",
      tipo: "General",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Habilitacion Comercial",
      tipo: "Equipos e Insumos Informaticos",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Habilitacion Comercial",
      tipo: "Servicios de Desarrollo y/o Provision de Software",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre:
        "Modificacion del Estatuto Certificado por Escribano y Aprobado por el Registro Publico de Comercio",
      tipo: "General",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
    {
      nombre: "Titulo Habilitante",
      tipo: "Rubro - Servicios de Desarrollo y/o Provision de Software",
      obligatorio: false,
      file: null,
      fechaVencimiento: null,
    },
  ]);

  const { data, loading, refetch } = useQuery(gql`
    query GetProveedorUser {
      proveedorUser {
        id
        tipoProveedor
        razonSocial
        nombre
        apellido
        tipoDocumento
        documento
        tipoResponsable
        localidad {
          id
          nombre
          provincia {
            id
            nombre
          }
        }
        documentos {
          id
          nombre
          descripcion
          file
          fechaVencimiento
          createdAt
          estado
          comentario
        }
      }
    }
  `);

  const [proveedor, setProveedor] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [addProveedor, { loading: loadingSave }] = useMutation(
    gql`
      mutation addProveedor($input: ProveedorInput!) {
        saveProveedor(input: $input) {
          id
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const [saveFile, { loading: loadingSaveFile }] = useMutation(
    gql`
      mutation addProveedorFile(
        $idProveedor: ID!
        $file: Upload!
        $fechaVencimiento: Date
        $descripcion: String
      ) {
        addProveedorFile(
          idProveedor: $idProveedor
          file: $file
          fechaVencimiento: $fechaVencimiento
          descripcion: $descripcion
        ) {
          id
        }
      }
    `
  );

  const [deleteFile, { loading: loadingDelete }] = useMutation(
    gql`
      mutation deleteProveedorFile($id: ID!) {
        deleteProveedorFile(id: $id)
      }
    `,
    {
      onCompleted: () => {
        handleSnackbar("Archivo eliminado correctamente", "success");
        refetch();
      },
      onError: (error) => {
        handleSnackbar(error.message, "error");
      },
    }
  );

  const [updateProveedorFileStatus] = useMutation(
    gql`
      mutation updateProveedorFileStatus($id: ID!, $estado: String!, $comentario: String) {
        updateProveedorFileStatus(id: $id, estado: $estado, comentario: $comentario) {
          id
        }
      }
    `
  );

  const handleSave = () => {
    let input = {
      ...proveedor,
      id: data?.proveedorUser?.id,
    };
    addProveedor({
      variables: {
        input: input,
      },
    })
      .then(() => {
        handleSnackbar("Proveedor guardado correctamente", "success");
        refetch();
        setProveedor();
      })
      .catch((error) => {
        handleSnackbar("Error al agregar el proveedor", "error");
      });
  };

  const handleChanges = (e) => {
    setProveedor({
      ...proveedor,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (proveedor?.idProvincia || data?.proveedorUser) {
      getLocalidades({
        variables: {
          provincia: proveedor?.idProvincia || data?.proveedorUser?.localidad?.provincia?.id,
        },
      }).then((data) => {
        // Si la localidad no pertenece a la provincia seleccionada, se limpia el campo
        if (
          proveedor?.idProvincia &&
          !data?.data?.localidades?.find((localidad) => localidad.id === proveedor?.idLocalidad)
        ) {
          setProveedor({
            ...proveedor,
            idLocalidad: -1,
          });
        }
      });
    }
  }, [proveedor?.idProvincia, data]);

  useEffect(() => {
    if (data?.proveedorUser?.documentos) {
      const newFiles = [...files];
      data?.proveedorUser?.documentos.forEach((documento) => {
        const index = newFiles.findIndex((file) => file.nombre === documento.descripcion);
        if (index !== -1) {
          newFiles[index].url = documento.file;
          newFiles[index].fechaVencimiento = documento.fechaVencimiento;
          newFiles[index].createdAt = documento.createdAt;
          newFiles[index].id = documento.id;
          newFiles[index].estado = documento.estado;
          newFiles[index].comentario = documento.comentario;
        }
      });
      setFiles(newFiles);
    }
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        {user?.tipoUser === "Proveedor" ? (
          <SoftBox mb={3}>
            <SoftBox mb={2}>
              <Collapse in={true} timeout={500}>
                <Card>
                  <SoftBox
                    sx={{
                      width: "100%",
                    }}
                    p={2}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="start"
                      texAlign="start"
                    >
                      <SoftBox display="flex-row" alignItems="center">
                        <SoftBox lineHeight={1} display="flex" alignItems="center">
                          <SoftBox mr={1}>
                            <Icon color={"info"} fontSize="small">
                              info
                            </Icon>
                          </SoftBox>

                          <SoftTypography variant="h7" fontWeight="bold" color={"info"}>
                            Solicitar aprobación
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SoftBox
                          sx={{
                            lineHeight: 1,
                          }}
                        >
                          <SoftTypography variant="caption">
                            {`Para solicitar la aprobación como proveedor de la Sistemas Constructivos, debe subir los
                            documentos requeridos y luego presionar el botón "Solicitar aprobación". Una vez
                            que los documentos sean revisados, se le notificará si su solicitud fue aprobada o
                            si debe modificar algún documento.`}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </Card>
              </Collapse>
            </SoftBox>
            <Card
              sx={{
                mb: 2,
              }}
            >
              <SoftBox p={3}>
                <SoftBox
                  mb={2}
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftTypography variant="h6">Datos del proveedor</SoftTypography>
                </SoftBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="tipoProveedor">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Tipo de proveedor
                      </SoftTypography>
                    </InputLabel>
                    {loading ? (
                      <SoftTypography variant="h2">
                        <Skeleton />
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="h6">
                        {data?.proveedorUser?.tipoProveedor}
                      </SoftTypography>
                    )}
                  </Grid>
                  {proveedor?.tipoProveedor === "Empresa" && (
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="razonSocial">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Razon Social
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      {loading ? (
                        <SoftTypography variant="h2">
                          <Skeleton />
                        </SoftTypography>
                      ) : (
                        <SoftTypography variant="h6">
                          {data?.proveedorUser?.razonSocial}
                        </SoftTypography>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre
                      </SoftTypography>
                    </InputLabel>
                    {loading ? (
                      <SoftTypography variant="h2">
                        <Skeleton />
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="h6">
                        {makeNiceText(
                          `${data?.proveedorUser?.nombre} ${data?.proveedorUser?.apellido}`,
                          "nombre"
                        )}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="tipoDocumento">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Documento
                      </SoftTypography>
                    </InputLabel>
                    {loading ? (
                      <SoftTypography variant="h2">
                        <Skeleton />
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="h6">
                        {`${
                          data?.proveedorUser?.tipoDocumento === 96
                            ? "DNI"
                            : data?.proveedorUser?.tipoDocumento === 80
                            ? "CUIT"
                            : "CUIL"
                        } ${data?.proveedorUser?.documento}`}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="tipoResponsable">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Tipo de responsable
                      </SoftTypography>
                    </InputLabel>
                    {loading ? (
                      <SoftTypography variant="h2">
                        <Skeleton />
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="h6">
                        {data?.proveedorUser?.tipoResponsable === 1
                          ? "IVA Responsable Inscripto"
                          : data?.proveedorUser?.tipoResponsable === 2
                          ? "IVA Responsable no Inscripto"
                          : data?.proveedorUser?.tipoResponsable === 3
                          ? "IVA no Responsable"
                          : data?.proveedorUser?.tipoResponsable === 4
                          ? "IVA Sujeto Exento"
                          : data?.proveedorUser?.tipoResponsable === 5
                          ? "Consumidor Final"
                          : data?.proveedorUser?.tipoResponsable === 6
                          ? "Responsable Monotributo"
                          : data?.proveedorUser?.tipoResponsable === 7
                          ? "Sujeto no Categorizado"
                          : data?.proveedorUser?.tipoResponsable === 8
                          ? "Proveedor del Exterior"
                          : data?.proveedorUser?.tipoResponsable === 9
                          ? "Cliente del Exterior"
                          : data?.proveedorUser?.tipoResponsable === 10
                          ? "IVA Liberado – Ley Nº 19.640"
                          : data?.proveedorUser?.tipoResponsable === 11
                          ? "IVA Responsable Inscripto – Agente de Percepción"
                          : data?.proveedorUser?.tipoResponsable === 12
                          ? "Pequeño Contribuyente Eventual"
                          : data?.proveedorUser?.tipoResponsable === 13
                          ? "Monotributista Social"
                          : data?.proveedorUser?.tipoResponsable === 14
                          ? "Pequeño Contribuyente Eventual Social"
                          : ""}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={proveedor?.tipoProveedor === "Empresa" ? 4 : 6}>
                    <InputLabel htmlFor="provincia">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Ubicación
                      </SoftTypography>
                    </InputLabel>
                    {loading ? (
                      <SoftTypography variant="h2">
                        <Skeleton />
                      </SoftTypography>
                    ) : (
                      <SoftTypography variant="h6">
                        {`${data?.proveedorUser?.localidad?.nombre}, ${data?.proveedorUser?.localidad?.provincia?.nombre}`}
                      </SoftTypography>
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
            <Card>
              <SoftBox p={3}>
                <SoftBox
                  mb={3}
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    mb={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftTypography variant="h6">Documentación requerida</SoftTypography>
                    <SoftTypography variant="caption">
                      Esta documentación es requerida para solicitar la aprobación como proveedor de
                      la Sistemas Constructivos
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftButton
                      color="primary"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      disabled={
                        files.filter((file) => file.obligatorio && !file.url).length > 0 ||
                        files.filter((file) => !file.estado && file.url).length < 1 ||
                        loadingSolicitud
                      }
                      onClick={() => {
                        let promises = [];
                        setLoadingSolicitud(true);
                        files
                          .filter((file) => file.url && !file.estado)
                          .forEach((file) => {
                            promises.push(
                              updateProveedorFileStatus({
                                variables: { id: file.id, estado: "Pendiente", comentario: null },
                              })
                            );
                          });
                        Promise.all(promises)
                          .then(() => {
                            handleSnackbar("Solicitud enviada correctamente", "success");
                            refetch();
                            setLoadingSolicitud(false);
                          })
                          .catch(() => {
                            handleSnackbar("Error al enviar la solicitud", "error");
                            refetch();
                            setLoadingSolicitud(false);
                          });
                      }}
                    >
                      {loadingSolicitud ? (
                        <CircularProgress size={15} color="white" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>published_with_changes</Icon>
                      )}
                      &nbsp;
                      {loadingSolicitud ? "Enviando solicitud" : "Solicitar aprobación"}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <SoftBox display="flex" flexDirection="column">
                    {files?.map((file, index) => (
                      <>
                        {index > 0 && <Divider />}
                        <DocumentacionItem
                          key={index}
                          name={file?.file?.name || file.nombre}
                          description={
                            file?.createdAt
                              ? `Subido el ${dayjs(file.createdAt).format("DD/MM/YYYY")}`
                              : file?.file?.lastModifiedDate
                              ? dayjs(file.lastModifiedDate).format("DD/MM/YYYY")
                              : file.obligatorio
                              ? "Obligatorio"
                              : "Opcional"
                          }
                          value={file.file}
                          handleDelete={(id) => {
                            setSelectedId(id);
                            deleteFile({ variables: { id } }).then(() => {
                              const newFiles = [...files];
                              newFiles[index].file = null;
                              newFiles[index].url = null;
                              newFiles[index].fechaVencimiento = null;
                              newFiles[index].createdAt = null;
                              setFiles(newFiles);
                            });
                          }}
                          saveFile={(archivo) => {
                            setSelectedFile({ archivo, index });
                            setOpenModalVencimiento(true);
                          }}
                          file={file}
                          handleOpenMotivo={() => {
                            setSelectedFile({ archivo: file, index });
                            setOpenModalMotivo(true);
                          }}
                          loading={loadingSaveFile || loadingDelete ? selectedId : null}
                        />
                      </>
                    ))}
                    {files.length === 0 && <SinDatos />}
                  </SoftBox>
                </SoftBox>
                <ModalVencimientoDocumentacion
                  open={openModalVencimiento}
                  handleClose={() => setOpenModalVencimiento(false)}
                  fechaVencimiento={dayjs(fechaVencimiento).format("YYYY-MM-DD")}
                  setFechaVencimiento={setFechaVencimiento}
                  handleSave={() => {
                    const newFiles = [...files];
                    let fileIndex = newFiles[selectedFile.index];
                    fileIndex.file = selectedFile.archivo;
                    fileIndex.fechaVencimiento = fechaVencimiento;
                    newFiles[selectedFile.index] = fileIndex;

                    saveFile({
                      variables: {
                        idProveedor: data?.proveedorUser?.id,
                        file: selectedFile.archivo,
                        fechaVencimiento: dayjs(fechaVencimiento).format("YYYY-MM-DD"),
                        descripcion: fileIndex.nombre,
                      },
                    }).then(() => {
                      handleSnackbar("Archivo guardado correctamente", "success");
                      refetch();
                      setFiles(newFiles);
                      setSelectedFile(null);
                      setFechaVencimiento(new Date());
                      setOpenModalVencimiento(false);
                    });
                  }}
                  loading={loadingSaveFile}
                />
                <ModalMotivoRechazo
                  open={openModalMotivo}
                  handleClose={() => setOpenModalMotivo(false)}
                  motivo={selectedFile?.archivo?.comentario}
                />
              </SoftBox>
            </Card>
          </SoftBox>
        ) : (
          <SoftBox mb={3}>
            <Card>
              <SoftBox p={3}>
                <SoftTypography variant="h6">Proveedor</SoftTypography>
              </SoftBox>
              <Grid container textAlign={"center"} py={3}>
                <Grid item xs={12}>
                  <Icon fontSize="md" color="primary">
                    lock
                  </Icon>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">Debe ser proveedor para acceder</SoftTypography>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="caption" component="p" color="text">
                    Solo los proveedores pueden acceder a esta pantalla
                  </SoftTypography>
                </Grid>
              </Grid>
            </Card>
          </SoftBox>
        )}
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PerfilProveedor;
