// React
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState, useContext } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import TableItem from "../TableItemTransferir";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import { ConfirmarContext } from "context/ConfirmarContext";
import ModalAddPlantilla from "../../ModalAddPlantilla";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "stock", desc: "Stock Disponible", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "center", width: "100px" },
  { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "left" },
];

export default function ModalNuevaTransferencia({
  open,
  handleClose,
  selectedTransferencia,
  idSucursal,
  refetchProductos,
}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [productoToAdd, setProductoToAdd] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [remito, setRemito] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [productosRemito, setProductosRemito] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const [sucursales, setSucursales] = useState([]);
  const [openModalAddPlantiilla, setOpenModalAddPlantiilla] = useState(false);
  const [openMenuPlantilla, setOpenMenuPlantilla] = useState(false);
  const { confirmar } = useContext(ConfirmarContext);
  const [administrarPlantillas, setAdministrarPlantillas] = useState(false);

  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          categoria
          nombre
          disabled
          precio
          stock(idSucursal: $idSucursal) {
            cantidad
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  const [saveRemito, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const [getSucursalesTransferencia,{data: sucursalesData}] = useLazyQuery(
    gql`
      query getSucursalesTransferencia($filter: JSON) {
        sucursales(filter: $filter) {
          id
          nombre
          administraStock
          sede {
            id
            nombre
          }
        }
      }
    `, {
      fetchPolicy: "network-only",
    }
  );
  // const { data: sucursalesData } = useQuery(
  //   gql`
  //     query getSucursalesTransferencia {
  //       sucursales(filter: { administraStock: true, principal: true }) {
  //         id
  //         nombre
  //         administraStock
  //         sede {
  //           id
  //           nombre
  //         }
  //       }
  //     }
  //   `
  // );

  const [
    getPlantillas,
    { data: plantillas, refetch: refetchPlantillas, loading: loadingPlantillas },
  ] = useLazyQuery(
    gql`
      query getPlantillas($page: Int, $order: Order, $filter: JSON) {
        plantillas(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          nombre
          user {
            id
            nombre
            apellido
            username
          }
          plantillaDetalles {
            id
            cantidad
            producto {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [deletePlantilla, { loading: loadingDeletePlantilla }] = useMutation(
    gql`
      mutation deletePlantilla($id: ID!) {
        deletePlantilla(id: $id) {
          id
        }
      }
    `
  );

  const handleOpenPlantillaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuPlantilla(true);
  };

  const handleClosePlantillaMenu = () => {
    setAnchorEl(null);
    setOpenMenuPlantilla(false);
  };

  const handleApplyPlantilla = (plantilla) => {
    setProductosRemito([]);
    let idsProductos = plantilla.plantillaDetalles.map((detalle) => detalle?.producto?.id);
    getProductos({
      variables: {
        page: 1,
        filter: {
          id: { _in: idsProductos },
          disabled: false,
        },
        idSucursal: idSucursal,
      },
    }).then((data) => {
      let newProductosRemito = [];
      data.data.productos.forEach((producto) => {
        newProductosRemito.push({
          ...producto,
          stock: { cantidad: producto.stock?.cantidad || 0 },
          cantidad:
            plantilla.plantillaDetalles.find((detalle) => detalle?.producto?.id === producto.id)
              ?.cantidad || 0,
        });
      });
      if (newProductosRemito.length === 0) {
        handleSnackbar("Los productos de la plantilla ya no existen en el sistema", "error");
      } else if (
        newProductosRemito.length > 0 &&
        newProductosRemito.length !== plantilla.plantillaDetalles.length
      ) {
        setProductosRemito(newProductosRemito);
        handleSnackbar(
          "La plantilla contiene algunos productos que ya no existen en el sistema",
          "info"
        );
      } else {
        setProductosRemito(newProductosRemito);
        handleSnackbar("Plantilla aplicada correctamente", "success");
      }
    });
    handleClosePlantillaMenu();
  };

  const handleDeletePlantilla = (plantilla) => {
    confirmar({
      title: "Eliminar plantilla",
      message: "¿Está seguro que desea eliminar esta plantilla?",
      icon: "warning",
      func: () => {
        deletePlantilla({ variables: { id: plantilla.id } })
          .then(() => {
            handleSnackbar("Plantilla eliminada correctamente", "success");
            refetchPlantillas();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al eliminar la plantilla", "error");
          });
      },
    });
  };

  const renderMenuPlantillas = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openMenuPlantilla)}
      onClose={handleClosePlantillaMenu}
    >
      {plantillas?.plantillas?.length > 0 ? (
        plantillas?.plantillas?.map((plantilla) => (
          <Tooltip
            key={plantilla.id}
            title={`${administrarPlantillas ? "Eliminar" : ""} ${plantilla.nombre} (${
              plantilla?.plantillaDetalles?.length
            } productos)`}
            placement="left"
          >
            <MenuItem
              onClick={() => {
                if (administrarPlantillas) handleDeletePlantilla(plantilla);
                else if (productosRemito.length > 0) {
                  confirmar({
                    title: "Sobreescribir productos",
                    message: "Esta acción sobreescrbirá los productos actuales, ¿desea continuar?",
                    icon: "warning",
                    func: () => {
                      handleApplyPlantilla(plantilla);
                    },
                  });
                } else {
                  handleApplyPlantilla(plantilla);
                }
              }}
              disabled={loadingPlantillas || loadingDeletePlantilla}
            >
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: "100%",
                }}
              >
                <SoftBox display="flex" mr={2}>
                  <SoftBox mr={1}>
                    <SoftTypography variant="p" fontWeight="bold">
                      {plantilla.nombre.length > 20
                        ? `${plantilla.nombre.substring(0, 20)}...`
                        : plantilla.nombre}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography
                      variant="caption"
                      color="sistemasGris"
                    >{`${plantilla?.plantillaDetalles?.length} productos`}</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Icon
                    fontSize="small"
                    sx={{
                      verticalAlign: "middle",
                      color: administrarPlantillas
                        ? getUproColor("sistemasAmarillo")
                        : getUproColor("sistemasGris"),
                    }}
                  >
                    {administrarPlantillas ? "delete" : "playlist_add"}
                  </Icon>
                </SoftBox>
              </SoftBox>
            </MenuItem>
          </Tooltip>
        ))
      ) : (
        <SinDatos />
      )}
      {plantillas?.plantillas?.length > 0 && (
        <MenuItem
          onClick={() => setAdministrarPlantillas(!administrarPlantillas)}
          disabled={loadingPlantillas || loadingDeletePlantilla}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox display="flex" mr={2}>
              <SoftBox mr={1}>
                <SoftTypography variant="p" fontWeight="bold">
                  {administrarPlantillas ? "Cancelar" : "Administrar"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color: getUproColor("sistemasAmarillo"),
                }}
              >
                {administrarPlantillas ? "arrow_back" : "settings"}
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      )}
    </Menu>
  );

  const handleChanges = (event) => {
    setRemito({ ...remito, [event.target.name]: event.target.value });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              {
                id: { _like: `%${event.target.value}%` },
              },
              {
                nombre: { _like: `%${event.target.value}%` },
              },
              {
                categoria: { _like: `%${event.target.value}%` },
              },
            ],
            _stock: {
              cantidad: { _gt: 0 },
              idSucursal: remito?.sucursalOrigen,
            },
          },
          idSucursal: remito?.sucursalOrigen,
        },
      });
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleUpdateProductoRemito = (material) => {
    setProductosRemito(
      productosRemito.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const handleAddProduct = (product) => {
    setProductosRemito([...productosRemito, product]);
  };

  const handleRemoveProduct = (product) => {
    setProductosRemito(productosRemito.filter((item) => item.id !== product.id));
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const handleSave = () => {
    let detalles = productosRemito.map((item) => ({
      idProducto: item.id,
      idSucursal: remito.sucursalOrigen,
      cantidad: -item.cantidad,
    }));
    saveRemito({
      variables: {
        input: {
          tipo: "Remito Sedes - Egreso",
          movimientoDetalle: detalles,
          idSucursalOrigen: remito.sucursalOrigen,
          idSucursalDestino: remito.sucursalDestino,
          observaciones: remito.observaciones,
          estado: "Pendiente",
        },
      },
    })
      .then(() => {
        handleSnackbar("El remito se ha guardado correctamente", "success");
        handleClose();
        refetchProductos();
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al guardar el remito", "error");
      });
  };

  useEffect(() => {
    setRemito({
      sucursalOrigen: idSucursal,
    });
    setProductosRemito([]);
    setAdministrarPlantillas(false);
    if (open){
      getPlantillas({ variables: { page: 1, filter: { tipo: "Movimiento" } } });
      getSucursalesTransferencia({variables: {filter: { administraStock: true, principal: true }}});
    } 
    if (selectedTransferencia && open) {
      let productosCargados = selectedTransferencia.movimientoDetalles
        .filter((item) => item.cantidad > 0)
        .map((item) => ({
          ...item.producto,
          cantidad: item.cantidad,
          stock: { cantidad: item?.cantidad || 0 },
        }));

      setProductosRemito(productosCargados);
    }
  }, [open]);

  useEffect(() => {
    if (!selectedTransferencia) {
      setProductosRemito([]);
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _stock: {
              cantidad: { _gt: 0 },
              idSucursal: remito?.sucursalOrigen,
            },
          },
          idSucursal: remito?.sucursalOrigen,
        },
      });
    }
  }, [remito?.sucursalOrigen]);

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      setSucursales(sucursalesData.sucursales);
    }
  }, [sucursalesData, idSucursal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Realizar transferencia a deposito</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="sucursalOrigen">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Deposito de origen
                          {remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && (
                              <SoftTypography variant="caption" color="error">
                                &nbsp;(No se puede transferir a la misma sucursal)
                              </SoftTypography>
                            )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.sucursalOrigen?.nombre +
                            " - " +
                            selectedTransferencia?.sucursalOrigen?.sede?.nombre}
                        </SoftTypography>
                      ) : (
                        <Select
                          name="sucursalOrigen"
                          value={idSucursal || ""}
                          disabled={true}
                          onChange={handleChanges}
                          sx={
                            remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && {
                              borderColor: "red",
                              color: "red",
                            }
                          }
                        >
                          {sucursales.map((sucursal) => (
                            <MenuItem key={sucursal.id} value={sucursal.id}>
                              {sucursal.sede.nombre} - {sucursal.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="sucursalDestino">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Deposito de destino
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                          {remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && (
                              <SoftTypography variant="caption" color="error">
                                &nbsp;(No se puede transferir a la misma sucursal)
                              </SoftTypography>
                            )}
                        </SoftTypography>
                      </InputLabel>
                      {selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.sucursalDestino?.nombre +
                            " - " +
                            selectedTransferencia?.sucursalDestino?.sede?.nombre}
                        </SoftTypography>
                      ) : (
                        <Select
                          name="sucursalDestino"
                          value={remito?.sucursalDestino || ""}
                          onChange={handleChanges}
                          sx={
                            remito?.sucursalOrigen &&
                            remito?.sucursalDestino &&
                            remito?.sucursalOrigen === remito?.sucursalDestino && {
                              borderColor: "red",
                              color: "red",
                            }
                          }
                        >
                          {sucursales
                            .filter((s) => s.id !== idSucursal)
                            .map((sucursal) => (
                              <MenuItem key={sucursal.id} value={sucursal.id}>
                                {sucursal.sede.nombre} - {sucursal.nombre}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Observaciones
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                      {selectedTransferencia ? (
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedTransferencia?.observaciones}
                        </SoftTypography>
                      ) : (
                        <SoftInput
                          label="observaciones"
                          placeholder="Inserte un comentario"
                          type="text"
                          name="observaciones"
                          value={remito?.observaciones || ""}
                          onChange={handleChanges}
                          multiline
                          rows={4}
                        />
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                  {!selectedTransferencia && (
                    <SoftBox display="flex" justifyContent="end" alignItems="flex-end">
                      <SoftBox mr={2}>
                        <Tooltip title="Plantillas" placement="top">
                          <SoftButton circular color="sistemasGris" onClick={handleOpenPlantillaMenu}>
                            {(loadingPlantillas || loadingDeletePlantilla) && (
                              <CircularProgress size={15} color="inherit" />
                            )}
                            {!loadingPlantillas && !loadingDeletePlantilla && <Icon>sort</Icon>}
                            &nbsp;Plantillas
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      {renderMenuPlantillas}
                      <SoftBox mr={2}>
                        <Tooltip title="Guardar como plantilla" placement="top">
                          <SoftButton
                            circular
                            iconOnly
                            color="primary"
                            disabled={productosRemito.length === 0}
                            onClick={() => setOpenModalAddPlantiilla(true)}
                          >
                            <Icon>save</Icon>
                          </SoftButton>
                        </Tooltip>
                      </SoftBox>
                      <SoftBox
                        sx={{
                          width: "400px",
                        }}
                      >
                        <SoftInput
                          placeholder="Busca aquí materiales..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                          onKeyPress={() => {
                            setOpenSelectionMenu(true);
                          }}
                          onClick={() => {
                            getProductos({
                              variables: {
                                page: 1,
                                filter: {
                                  disabled: false,
                                  _stock: {
                                    cantidad: { _gt: 0 },
                                    idSucursal: remito?.sucursalOrigen,
                                  },
                                },
                                idSucursal: remito?.sucursalOrigen,
                              },
                            });
                            setOpenSelectionMenu(true);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.relatedTarget?.tagName !== "LI") {
                              setOpenSelectionMenu(false);
                            } else {
                              e.target.focus();
                            }
                          }}
                        />
                        {openSelectionMenu && (
                          <SoftBox sx={{ position: "fixed", zIndex: 99 }}>
                            <Card>
                              <SoftBox
                                p={
                                  !loading &&
                                  productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0
                                    ? 3
                                    : 0
                                }
                                sx={{
                                  width: "400px",
                                  maxHeight: "30vh",
                                  overflowY: "auto",
                                }}
                              >
                                {openSelectionMenu && loading ? (
                                  <Loading />
                                ) : productos?.productos?.length > 0 &&
                                  remito?.sucursalOrigen &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosRemito.find((item) => item.id === producto.id)
                                  ).length > 0 &&
                                  openSelectionMenu ? (
                                  productos?.productos
                                    .filter(
                                      (producto) =>
                                        !productosRemito.find((item) => item.id === producto.id)
                                    )
                                    .map((producto) => (
                                      <MenuItem
                                        key={producto.id}
                                        onClick={() => {
                                          handleAddProduct(producto);
                                          setOpenSelectionMenu(false);
                                        }}
                                      >
                                        <SoftBox mr={2}>
                                          <Icon
                                            sx={{
                                              color:
                                                producto.tipo === "Material"
                                                  ? getUproColor("sistemasAmarillo")
                                                  : producto.tipo === "Consumible"
                                                  ? getUproColor("sistemasGris")
                                                  : getUproColor("sistemasAmarillo"),
                                            }}
                                            fontSize="small"
                                          >
                                            {producto.tipo === "Material"
                                              ? "category"
                                              : producto.tipo === "Consumible"
                                              ? "recycling"
                                              : "layers"}
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                          &nbsp;{`${producto.id} - ${producto.nombre}`}
                                        </SoftBox>
                                      </MenuItem>
                                    ))
                                ) : (
                                  <SinResultados />
                                )}
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </SoftBox>
                  )}
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          {selectedTransferencia ? (
                            <TableRow>{renderColumnsSelect}</TableRow>
                          ) : (
                            <TableRow>{renderColumnsBase}</TableRow>
                          )}
                        </SoftBox>
                        <TableBody>
                          {productosRemito.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoRemito}
                                handleRemoveMaterial={() => handleRemoveProduct(material)}
                                disabled={selectedTransferencia ? true : false}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {!selectedTransferencia && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                    <SoftBox mr={2}>
                      <SoftButton color="primary" circular onClick={handleClose}>
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox>
                      <SoftButton
                        color="sistemasGris"
                        circular
                        disabled={
                          loadingSave ||
                          productosRemito.length === 0 ||
                          productosRemito.some(
                            (item) =>
                              !item.cantidad ||
                              item.cantidad == 0 ||
                              item.cantidad > item.stock?.cantidad ||
                              item?.cantidad?.toString().includes(".")
                          ) ||
                          !remito?.sucursalOrigen ||
                          !remito?.sucursalDestino ||
                          remito?.sucursalOrigen === remito?.sucursalDestino ||
                          !remito?.observaciones
                        }
                        onClick={handleSave}
                      >
                        {loadingSave ? (
                          <CircularProgress size={15} color="white" />
                        ) : (
                          <Icon sx={{ fontWeight: "light" }}>save</Icon>
                        )}
                        &nbsp;Guardar
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </SoftBox>
            <ModalAddPlantilla
              open={openModalAddPlantiilla}
              handleClose={() => setOpenModalAddPlantiilla(false)}
              productos={productosRemito}
              refetch={refetchPlantillas}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevaTransferencia.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedTransferencia: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
