/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import dayjs from "dayjs";

const getRows = (data, handleSelect) => {
  const rows = [];

  const validateRecepcion = (enviado, recibido) => {
    let validacion = false;
    enviado.forEach((enviadoItem) => {
      let recibidoItem = recibido.find(
        (recibidoItem) => recibidoItem.producto.id === enviadoItem.producto.id
      );
      if (recibidoItem && enviadoItem.cantidad === recibidoItem.cantidad) validacion = true;
    });
    return validacion;
  };

  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                title={
                  item?.tipo === "Prestamo" && item?.recepcion
                    ? `Prestamo - Finalizado (Devolución N°${item?.recepcion?.id})`
                    : item?.tipo === "Prestamo" && !item?.recepcion
                    ? "Prestamo - Pendiente"
                    : item.tipo
                }
                placement="top"
              >
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipo === "Transferencia"
                        ? getUproColor("sistemasGris")
                        : item?.tipo === "Prestamo" && item?.recepcion
                        ? "success.main"
                        : item?.tipo === "Prestamo" && !item?.recepcion
                        ? getUproColor("sistemasAmarillo")
                        : getUproColor("sistemasAmarillo"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipo === "Transferencia"
                    ? "import_export"
                    : item?.tipo === "Prestamo" && item?.recepcion
                    ? "task_alt"
                    : item?.tipo === "Prestamo" && !item?.recepcion
                    ? "autorenew"
                    : "replay"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography verticalAlign="middle" variant="caption" color="dark" fontWeight="bold">
              {"N° " + item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalOrigen: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalOrigen?.nombre + " - " + item?.sucursalOrigen?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        sucursalDestino: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalDestino?.nombre + " - " + item?.sucursalDestino?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.movimientoDetalles.filter((detalle) => detalle.cantidad > 0).length +
                " productos"}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.nombre
                ? `${makeNiceText(item?.user?.nombre, "nombre")} ${makeNiceText(
                    item?.user?.apellido,
                    "nombre"
                  )}`
                : item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        responsable: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.responsable?.nombre
                ? `${makeNiceText(item?.responsable?.nombre, "nombre")} ${makeNiceText(
                    item?.responsable?.apellido,
                    "nombre"
                  )}`
                : item?.responsable?.username}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {dayjs(item?.createdAt).format("DD/MM/YYYY")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
            display="flex"
            justifyContent="end"
          >
            {item?.recepcion &&
              !validateRecepcion(
                item?.recepcion?.movimientoDetalles?.filter((detalle) => detalle.cantidad > 0),
                item?.movimientoDetalles?.filter((detalle) => detalle.cantidad > 0)
              ) && (
                <SoftBox>
                  <Tooltip title="Se devolvieron menos productos de los prestados" placement="top">
                    <Icon
                      color="error"
                      sx={{ cursor: "pointer", mx: 0.5 }}
                      fontSize="small"
                      onClick={() => handleSelect(item)}
                    >
                      warning
                    </Icon>
                  </Tooltip>
                </SoftBox>
              )}
            {item.tipo === "Transferencia" && item?.statusConformidad && (
              <SoftBox>
                <Tooltip title={item?.statusConformidad} placement="top">
                  <Icon
                  color={item?.statusConformidad === "Conforme" ? "success" : "error"}
                    sx={{ cursor: "pointer", mx: 0.5 }}
                    fontSize="small"
                    onClick={() => handleSelect(item)}
                  >
                    {item?.statusConformidad === "Conforme" ? "verified" : "thumb_down"}
                  </Icon>
                </Tooltip>
              </SoftBox>
            )}
            <SoftBox>
              {item.tipo === "Transferencia" && !item.userConformidad ? (
                <Tooltip title="Requiere confirmacion de la sucursal" placement="top">
                  <Icon
                    sx={{ color: getUproColor("sistemasAmarillo"), cursor: "pointer", mx: 0.5 }}
                    fontSize="small"
                    onClick={() => handleSelect(item)}
                  >
                    report
                  </Icon>
                </Tooltip>
              ) : (
                <Tooltip title="Ver detalles" placement="top">
                  <Icon
                    sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                    fontSize="small"
                    onClick={() => handleSelect(item)}
                  >
                    arrow_forward
                  </Icon>
                </Tooltip>
              )}
            </SoftBox>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  {
    name: "id",
    desc: "ID",
    align: "left",
  },
  {
    name: "sucursalOrigen",
    desc: "Sucursal de Origen",
    align: "left",
    orderField: "idSucursalOrigen",
  },
  {
    name: "sucursalDestino",
    desc: "Sucursal de Destino",
    align: "left",
    orderField: "idSucursalDestino",
  },
  { name: "cantidad", desc: "Cantidad de productos", align: "center", noOrder: true },
  { name: "user", desc: "Creado por", align: "left", orderField: "idUser" },
  { name: "responsable", desc: "Responsable", align: "left", orderField: "idResponsable" },
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt" },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
