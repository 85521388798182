// React
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Collapse, Icon, Menu, MenuItem, Select, Skeleton, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import ModalConfirm from "components/Modals/ConfirmMsg";
import ModalOptions from "components/Modals/Options";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import ModalAjuste from "./components/ModalAjustes";
import ModalAjusteHistory from "./components/ModalAjustes/ModalHistorial";
import ModalIngresar from "./components/ModalIngresar";
import ModalNuevoRemito from "./components/ModalIngresar/ModalNuevoRemito";
import ModalProducto from "./components/ModalProducto";
import ModalProductoMovimientos from "./components/ModalProductoMovimientos";
import ModalProductoStock from "./components/ModalProductoStock";
import ModalSolicitudes from "./components/ModalSolicitudes";
import ModalTransferencias from "./components/ModalTransferencia";
import ModalNuevaTransferencia from "./components/ModalTransferencia/ModalNuevaTransferencia";

//REMITO SEDE
import ModalSedeHistorial from "./components/TransferenciaSede";
import ModalSedeRecepcionar from "./components/TransferenciaSede/Recepcion";
import ModalSedeTransferir from "./components/TransferenciaSede/Transferir";

import { MenuContext } from "context/menuContext";
import { UserContext } from "context/user";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import ModalDevoluciones from "./components/ModalDevoluciones";
import ModalProveedores from "./components/ModalProveedores";
import ModalStockRapido from "./components/ModalStockRapido";
import ModalVerificacionPedidos from "./components/ModalVerificacionPedidos";
import productoData from "./data/productoData";
// import ModalDepositos from "./components/ModalDepositos";
import ModalSucursales from "./components/ModalSucursales";

function Productos() {
  const { columns, getRows } = productoData;
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openFilters, setOpenFilters] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "DESC",
  });
  const [filter, setFilter] = useState({
    disabled: false,
  });
  const [selectedSucursal, setSelectedSucursal] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalProducto, setOpenModalProducto] = useState(false);
  const [openModalProductoMovimientos, setOpenModalProductoMovimientos] = useState(false);
  const [openModalProductoStock, setOpenModalProductoStock] = useState(false);
  const [openModalIngresar, setOpenModalIngresar] = useState(false);
  const [openModalAjuste, setOpenModalAjuste] = useState(false);
  const [openModalNuevoRemito, setOpenModalNuevoRemito] = useState(false);
  const [selectedAjuste, setSelectedAjuste] = useState();
  const [openAjusteMenu, setOpenAjusteMenu] = useState(false);
  const [openSucursalMenu, setOpenSucursalMenu] = useState(false);
  const [openIngresoMenu, setOpenIngresoMenu] = useState(false);
  const [openMovimientoSedesMenu, setOpenMovimientoSedesMenu] = useState(false);
  const [openTransferenciaMenu, setOpenTransferenciaMenu] = useState(false);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);

  // REMITO SEDE
  const [openSedeTransferir, setOpenSedeTransferir] = useState(false);
  const [openSedeRecepcionar, setOpenSedeRecepcionar] = useState(false);
  const [openSedeHistorial, setOpenSedeHistorial] = useState(false);

  const [openModalTransferenciaHistory, setOpenModalTransferenciaHistory] = useState(false);
  const [openModalSolicitudes, setOpenModalSolicitudes] = useState(false);
  const [openModalAjusteHistory, setOpenModalAjusteHistory] = useState(false);
  const [openModalProveedores, setOpenModalProveedores] = useState(false);
  const [openModalDevoluciones, setOpenModalDevoluciones] = useState(false);
  const [selectedTipoTransferencia, setSelectedTipoTransferencia] = useState("Transferencia");
  const [openModalOptions, setOpenModalOptions] = useState(false);
  const { verificarAcceso, user } = useContext(UserContext);
  const [openModalStockRapido, setOpenModalStockRapido] = useState(false);
  const { menu } = useContext(MenuContext);
  const [openModalPedidosCompra, setOpenModalPedidosCompra] = useState(false);
  const [verificacionesPendientes, setVerificacionesPendientes] = useState(0);
  const [openSucursales,setOpenSucursales] = useState(false);

  const { data: dataSucurales, loading: loadingSucursales, refetch: refetchSucursales } = useQuery(
    gql`
      query getSucursales($filter: JSON) {
        sucursales(filter: $filter) {
          id
          nombre
          sede {
            id
            nombre
          }
          administraStock
          principal
        }
      }
    `,
    {
      variables: {
        filter: {
          administraStock: true,
          id: user?.sucursales ? user?.sucursales.map((sucursal) => sucursal.id) : undefined,
        },
      },
      onCompleted: (data) => {
        if (data?.sucursales?.length > 0) {
          setSelectedSucursal(data?.sucursales[0].id);
        }
      },
    }
  );

  const { data: dataCategorias } = useQuery(gql`
    query CategoriasProducto {
      categoriasProducto {
        tipoProducto
        nombre
      }
    }
  `);

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getProductos(
        $search: String
        $page: Int
        $order: Order
        $filter: JSON
        $idSucursal: ID
      ) {
        paginationInfo {
          pages
          total
        }
        productos(
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
          filter: $filter
        ) {
          id
          codigo
          tipo
          nombre
          disabled
          categoria
          motivoBaja
          stock(idSucursal: $idSucursal) {
            cantidad
            enPrestamo
            pasillo
            fila
            columna
            stockMinimo
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
        filter: filter,
        idSucursal: selectedSucursal || null,
      },
      fetchPolicy: "no-cache",
    }
  );

  const [getProductosData] = useLazyQuery(
    gql`
      query getProductos($order: Order, $filter: JSON, $idSucursal: ID) {
        productos(order: $order, filter: $filter) {
          __typename @skip(if: true)
          id
          tipo
          nombre
          disabled
          categoria
          stock(idSucursal: $idSucursal) {
            cantidad
            enPrestamo
            pasillo
            fila
            columna
            stockMinimo
          }
        }
      }
    `
  );

  const [getProductosSede] = useLazyQuery(
    gql`
      query getProductos($order: Order, $filter: JSON, $idSede: ID) {
        productos(order: $order, filter: $filter) {
          __typename @skip(if: true)
          id
          tipo
          nombre
          disabled
          categoria
          stockSede(idSede: $idSede) {
            cantidad
            enPrestamo
            stockMinimo
            sucursal {
              nombre
              administraStock
              sede {
                nombre
              }
            }
          }
        }
      }
    `
  );

  const [getTotalSede] = useLazyQuery(
    gql`
      query getProductos($order: Order, $filter: JSON) {
        productos(order: $order, filter: $filter) {
          __typename @skip(if: true)
          id
          tipo
          nombre
          disabled
          categoria
          stockPorSede {
            sede {
              id
              nombre
            }
            cantidad
          }
        }
      }
    `
  );

  const [mutateProducto] = useMutation(gql`
    mutation saveProducto($input: ProductoInput!) {
      saveProducto(input: $input) {
        id
      }
    }
  `);

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
      setPage(1);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    if (value == -1) {
      setFilter((prev) => {
        const updatedFilter = { ...prev };
        delete updatedFilter[name];
        return updatedFilter;
      });
    } else {
      setFilter((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedItem(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    /* setSelectedItem(); */
  };

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenAjusteMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenAjusteMenu(true);
  };

  const handleCloseAjusteMenu = () => {
    setAnchorEl(null);
    setOpenAjusteMenu(false);
  };

  const handleOpenIngresoMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenIngresoMenu(true);
  };

  const handleCloseIngresoMenu = () => {
    setAnchorEl(null);
    setOpenIngresoMenu(false);
  };

  const handleOpenMovimientoSedesMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMovimientoSedesMenu(true);
  };

  const handleCloseMovimientoSedesMenu = () => {
    setAnchorEl(null);
    setOpenMovimientoSedesMenu(false);
  };

  const handleOpenTransferenciaMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenTransferenciaMenu(true);
  };

  const handleCloseTransferenciaMenu = () => {
    setAnchorEl(null);
    setOpenTransferenciaMenu(false);
  };

  const handleOpenSucursalMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenSucursalMenu(true);
  };

  const handleCloseSucursalMenu = () => {
    setAnchorEl(null);
    setOpenSucursalMenu(false);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const toggleFilters = () => {
    if (openFilters) {
      setOpenFilters(false);
    } else {
      setOpenFilters(true);
    }
  };

  const handleDisabled = (motivo) => {
    mutateProducto({
      variables: {
        input: {
          id: selectedItem.id,
          disabled: !selectedItem.disabled,
          motivoBaja: motivo || null,
        },
      },
    })
      .then(() => {
        handleSnackbar("Producto actualizado correctamente", "success");
        refetch();
      })
      .catch(() => {
        handleSnackbar("Ocurrió un error al actualizar el producto", "error");
      });
    handleCloseMenu();
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(data?.productos, handleOpenMenu));
      setPagesInfo(data?.paginationInfo);
    }
  }, [data]);

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
      {verificarAcceso(5) && (
        <MenuItem onClick={() => setOpenModalProducto(true)} disabled={false}>
          <Icon
            sx={{
              color: getUproColor("sistemasAmarillo"),
            }}
            fontSize="small"
          >
            edit
          </Icon>
          &nbsp;Editar
        </MenuItem>
      )}
      {verificarAcceso(6) && (
        <MenuItem onClick={() => setOpenModalProductoMovimientos(true)} disabled={false}>
          <Icon
            sx={{
              color: getUproColor("sistemasNegro"),
            }}
            fontSize="small"
          >
            history
          </Icon>
          &nbsp;Últimos movimientos
        </MenuItem>
      )}
      {verificarAcceso(7) && (
        <MenuItem onClick={() => setOpenModalProductoStock(true)} disabled={false}>
          <Icon
            sx={{
              color: getUproColor("sistemasGris"),
            }}
            fontSize="small"
          >
            inventory
          </Icon>
          &nbsp;Stock en sucursales
        </MenuItem>
      )}
      {verificarAcceso(8) && (
        <MenuItem onClick={handleOpenModalConfirm} disabled={false}>
          <Icon color={selectedItem?.disabled ? "success" : "error"} fontSize="small">
            {selectedItem?.disabled ? "arrow_upward_icon" : "arrow_downward_icon"}
          </Icon>
          &nbsp;{selectedItem?.disabled ? "Habilitar" : "Deshabilitar"}
        </MenuItem>
      )}
      {!verificarAcceso([5, 6, 7, 8], "or") && (
        <MenuItem disabled={true}>
          <Icon fontSize="small">block</Icon>
          &nbsp;No tiene permisos
        </MenuItem>
      )}
    </Menu>
  );

  const renderAjusteMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openAjusteMenu)}
      onClose={handleCloseAjusteMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenModalAjuste(true);
          setSelectedAjuste("Ajuste");
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Ajuste</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              drive_file_rename_outline
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalAjuste(true);
          setSelectedAjuste("Merma");
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Merma</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              arrow_downward
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalAjuste(true);
          setSelectedAjuste("Devolucion Proveedor");
          handleCloseAjusteMenu();
        }}
        sx={{
          minWidth: { xs: "75vw", sm: "auto" },
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Devolucion a proveedor</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              undo
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalAjusteHistory(true);
          handleCloseAjusteMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Ver historial</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasNegro"),
              }}
            >
              history
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const renderSucursalMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openSucursalMenu)}
      onClose={handleCloseSucursalMenu}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      {dataSucurales?.sucursales?.map((sucursal, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            setSelectedSucursal(sucursal.id);
            handleCloseSucursalMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox mr={2}>
              <SoftTypography variant="p" fontWeight="medium">
                {sucursal.sede.nombre}
              </SoftTypography>
              <SoftTypography variant="p">{` - ${sucursal.nombre}`}</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    sucursal.id === selectedSucursal
                      ? getUproColor("sistemasAmarillo")
                      : getUproColor("sistemasGris"),
                }}
              >
                location_on
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
      ))}
    </Menu>
  );

  const renderIngresoMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openIngresoMenu)}
      onClose={handleCloseIngresoMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenModalNuevoRemito(true);
          handleCloseIngresoMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Nuevo remito a proveedor</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              add
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalIngresar(true);
          handleCloseIngresoMenu();
        }}
        sx={{
          minWidth: { xs: "75vw", sm: "auto" },
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Ver remitos a proveedores</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasNegro"),
              }}
            >
              history
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  // REMITO SEDE
  const renderMovimientoSedesMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openMovimientoSedesMenu)}
      onClose={handleCloseMovimientoSedesMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setOpenSedeTransferir(true);
          handleCloseMovimientoSedesMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Transferir</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              swipe_up_alt
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenSedeRecepcionar(true);
          handleCloseMovimientoSedesMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Recepcionar</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              swipe_down_alt
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenSedeHistorial(true);
          handleCloseMovimientoSedesMenu();
        }}
        sx={{
          minWidth: { xs: "75vw", sm: "auto" },
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Ver movimientos</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasNegro"),
              }}
            >
              history
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  const renderTransferenciaMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(openTransferenciaMenu)}
      onClose={handleCloseTransferenciaMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
    >
      <MenuItem
        onClick={() => {
          setSelectedTipoTransferencia("Transferencia");
          setOpenModalTransferencia(true);
          handleCloseTransferenciaMenu();
        }}
        sx={{
          minWidth: { xs: "75vw", sm: "auto" },
          maxWidth: { xs: "75vw", sm: "auto" },
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox
            mr={{
              xs: 0,
              sm: 2,
            }}
          >
            <SoftTypography variant="p">Nueva transferencia entre sucursales</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasGris"),
              }}
            >
              import_export
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setSelectedTipoTransferencia("Prestamo");
          setOpenModalTransferencia(true);
          handleCloseTransferenciaMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox mr={2}>
            <SoftTypography variant="p">Nuevo prestamo</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              autorenew
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalSolicitudes(true);
          handleCloseTransferenciaMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Solicitudes</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
              }}
              color="success"
            >
              rule_folder
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalDevoluciones(true);
          handleCloseTransferenciaMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Recepción prestamo</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasAmarillo"),
              }}
            >
              replay
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenModalTransferenciaHistory(true);
          handleCloseTransferenciaMenu();
        }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
          }}
        >
          <SoftBox>
            <SoftTypography variant="p">Ver historial</SoftTypography>
          </SoftBox>
          <SoftBox>
            <Icon
              fontSize="small"
              sx={{
                verticalAlign: "middle",
                color: getUproColor("sistemasNegro"),
              }}
            >
              history
            </Icon>
          </SoftBox>
        </SoftBox>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display={{ xs: "flex-row", sm: "flex" }}
              justifyContent="space-between"
              alignItems="center"
              p={3}
              pb={{
                xs: 2,
                sm: 3,
              }}
            >
              <SoftBox pb={{ xs: 3, sm: 0 }} display="flex" justifyContent="space-between">
                <SoftBox
                  display="flex"
                  alignItems="center"
                  onClick={handleOpenSucursalMenu}
                  mr={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <Icon color="primary">location_on</Icon>
                  <SoftTypography
                    variant="h6"
                    sx={{
                      cursor: "pointer",
                    }}
                    ml={1}
                  >
                    {loadingSucursales ? (
                      <Skeleton width={100} />
                    ) : dataSucurales?.sucursales?.find(
                        (sucursal) => sucursal.id === selectedSucursal
                      ) ? (
                      `${
                        dataSucurales?.sucursales?.find(
                          (sucursal) => sucursal.id === selectedSucursal
                        )?.sede.nombre
                      } - ${
                        dataSucurales?.sucursales?.find(
                          (sucursal) => sucursal.id === selectedSucursal
                        )?.nombre
                      }`.length > 30 ? (
                        `${
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.sede.nombre
                        } - ${
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.nombre
                        }`.substring(0, 25) + "..."
                      ) : (
                        `${
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.sede.nombre
                        } - ${
                          dataSucurales?.sucursales?.find(
                            (sucursal) => sucursal.id === selectedSucursal
                          )?.nombre
                        }`
                      )
                    ) : (
                      "No está asignado a ningún pañol"
                    )}
                  </SoftTypography>
                  <Icon color="dark">{openSucursalMenu ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
                </SoftBox>
                <SoftBox
                  display={{
                    xs: "flex",
                    sm: "none",
                  }}
                  justifyContent="flex-end"
                >
                  <SoftBox mr={2}>
                    <Tooltip title={openFilters ? "Cerrar filtros" : "Ver filtros"} placement="top">
                      <SoftButton color="sistemasGris" onClick={toggleFilters} circular iconOnly>
                        <Icon sx={{ fontWeight: "bold" }}>
                          {openFilters ? "filter_alt_off" : "filter_alt"}
                        </Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                  <SoftBox>
                    <Tooltip title="Opciones" placement="top">
                      <SoftButton
                        color="primary"
                        onClick={() => setOpenModalOptions(true)}
                        circular
                        iconOnly
                      >
                        <Icon sx={{ fontWeight: "light" }}>more_vert</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display={{ xs: "flex-row", sm: "flex" }}
                justifyContent="end"
                alignItems="flex-end"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                >
                  <SoftInput
                    placeholder="Escribe aquí..."
                    icon={{ component: "search", direction: "left" }}
                    onChange={handleSearch}
                  />
                </SoftBox>
                <SoftBox mr={2} display={{ xs: "none", sm: "block" }}>
                  <Tooltip title="Stock inicial rápido" placement="top">
                    <SoftButton
                      color="sistemasGris"
                      circular
                      iconOnly
                      onClick={() => setOpenModalStockRapido(true)}
                    >
                      <Icon sx={{ fontWeight: "light" }}>electric_bolt</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                <SoftBox mt={2} display={{ xs: "block", sm: "none" }}>
                  <Tooltip title="Stock inicial rápido" placement="top">
                    <SoftButton
                      color="sistemasGris"
                      circular
                      fullWidth
                      onClick={() => setOpenModalStockRapido(true)}
                    >
                      <Icon sx={{ fontWeight: "light" }}>electric_bolt</Icon>
                      &nbsp;Stock rápido
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                {verificarAcceso(1) && (
                  <SoftBox
                    mr={2}
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Ingresar productos" placement="top">
                      <SoftButton
                        color="sistemasAmarillo"
                        onClick={handleOpenIngresoMenu}
                        circular
                        iconOnly
                        disabled={!selectedSucursal}
                      >
                        <Icon sx={{ fontWeight: "light" }}>local_shipping</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                {verificarAcceso(10) &&
                  selectedSucursal &&
                  dataSucurales.sucursales?.find((sucursal) => sucursal.id === selectedSucursal)
                    ?.principal && (
                    <SoftBox
                      mr={2}
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Transferencias entre depositos" placement="top">
                        <SoftButton
                          color="sistemasAmarillo"
                          onClick={handleOpenMovimientoSedesMenu}
                          circular
                          iconOnly
                          disabled={!selectedSucursal}
                        >
                          <Icon sx={{ fontWeight: "light" }}>call_merge</Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  )}
                {verificarAcceso(3) && (
                  <SoftBox
                    mr={2}
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Ajustar stock" placement="top">
                      <SoftButton
                        color="sistemasAmarillo"
                        onClick={handleOpenAjusteMenu}
                        circular
                        iconOnly
                        disabled={!selectedSucursal}
                      >
                        <Icon sx={{ fontWeight: "light" }}>drive_file_rename_outline</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                {verificarAcceso(3) && (
                  <SoftBox
                    mr={2}
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Administrar depositos" placement="top">
                      <SoftButton
                        color="sistemasAmarillo"
                        onClick={() => setOpenSucursales(true)}
                        circular
                        iconOnly
                      >
                        <Icon sx={{ fontWeight: "light" }}>home_work</Icon>
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                {verificarAcceso(4) && (
                  <SoftBox
                    mr={2}
                    display={{
                      xs: "none",
                      sm: "block",
                    }}
                  >
                    <Tooltip title="Agregar nuevo producto" placement="top">
                      <SoftButton
                        color="primary"
                        onClick={() => {
                          setOpenModalProducto(true);
                          setSelectedItem({ tipo: "Material" });
                        }}
                        disabled={!selectedSucursal}
                        circular
                      >
                        <Icon>add</Icon>
                        &nbsp;Agregar
                      </SoftButton>
                    </Tooltip>
                  </SoftBox>
                )}
                <SoftBox
                  display={{
                    xs: "none",
                    sm: "block",
                  }}
                >
                  <Tooltip title={openFilters ? "Cerrar filtros" : "Ver filtros"} placement="top">
                    <SoftButton color="sistemasGris" onClick={toggleFilters} circular iconOnly>
                      <Icon sx={{ fontWeight: "bold" }}>
                        {openFilters ? "filter_alt_off" : "filter_alt"}
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
              <SoftBox
                display="flex"
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
                justifyContent="end"
                alignItems="center"
                px={3}
                pb={3}
                gap={2}
              >
                <Select
                  name="tipo"
                  value={filter?.["tipo"] || -1}
                  sx={{
                    paddingRight: "35px !important",
                  }}
                  onChange={handleFilter}
                >
                  <MenuItem value={-1}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Todos</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasNegro"),
                          }}
                        >
                          dataset
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value="Material">
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Materiales</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasAmarillo"),
                          }}
                        >
                          category
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem
                    value="Consumible"
                    sx={{
                      minWidth: { xs: "70vw", sm: "auto" },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Consumibles</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasGris"),
                          }}
                        >
                          recycling
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                  <MenuItem value="Recurso">
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <SoftBox>
                        <SoftTypography variant="p">Recursos</SoftTypography>
                      </SoftBox>
                      <SoftBox>
                        <Icon
                          fontSize="small"
                          sx={{
                            verticalAlign: "middle",
                            color: getUproColor("sistemasAmarillo"),
                          }}
                        >
                          layers
                        </Icon>
                      </SoftBox>
                    </SoftBox>
                  </MenuItem>
                </Select>
                <Select
                  name="categoria"
                  value={filter?.["categoria"] != undefined ? filter?.["categoria"] : -1}
                  onChange={handleFilter}
                >
                  <MenuItem value={-1}>No filtrar por categoria</MenuItem>
                  {dataCategorias?.categoriasProducto?.map((categoria, index) => (
                    <MenuItem key={index} value={categoria.nombre}>
                      {categoria.nombre}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  name="_stock"
                  defaultValue={0}
                  onChange={(e) => {
                    let value = e.target.value;
                    handleFilter({
                      target: {
                        name: "_stock",
                        value:
                          value == 1
                            ? { cantidad: { _gte: 1 }, idSucursal: selectedSucursal }
                            : value == 2
                            ? {
                                cantidad: { _lte: { _col: "stockMinimo" } },
                                idSucursal: selectedSucursal,
                              }
                            : -1,
                      },
                    });
                  }}
                >
                  <MenuItem value={0}>No filtrar por stock</MenuItem>
                  <MenuItem value={1}>Con stock</MenuItem>
                  <MenuItem value={2}>Stock crítico</MenuItem>
                </Select>
                <Select
                  name="disabled"
                  value={filter?.["disabled"] != undefined ? filter?.["disabled"] : -1}
                  onChange={handleFilter}
                >
                  <MenuItem value={-1}>Todos los estados</MenuItem>
                  <MenuItem value={false}>Habilitados</MenuItem>
                  <MenuItem value={true}>Deshabilitados</MenuItem>
                </Select>
                <SoftBox>
                  <Tooltip title="Descargar listado de productos en excel" placement="top">
                    <SoftButton
                      color="success"
                      circular
                      sx={{
                        textWrap: "nowrap",
                      }}
                      onClick={(event) => {
                        menu({
                          open: event.currentTarget,
                          align: "right",
                          options: [
                            {
                              name: "Esta sucursal",
                              icon: { icon: "place", color: "primary" },
                              onClick: () => {
                                getProductosData({
                                  variables: {
                                    order: { field: orderBy.campo, order: orderBy.orden },
                                    filter: filter,
                                    idSucursal: selectedSucursal,
                                  },
                                }).then((data) => {
                                  let productosExportar = data?.data?.productos;
                                  let sucursal = dataSucurales?.sucursales?.find(
                                    (sucursal) => sucursal.id === selectedSucursal
                                  );

                                  productosExportar = productosExportar.map((producto) => {
                                    return {
                                      ID: producto.id,
                                      Tipo: producto.tipo,
                                      Nombre: producto.nombre,
                                      Categoria: producto.categoria,
                                      Stock: producto.stock?.cantidad || 0,
                                      "Stock Minimo": producto.stock?.stockMinimo || 0,
                                      "En prestamo": producto.stock?.enPrestamo || 0,
                                      Deshabilitado: producto.disabled ? "Si" : "No",
                                    };
                                  });

                                  const cabecera = [
                                    [
                                      `Productos ${sucursal?.sede?.nombre} - ${
                                        sucursal?.nombre
                                      } - ${dayjs().format("DD-MM-YYYY")}`,
                                    ],
                                  ];

                                  const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                  XLSX.utils.sheet_add_json(sheet, productosExportar, {
                                    skipHeader: false,
                                    origin: -1,
                                  });

                                  sheet["!merges"] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];

                                  const workbook = XLSX.utils.book_new();
                                  XLSX.utils.book_append_sheet(
                                    workbook,
                                    sheet,
                                    `Stock ${dayjs().format("DD-MM-YYYY")}`
                                  );

                                  XLSX.writeFile(
                                    workbook,
                                    `Productos ${sucursal?.sede?.nombre} - ${
                                      sucursal?.nombre
                                    } - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                                  );
                                });
                              },
                            },
                            {
                              name: "Todo este deposito",
                              icon: {
                                icon: "location_city",
                                color: getUproColor("sistemasAmarillo"),
                              },
                              onClick: () => {
                                getProductosSede({
                                  variables: {
                                    order: { field: orderBy.campo, order: orderBy.orden },
                                    filter: filter,
                                    idSede: dataSucurales?.sucursales?.find(
                                      (sucursal) => sucursal.id === selectedSucursal
                                    )?.sede.id,
                                  },
                                }).then((data) => {
                                  let productosExportar = data?.data?.productos;
                                  let sucursal = dataSucurales?.sucursales?.find(
                                    (sucursal) => sucursal.id === selectedSucursal
                                  );

                                  // Obtener todas las columnas de stock posibles
                                  let columnasStock = new Set();
                                  productosExportar.forEach((producto) => {
                                    producto.stockSede.forEach((sucursal) => {
                                      columnasStock.add(`Stock ${sucursal.sucursal.nombre}`);
                                    });
                                  });

                                  // Convertir el conjunto a un array para ordenarlas
                                  columnasStock = Array.from(columnasStock).sort();

                                  // Procesar los productos para asegurar que cada uno tenga todas las columnas de stock
                                  productosExportar = productosExportar.map((producto) => {
                                    let thisProducto = {
                                      ID: producto.id,
                                      Tipo: producto.tipo,
                                      Nombre: producto.nombre,
                                      Categoria: producto.categoria,
                                      Deshabilitado: producto.disabled ? "Si" : "No",
                                    };

                                    // Inicializar todas las columnas de stock en 0
                                    columnasStock.forEach((columna) => {
                                      thisProducto[columna] = 0;
                                    });

                                    // Rellenar las columnas de stock con los valores correspondientes
                                    producto.stockSede.forEach((sucursal) => {
                                      thisProducto[`Stock ${sucursal.sucursal.nombre}`] =
                                        sucursal.cantidad || 0;
                                    });

                                    return thisProducto;
                                  });

                                  const cabecera = [
                                    [
                                      `Productos en sede ${
                                        sucursal?.sede?.nombre
                                      } - ${dayjs().format("DD-MM-YYYY")}`,
                                    ],
                                  ];

                                  const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                  XLSX.utils.sheet_add_json(sheet, productosExportar, {
                                    skipHeader: false,
                                    origin: -1,
                                  });

                                  // Asegurarse de combinar las celdas adecuadas en la cabecera
                                  sheet["!merges"] = [
                                    { s: { r: 0, c: 0 }, e: { r: 0, c: columnasStock.length + 4 } },
                                  ];

                                  const workbook = XLSX.utils.book_new();
                                  XLSX.utils.book_append_sheet(
                                    workbook,
                                    sheet,
                                    `Stock ${dayjs().format("DD-MM-YYYY")}`
                                  );

                                  XLSX.writeFile(
                                    workbook,
                                    `Productos Deposito ${sucursal?.nombre} - ${dayjs().format(
                                      "DD-MM-YYYY"
                                    )}.xlsx`
                                  );
                                });
                              },
                            },
                            {
                              name: "Total por deposito",
                              icon: { icon: "assessment", color: getUproColor("sistemasGris") },
                              onClick: () => {
                                getTotalSede({
                                  variables: {
                                    order: { field: orderBy.campo, order: orderBy.orden },
                                    // mandar el filtro sin la propiedad _stock
                                    filter: Object.keys(filter).reduce((acc, key) => {
                                      if (key !== "_stock") {
                                        acc[key] = filter[key];
                                      }
                                      return acc;
                                    }, {}),
                                  },
                                }).then((data) => {
                                  let productosExportar = data?.data?.productos;

                                  // Obtener todas las columnas de stock posibles
                                  let columnasStock = new Set();
                                  productosExportar.forEach((producto) => {
                                    producto.stockPorSede.forEach((sede) => {
                                      columnasStock.add(`Stock ${sede.sede.nombre}`);
                                    });
                                  });

                                  // Convertir el conjunto a un array para ordenarlas
                                  columnasStock = Array.from(columnasStock).sort();

                                  // Procesar los productos para asegurar que cada uno tenga todas las columnas de stock
                                  productosExportar = productosExportar.map((producto) => {
                                    let thisProducto = {
                                      ID: producto.id,
                                      Tipo: producto.tipo,
                                      Nombre: producto.nombre,
                                      Categoria: producto.categoria,
                                      Deshabilitado: producto.disabled ? "Si" : "No",
                                    };

                                    // Inicializar todas las columnas de stock en 0
                                    columnasStock.forEach((columna) => {
                                      thisProducto[columna] = 0;
                                    });

                                    // Rellenar las columnas de stock con los valores correspondientes
                                    producto.stockPorSede.forEach((sucursal) => {
                                      thisProducto[`Stock ${sucursal.sede.nombre}`] =
                                        sucursal.cantidad || 0;
                                    });

                                    return thisProducto;
                                  });

                                  const cabecera = [
                                    [`Stock efectivo por sede - ${dayjs().format("DD-MM-YYYY")}`],
                                  ];

                                  const sheet = XLSX.utils.aoa_to_sheet(cabecera);

                                  XLSX.utils.sheet_add_json(sheet, productosExportar, {
                                    skipHeader: false,
                                    origin: -1,
                                  });

                                  // Asegurarse de combinar las celdas adecuadas en la cabecera
                                  sheet["!merges"] = [
                                    { s: { r: 0, c: 0 }, e: { r: 0, c: columnasStock.length + 4 } },
                                  ];

                                  const workbook = XLSX.utils.book_new();
                                  XLSX.utils.book_append_sheet(
                                    workbook,
                                    sheet,
                                    `Stock ${dayjs().format("DD-MM-YYYY")}`
                                  );

                                  XLSX.writeFile(
                                    workbook,
                                    `Productos por Deposito - ${dayjs().format("DD-MM-YYYY")}.xlsx`
                                  );
                                });
                              },
                            },
                          ],
                          handleClose: () => {},
                        });
                      }}
                    >
                      <Icon>download</Icon>
                      &nbsp; Excel
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </Collapse>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : rows?.length > 0 && selectedSucursal ? (
                <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.productos.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
          {renderMenu}
          {renderAjusteMenu}
          {renderSucursalMenu}
          {renderIngresoMenu}
          {renderMovimientoSedesMenu}
          {renderTransferenciaMenu}
        </SoftBox>
      </SoftBox>

      <ModalConfirm
        open={openModalConfirm}
        handleClose={handleCloseModalConfirm}
        message={
          selectedItem?.disabled
            ? `¿Está seguro que desea habilitar el producto?`
            : `¿Está seguro que desea deshabilitar el producto? ` +
              `No estará disponible para nuevos pedidos y ordenes de compra.`
        }
        func={handleDisabled}
        pedirMotivo={!selectedItem?.disabled}
      />
      <ModalProducto
        id={selectedItem?.id || null}
        idSucursal={selectedSucursal || null}
        tipo={selectedItem?.tipo || null}
        open={openModalProducto}
        handleClose={() => {
          setOpenModalProducto(false);
        }}
        refetch={() => {
          refetch();
          handleCloseMenu();
        }}
      />
      <ModalProductoMovimientos
        open={openModalProductoMovimientos}
        handleClose={() => {
          setOpenModalProductoMovimientos(false);
          handleCloseMenu();
        }}
        idProducto={selectedItem?.id}
        idSucursal={selectedSucursal}
      />
      <ModalProductoStock
        open={openModalProductoStock}
        handleClose={() => {
          setOpenModalProductoStock(false);
          handleCloseMenu();
        }}
        idProducto={selectedItem?.id}
        idSede={dataSucurales?.sucursales?.find((s) => s.id === selectedSucursal)?.sede.id}
      />
      <ModalIngresar
        open={openModalIngresar}
        handleClose={() => setOpenModalIngresar(false)}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalAjuste
        open={openModalAjuste}
        handleClose={() => setOpenModalAjuste(false)}
        selectedAjuste={selectedAjuste}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalNuevoRemito
        open={openModalNuevoRemito}
        handleClose={() => {
          setOpenModalNuevoRemito(false);
        }}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalTransferencias
        open={openModalTransferenciaHistory}
        handleClose={() => setOpenModalTransferenciaHistory(false)}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalSolicitudes
        open={openModalSolicitudes}
        handleClose={() => setOpenModalSolicitudes(false)}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalDevoluciones
        open={openModalDevoluciones}
        handleClose={() => setOpenModalDevoluciones(false)}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalVerificacionPedidos
        open={openModalPedidosCompra}
        handleClose={() => setOpenModalPedidosCompra(false)}
        setVerificacionesPendientes={setVerificacionesPendientes}
        idSucursal={selectedSucursal}
      />

      {/* REMITO SEDE */}
      <ModalSedeTransferir
        open={openSedeTransferir}
        handleClose={() => {
          setOpenSedeTransferir(false);
        }}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalSedeRecepcionar
        open={openSedeRecepcionar}
        handleClose={() => {
          setOpenSedeRecepcionar(false);
        }}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalSedeHistorial
        open={openSedeHistorial}
        handleClose={() => {
          setOpenSedeHistorial(false);
        }}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />

      <ModalNuevaTransferencia
        open={openModalTransferencia}
        handleClose={() => {
          setOpenModalTransferencia(false);
        }}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
        tipo={selectedTipoTransferencia}
      />
      <ModalAjusteHistory
        open={openModalAjusteHistory}
        handleClose={() => setOpenModalAjusteHistory(false)}
        idSucursal={selectedSucursal}
        refetchProductos={refetch}
      />
      <ModalProveedores
        open={openModalProveedores}
        handleClose={() => setOpenModalProveedores(false)}
      />
      <ModalOptions
        open={openModalOptions}
        handleClose={() => setOpenModalOptions(false)}
        options={[
          {
            title: "Ingresar productos",
            icon: "local_shipping",
            onClick: (e) => handleOpenIngresoMenu(e),
            permiso: 1,
            color: "sistemasAmarillo",
          },
          {
            title: "Transferencias entre depositos",
            icon: "call_merge",
            onClick: (e) => handleOpenMovimientoSedesMenu(e),
            permiso: 10,
            color: "sistemasAmarillo",
          },
          {
            title: "Transferencias y prestamos",
            icon: "import_export",
            onClick: (e) => handleOpenTransferenciaMenu(e),
            permiso: 2,
            color: "sistemasAmarillo",
          },
          {
            title: "Ajustar stock",
            icon: "drive_file_rename_outline",
            onClick: (e) => handleOpenAjusteMenu(e),
            permiso: 3,
            color: "sistemasAmarillo",
          },
          {
            title: "Administrar proveedores",
            icon: "group",
            onClick: (e) => setOpenModalProveedores(true),
            permiso: 9,
            color: "sistemasAmarillo",
          },
          {
            title: "Agregar nuevo producto",
            icon: "add",
            onClick: (e) => {
              setOpenModalProducto(true);
              setSelectedItem({ tipo: "Material" });
            },
            permiso: 4,
            color: "primary",
          },
        ]}
      />
      <ModalStockRapido
        open={openModalStockRapido}
        handleClose={() => setOpenModalStockRapido(false)}
        idSucursal={selectedSucursal}
        refetch={refetch}
      />

      <ModalSucursales
      open={openSucursales}
      handleClose={()=>setOpenSucursales(false)}
      refetch={refetchSucursales}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default Productos;
