// React
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import recepcionSedesData from "layouts/productos/data/recepcionSedesData";
import CustomPagination from "components/Pagination";
import ModalNuevaRecepcion from "./nuevaRecepcion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "60%" },
  overflow: "auto",
};

export default function ModalRecepcionSede({ open, handleClose, idSucursal, refetchProductos }) {
  const [openModalNuevaRecepcion, setOpenModalNuevaRecepcion] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedMovimiento, setSelectedMovimiento] = useState();
  const { columns, getRows } = recepcionSedesData;
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "createdAt",
    orden: "DESC",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const {
    data: movimientos,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getMovimientos($filter: JSON, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        movimientos(
          filter: $filter
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          tipo
          ordenPago
          user {
            id
            username
            nombre
            apellido
          }
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          sucursalOrigen {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          observaciones
          fechaEntrega
          fechaOrdenPago
          numeroExpediente
          proveedor {
            id
            razonSocial
            nombre
            apellido
          }
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
          userProvisorio{
            id
            nombre
            apellido
          }
          comentarios{
            idUser
            comentario
            fecha
          }
          createdAt
        }
      }
    `,
    {
      variables: {
        filter: {
          tipo: "Remito Sedes - Egreso",
          idSucursalDestino: idSucursal,
          idRecepcion: null,
        },
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const handleOpenTransferencia = (transferencia) => {
    setSelectedMovimiento(transferencia);
    setOpenModalNuevaRecepcion(true);
  };

  useEffect(() => {
    if (movimientos && movimientos?.movimientos) {
      setRows(getRows(movimientos.movimientos, handleOpenTransferencia));
      setPagesInfo(movimientos?.paginationInfo);
    }
  }, [movimientos]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, openModalNuevaRecepcion]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>

            <SoftBox>
              <SoftBox display="flex" justifyContent="space-between" p={3}>
                <SoftBox>
                  <SoftTypography variant="h6">Recepciones pendientes</SoftTypography>
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">close</Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  maxHeight: "60vh",
                  overflow: "auto",
                }}
              >
                {loading ? (
                  <Loading />
                ) : movimientos?.movimientos?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
          <CustomPagination
            loading={loading}
            length={movimientos?.movimientos.length}
            total={pagesInfo?.total}
            pages={pagesInfo?.pages}
            page={page}
            setPage={setPage}
            noCard
          />
            </SoftBox>
          <SoftBox>
            <ModalNuevaRecepcion
              open={openModalNuevaRecepcion}
              handleClose={() => {
                setOpenModalNuevaRecepcion(false);
                setSelectedMovimiento();
              }}
              movimiento={selectedMovimiento}
              idSucursal={idSucursal}
              refetchProductos={refetchProductos}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalRecepcionSede.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
