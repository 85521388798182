// React
import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useLazyQuery } from "@apollo/client";
import DefaultDoughnutChart from "components/Charts/DoughnutCharts/DefaultDoughnutChart";
import DefaultLineChart from "components/Charts/LineCharts/DefaultLineChart";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftProgress from "components/SoftHorarios";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import SoftCircularProgress from "components/SoftCircularProgress";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalReportes({ open, handleClose, selectedProyecto }) {
  const [getPresupuestos, { data: presupuestos, loading, refetch: refetchPresupuestos }] =
    useLazyQuery(
      gql`
        query getPresupuestos($idProyecto: ID) {
          presupuestos(filter: { idProyecto: $idProyecto }, currentVersions: false) {
            id
            numero
            version
            createdAt
            fechaInicioEstimada
            fechaFinEstimada
            fases {
              id
              nombre
              cantidad
              porcentajeGanancia
              manoDeObra {
                id
                horas
                precio
              }
              materiales {
                id
                precio
                cantidad
              }
              concepto {
                id
                precio
              }
            }
          }
        }
      `,
      {
        fetchPolicy: "network-only",
        cachePolicy: "no-cache",
      }
    );

  const [presupuestoInicial, setPresupuestoInicial] = useState(null);
  const [presupuestoActual, setPresupuestoActual] = useState(null);
  const [presupuestosModificados, setPresupuestosModificados] = useState([]);

  useEffect(() => {
    if (selectedProyecto && open) {
      getPresupuestos({ variables: { idProyecto: selectedProyecto.id } });
    }
  }, [selectedProyecto, open]);

  useEffect(() => {
    if (presupuestos?.presupuestos && open) {
      setPresupuestoActual(presupuestos.presupuestos[presupuestos.presupuestos.length - 1]);
      const presupuesto = presupuestos.presupuestos.find(
        (presupuesto) => presupuesto.id === selectedProyecto.presupuestoAprobado.id
      );
      setPresupuestoInicial(presupuesto);
      setPresupuestosModificados(
        presupuestos.presupuestos.filter((item) => item?.version >= presupuesto?.version)
      );
    }
  }, [presupuestos]);

  const getTotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        const porcentaje = parseInt(fase?.porcentajeGanancia) || 0;
        // let content = fase?.manoDeObra?.concat(fase?.materiales) || [];
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        subtotal += subtotal * (porcentaje / 100) || 0;
        total += subtotal;
      });
    }

    return total;
  };

  const getSubtotal = (fasesPresupuesto) => {
    let total = 0;

    if (fasesPresupuesto?.length > 0) {
      fasesPresupuesto.forEach((fase) => {
        const cantidad = parseInt(fase?.cantidad) || 0;
        let content = [
          ...(fase?.manoDeObra || []),
          ...(fase?.materiales || []),
          ...(fase?.concepto || []),
        ];
        let subtotal = 0;
        content?.forEach((item) => {
          if (item.horas) {
            subtotal += item.precio * item.horas * cantidad || 0;
          } else if (item.cantidad) {
            subtotal += item.precio * item.cantidad * cantidad || 0;
          } else {
            subtotal += item.precio || 0;
          }
        });
        total += subtotal;
      });
    }

    return total;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Reportes</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end">
                <SoftBox display="flex" alignItems="center">
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox py={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                        <SoftBox>
                          <SoftBox display="flex" alignItems="center">
                            <Icon fontSize="small" color="primary">
                              schedule
                            </Icon>
                            <SoftTypography variant="body2" fontWeight="medium" ml={1}>
                              Inicio
                            </SoftTypography>
                          </SoftBox>
                          <SoftTypography variant="body2" fontWeight="medium">
                            {presupuestoInicial?.fechaInicioEstimada
                              ? dayjs(presupuestoInicial?.fechaInicioEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox sx={{ width: "80%" }} textAlign="center">
                          <SoftBox px={2} display="flex-row" justifyContent="center">
                            <SoftTypography variant="body2" fontWeight="medium" mb={1}>
                              40%
                            </SoftTypography>
                            <SoftBox
                              sx={{
                                width: "100%",
                              }}
                            >
                              <SoftProgress color="primary" variant="gradient" value={40} />
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox>
                          <SoftBox display="flex" alignItems="center" justifyContent="flex-end">
                            <SoftTypography variant="body2" fontWeight="medium" mr={1}>
                              Fin
                            </SoftTypography>
                            <Icon fontSize="small" color="primary">
                              schedule
                            </Icon>
                          </SoftBox>
                          <SoftTypography variant="body2" fontWeight="medium">
                            {presupuestoInicial?.fechaFinEstimada
                              ? dayjs(presupuestoInicial?.fechaFinEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <DefaultLineChart
                    title="Variación del total del presupuesto"
                    chart={{
                      labels: presupuestosModificados.map(
                        (presupuesto) => `v${presupuesto.version}`
                      ),
                      datasets: [
                        {
                          label: `Presupuesto Actual`,
                          color: "#F1AF29",
                          data: presupuestosModificados.map((presupuesto) =>
                            getTotal(presupuesto.fases)
                          ),
                        },
                        {
                          label: `Presupuesto Inicial`,
                          color: "#535049",
                          data: presupuestosModificados.map(() =>
                            getTotal(presupuestoInicial?.fases)
                          ),
                        },
                      ],
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DefaultDoughnutChart
                    title="Fases del proyecto"
                    chart={{
                      labels: ["En Curso", "Finalizado", "Pendiente"],
                      datasets: {
                        label: "Projects",
                        backgroundColors: ["info", "success", "error"],
                        data: [20, 15, 65],
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox display="flex" justifyContent="space-between">
                        <SoftBox>
                          <SoftTypography variant="h6">Comparativa de presupuestos</SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={2}>
                        <SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            {`Versión inicial (v${presupuestoInicial?.version})`}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox>
                          <SoftTypography variant="h6" fontWeight="bold">
                            {`Versión actual (v${presupuestoActual?.version})`}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={1}>
                        <SoftBox display="flex" alignItems="center">
                          <Icon fontSize="small" color="primary">
                            schedule
                          </Icon>
                          <SoftTypography variant="button" fontWeight="medium" ml={1}>
                            Fases: {presupuestoInicial?.fases?.length}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="button" fontWeight="medium" mr={1}>
                            Fases: {presupuestoActual?.fases?.length}
                          </SoftTypography>
                          <Icon fontSize="small" color="primary">
                            schedule
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={1}>
                        <SoftBox display="flex" alignItems="center">
                          <Icon fontSize="small" color="primary">
                            attach_money
                          </Icon>
                          <SoftTypography variant="button" fontWeight="medium" ml={1}>
                            Total: ${formatMoneyPunto(getTotal(presupuestoInicial?.fases))}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="button" fontWeight="medium" mr={1}>
                            Total: ${formatMoneyPunto(getTotal(presupuestoActual?.fases))}
                          </SoftTypography>
                          <Icon fontSize="small" color="primary">
                            attach_money
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={1}>
                        <SoftBox display="flex" alignItems="center">
                          <Icon fontSize="small" color="primary">
                            paid
                          </Icon>
                          <SoftTypography variant="button" fontWeight="medium" ml={1}>
                            Ganancia: $
                            {formatMoneyPunto(
                              getTotal(presupuestoInicial?.fases) -
                                getSubtotal(presupuestoInicial?.fases)
                            )}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="button" fontWeight="medium" mr={1}>
                            Ganancia: $
                            {formatMoneyPunto(
                              getTotal(presupuestoActual?.fases) -
                                getSubtotal(presupuestoActual?.fases)
                            )}
                          </SoftTypography>
                          <Icon fontSize="small" color="primary">
                            paid
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={1}>
                        <SoftBox display="flex" alignItems="center">
                          <Icon fontSize="small" color="primary">
                            calendar_month
                          </Icon>
                          <SoftTypography variant="button" fontWeight="medium" ml={1}>
                            Inicio estimado:{" "}
                            {presupuestoInicial?.fechaInicioEstimada
                              ? dayjs(presupuestoInicial?.fechaInicioEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="button" fontWeight="medium" mr={1}>
                            Inicio estimado:{" "}
                            {presupuestoActual?.fechaInicioEstimada
                              ? dayjs(presupuestoActual?.fechaInicioEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                          <Icon fontSize="small" color="primary">
                            calendar_month
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox display="flex" justifyContent="space-between" mt={1}>
                        <SoftBox display="flex" alignItems="center">
                          <Icon fontSize="small" color="primary">
                            calendar_today
                          </Icon>
                          <SoftTypography variant="button" fontWeight="medium" ml={1}>
                            Fin estimado:{" "}
                            {presupuestoInicial?.fechaFinEstimada
                              ? dayjs(presupuestoInicial?.fechaFinEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" alignItems="center">
                          <SoftTypography variant="button" fontWeight="medium" mr={1}>
                            Fin estimado:{" "}
                            {presupuestoActual?.fechaFinEstimada
                              ? dayjs(presupuestoActual?.fechaFinEstimada).format("DD-MM-YYYY")
                              : "Sin especificar"}
                          </SoftTypography>
                          <Icon fontSize="small" color="primary">
                            calendar_today
                          </Icon>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <SoftBox p={3}>
                      <SoftBox display="flex" justifyContent="space-between">
                        <SoftBox>
                          <SoftTypography variant="h6">Certificados generados</SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      <SoftBox mt={2}>
                        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
                          <SoftBox display="flex">
                            <Tooltip title={"Certificado"} placement="top">
                              <SoftBox
                                display="flex"
                                alignItems="center"
                                mr={2}
                                sx={{
                                  cursor: "pointer",
                                }}
                              >
                                <SoftCircularProgress
                                  color={"success"}
                                  icon={"verified"}
                                  value={0}
                                />
                              </SoftBox>
                            </Tooltip>
                            <SoftBox lineHeight={1}>
                              <SoftTypography variant="button">
                                Certificado 05-08-2024
                              </SoftTypography>
                              <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h5" fontWeight="bold">
                                  50% de avance total
                                </SoftTypography>
                              </SoftBox>
                            </SoftBox>
                          </SoftBox>
                          <SoftBox display="flex">
                            <SoftBox>
                              <Tooltip title="Descargar PDF" placement="top">
                                <SoftButton color="primary" circular iconOnly>
                                  <Icon>download</Icon>
                                </SoftButton>
                              </Tooltip>
                            </SoftBox>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="sistemasGris"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cerrar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalReportes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedProyecto: PropTypes.object,
};
