import { Grid, Icon } from "@mui/material";
import SoftTypography from "components/SoftTypography";

function SinResultados() {
  return (
    <Grid container textAlign={"center"} py={3}>
      <Grid item xs={12}>
        <Icon
          sx={{
            width: "50px",
          }}
        >
          search_off
        </Icon>
      </Grid>
      <Grid item xs={12}>
        <SoftTypography variant="h6">Sin resultados</SoftTypography>
      </Grid>
      <Grid item xs={12}>
        <SoftTypography variant="caption" component="p" color="text">
          No hay resultados para la búsqueda
        </SoftTypography>
      </Grid>
    </Grid>
  );
}
export default SinResultados;
