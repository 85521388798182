// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

// Custom UI components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useContext, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SoftInput from "components/SoftInput";
import { UserContext } from "context/user";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflow: "auto",
};

export default function ModalAddPlantilla({ open, handleClose, permisos, refetch }) {
  const [data, setData] = useState();
  const { user } = useContext(UserContext);
  const { handleSnackbar } = useContext(MessageManager);

  const [addPlantilla, { loading: loadingPlantilla }] = useMutation(
    gql`
      mutation savePlantilla($input: PlantillaInput!) {
        savePlantilla(input: $input) {
          id
        }
      }
    `
  );

  const handleChanges = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    const input = {
      ...data,
      idUser: user.id,
      tipo: "Permiso",
      plantillaDetalle: permisos.map((id) => ({
        idPermiso: id,
      })),
    };
    await addPlantilla({ variables: { input } })
      .then(() => {
        handleSnackbar("Plantilla creada correctamente", "success");
        handleClose();
        refetch();
      })
      .catch((e) => {
        handleSnackbar("Ha ocurrido un error al crear la plantilla", "error");
      });
  };

  useEffect(() => {
    setData();
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                p={3}
                pb={0}
              >
                <SoftBox>
                  <SoftTypography variant="h6">Crear nueva plantilla</SoftTypography>
                  <SoftBox display="flex" alignItems="center">
                    <SoftBox display="flex" alignItems="center">
                      <SoftBox mr={1}>
                        <Icon fontSize="small">verified_user</Icon>
                      </SoftBox>
                      <SoftTypography variant="h7">{`${permisos.length} permisos`}</SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
              <SoftBox p={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="nombre">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Nombre de la plantilla
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="nombre"
                      placeholder="Inserte un nombre"
                      type="text"
                      name="nombre"
                      value={data?.nombre || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="end" alignItems="center" p={3} pt={0}>
              <SoftBox mr={2}>
                <SoftButton color="primary" circular onClick={handleClose}>
                  <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  color="sistemasGris"
                  circular
                  disabled={!data?.nombre || loadingPlantilla}
                  onClick={handleSave}
                >
                  {loadingPlantilla && <CircularProgress size={15} color="inherit" />}
                  {!loadingPlantilla && <Icon sx={{ fontWeight: "light" }}>save</Icon>}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAddPlantilla.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  permisos: PropTypes.array,
  refetch: PropTypes.func,
};
