import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Card, Grid, Icon } from "@mui/material";
import brand from "assets/images/logo.png";
import DinamicCard from "components/Cards/InfoCards/DinamicCard";
import Footer from "components/Footer";
import EmpleadoLayout from "components/LayoutContainers/EmpleadoLayout";
import ModalLogout from "components/Modals/Logout";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { ConfirmarContext } from "context/ConfirmarContext";
import { UserContext } from "context/user";
import ModalNuevaRecepcion from "layouts/productos/components/TransferenciaSede/Recepcion/nuevaRecepcion";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { formatDate } from "utils/formatters";

function Recepcion() {
  const { user, logout, verificarAcceso } = useContext(UserContext);
  const { confirmar } = useContext(ConfirmarContext);
  const [openModalNuevaRecepcion, setOpenModalNuevaRecepcion] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [recepcionado, setRecepcionado] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [data, setData] = useState();
  const { id } = useParams();

  const [getData] = useLazyQuery(
    gql`
      query getMovimientos($filter: JSON) {
        movimientos(
          filter: $filter
          pagination: { page: 1, limit: 1 }
        ) {
          id
          tipo
          ordenPago
          user {
            id
            username
            nombre
            apellido
          }
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          sucursalOrigen {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          observaciones
          fechaEntrega
          fechaOrdenPago
          numeroExpediente
          proveedor {
            id
            razonSocial
            nombre
            apellido
          }
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
          userProvisorio{
            id
            nombre
            apellido
          }
          comentarios{
            idUser
            comentario
            fecha
          }
          createdAt
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [saveProvisorio] = useMutation(gql`
    mutation saveProvisorio($id: ID!, $observacion: String!) {
      updateRecepcionProvisoria(id: $id, observacion: $observacion) {
        id
      }
    }
  `);

  useEffect(() => {
    if (id) {
      getData({ variables: {
        filter: { 
          id: id,
          tipo: "Remito Sedes - Egreso",
          idRecepcion: null
        },
      }}).then((res) => {
        if (res.data?.movimientos?.length > 0) {
          setData(res.data.movimientos[0]);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if(data){
      if(!verificarAcceso(10) && data.userProvisorio){
        setRecepcionado(true)
      }

      let sedes = [... new Set(user.sucursales?.map(s => s.sede?.id))]
      if(sedes.includes(data?.sucursalDestino?.sede?.id)){
        setDisabled(false)
      }
    }
  }, [data])

  const handleOpenRecepcion = () => {
    //puede hacer la recepcion final
    if(verificarAcceso(10) && user.sucursales?.some(s => s.id === data?.sucursalDestino?.id)){
      setOpenModalNuevaRecepcion(true);
      return;
    }

    //puede hacer una recepcion provisoria
    let sedes = [... new Set(user.sucursales?.map(s => s.sede?.id))]
    if(sedes.includes(data?.sucursalDestino?.sede?.id)){
      confirmar({
        icon: "swipe_down_alt",
        title: "Recepcion provisoria",
        message: "No tiene permisos para recepción final, ¿desea hacer una recepción provisoria? deberá avisar a un encargado para completarla.",
        func: (motivo) => {
          saveProvisorio({
            variables: {
              id: data.id,
              observacion: motivo
            }
          }).then(() => {
            setRecepcionado(true)
          })
        },
        pedirMotivo: true,
        placeholder: "Especifique cualquier dato de interes para el encargado de stock",
      })
      return;
    }
  };

  return (
    <EmpleadoLayout>
      <SoftBox mb={2}>
        <Card>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  component={NavLink}
                  to="/"
                  display="flex"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                >
                  <SoftBox
                    component="img"
                    src={brand}
                    alt="Logo"
                    width={{ xs: "20vh", md: "15vh" }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    height: "100%",
                  }}
                >
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                  >
                    <SoftButton component={NavLink} to="/" circular fullWidth color="sistemasGris">
                      Ir al dashboard &nbsp;
                      <Icon>home</Icon>
                    </SoftButton>
                  </SoftBox>
                  <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                    display="flex"
                    justifyContent="center"
                  >
                    <SoftButton
                      onClick={() => setOpenLogout(true)}
                      circular
                      fullWidth
                      color="primary"
                    >
                      Cerrar sesión &nbsp;
                      <Icon>logout</Icon>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <SoftBox mb={3}>
        <Card>
          <SoftBox p={3}>
            {data ? (
              <SoftBox display="flex" flexDirection="column" gap={2} width={{xs: "100%", md:"50%", lg: "30vw"}}>
                <SoftTypography>Recepción de remito</SoftTypography>
                <DinamicCard
                  title={data?.sucursalDestino?.sede?.nombre}
                  data={{
                    Movimiento: data?.id,
                    origen: data?.sucursalOrigen?.sede?.nombre,
                    usuario: data?.user?.nombre
                      ? `${data?.user?.nombre} ${data?.user?.apellido}`
                      : data?.user?.username,
                    fecha: formatDate(data?.createdAt, "dd/MM/yyyy hh:mm"),
                    Observación: data?.observaciones,
                  }}
                >
                  {data?.userProvisorio && (
                    <SoftTypography variant="caption" color="secondary">
                      <strong>Recepción provisoria</strong><br />
                      {data.userProvisorio?.nombre} {data.userProvisorio?.apellido} el &nbsp; 
                      {formatDate(data.comentarios?.find(c => c.idUser === data.userProvisorio.id).fecha, "dd/MM/yyyy hh:mm")}:<br/>
                      {data.comentarios?.find(c => c.idUser === data.userProvisorio.id).comentario}
                    </SoftTypography>
                  )}
                </DinamicCard>
                <SoftButton 
                  color="primary" 
                  fullWidth
                  onClick={handleOpenRecepcion}
                  disabled={disabled || recepcionado}
                >
                  {disabled ? "No puedes recepcionar en esta sede" : 
                  recepcionado ? (<>
                    Recepcionado &nbsp;<Icon>check</Icon>
                  </>):(<>
                    Recepcionar &nbsp;<Icon>swipe_down_alt</Icon>
                  </>)}
                </SoftButton>
              </SoftBox>
            ) : (
              <Grid container textAlign={"center"} py={3}>
                <Grid item xs={12}>
                  <Icon fontSize="medium">search_off</Icon>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">El remito no existe</SoftTypography>
                </Grid>
                <Grid item xs={12}>
                  <SoftTypography variant="caption" component="p" color="text">
                    El remito que buscas no existe o ya fue recepcionado
                  </SoftTypography>
                </Grid>
              </Grid>
            )}
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
      <ModalLogout
        open={openLogout}
        handleClose={() => {
          setOpenLogout(false);
        }}
        btnFunction={logout}
      />
      <ModalNuevaRecepcion
        open={openModalNuevaRecepcion}
        handleClose={() => {
          setOpenModalNuevaRecepcion(false);
        }}
        movimiento={data}
        refetchProductos={() => {
          setRecepcionado(true)
        }}
      />
    </EmpleadoLayout>
  );
}

export default Recepcion;
