// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import TableItem from "../TableItem";
import SinDatos from "components/Carteles/SinDatos";
import SoftInput from "components/SoftInput";
import { formatMoneyPunto } from "utils/formatters";
import dayjs from "dayjs";
import TableItemDetalle from "./TablaItemDetalle";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "70%" },
  overflowY: "auto",
  p: 3,
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "center", noOrder: true },
  { name: "cantComprada", desc: "Cant. a Comprar", align: "center", noOrder: true },

  { name: "precioCompra ", desc: "Precio Unit.", align: "center", noOrder: true },
  { name: "tipoMonedaCompra ", desc: "Tipo Moneda", align: "center", noOrder: true },
  { name: "cotizacionCompra ", desc: "Cotización", align: "center", noOrder: true },
  { name: "subtotal", desc: "Subtotal", align: "center", noOrder: true },
  { name: "recibido ", desc: "Total Recibido", align: "center", noOrder: true },
];

export default function ModalDetalle({ open, handleClose, idProyecto,idOrden,ordenTipo }) {
    const {handleSnackbar} = useContext(MessageManager);
    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;
    const [infoOrdenCompra,setInfoOrdenCompra] = useState({});
 
    const [getOrden, { data,  loading: loadingOrden, error: errorOrden }] = useLazyQuery(gql`
        query getOrden($id: ID!) {
            ordenCompra(id: $id) {
            id
            fechaOrden 
            totalOrden
            proyectoFase{
                id
                nombre
                cantidad
                etapa{
                  id
                  nombre
                }
            }
            proveedor{
                id
                tipoProveedor
                razonSocial
                nombre
                apellido
            }
            detalle{
                id
                tipo
                nombre
                cantidad
                cantComprada
                precioCompra
                tipoMonedaCompra
                cotizacionCompra
                producto{
                  id
                  nombre
                  unidadMedida
                }
            }
            movimiento{
              id
              movimientoDetalles{
                id
                producto{
                  id
                  nombre
                }
                precio
                cantidad
                superaOrdenProducto
              }
            }
            }
        }
        `, {
            onCompleted: (data) => {
              if(!data) return;
              if(data?.ordenCompra?.movimiento?.length > 0){
                handleAcumuladoRecepcion(data?.ordenCompra);
              }else{
                setInfoOrdenCompra(data?.ordenCompra);  
              }
            },
            fetchPolicy: "no-cache"
        }
    );

    const handleAcumuladoRecepcion = (ordenCompra)=>{
      let arrayMovimientos = [];

      ordenCompra?.movimiento?.forEach((item) => {
        item?.movimientoDetalles?.forEach((element) => {
          if(arrayMovimientos.some((mov) => mov?.producto?.id == element?.producto?.id)){
            let index = arrayMovimientos.findIndex((mov) => mov?.producto?.id == element?.producto?.id);
            arrayMovimientos[index].totalRecibido += element?.cantidad;
          }else{
            arrayMovimientos.push({
              ...element,
              totalRecibido: element?.cantidad
            });
          }
        });
      });
      
      let arrayOrdenRecepcion = [];

      ordenCompra?.detalle?.forEach((item) => {
        if(arrayMovimientos.some((mov)=> mov?.producto?.id == item?.producto?.id)){
          let movTotal = arrayMovimientos.find((mov) => mov?.producto?.id == item?.producto?.id);
          arrayOrdenRecepcion.push({
            ...item,
            recibido: movTotal?.totalRecibido,
          });          
        }else{
          arrayOrdenRecepcion.push({
            ...item,
            recibido: 0,
          });
        }
      });
      
      setInfoOrdenCompra({
        ...ordenCompra,
        detalle: arrayOrdenRecepcion 
      });  
    }

    useEffect(() => {
        if(open){
            getOrden({
            variables: {
                id: idOrden
            }
            });
        }
    }, [open]);
 
    const renderColumns = (columns) => {
        return columns.map(({ name, desc, align, width }, key) => {
        let pl;
        let pr;

        if (key === 0) {
            pl = 1;
            pr = 1;
        } else if (key === columns.length - 1) {
            pl = 1;
            pr = 1;
        } else {
            pl = 1;
            pr = 1;
        }

        return (
            <SoftBox
            key={name}
            component="th"
            width={width || "auto"}
            pt={1.5}
            pb={1.25}
            pl={align === "left" ? pl : 1}
            pr={align === "right" ? pr : 1}
            textAlign={align}
            fontSize={size.xxs}
            fontWeight={fontWeightBold}
            color="secondary"
            opacity={0.7}
            borderBottom={`${borderWidth[1]} solid ${light.main}`}
            >
            {desc ? desc.toUpperCase() : name.toUpperCase()}
            </SoftBox>
        );
        });
    };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" pb={1}>
                <SoftBox lineHeight={1}>
                    <SoftTypography variant="h6" lineHeight={1}>
                        Orden de compra
                    </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                        <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                        </Icon>
                    </Tooltip>
                    </SoftBox>
                </SoftBox>
            </SoftBox>

            <SoftBox >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                  <SoftTypography variant="h6">Fecha: {infoOrdenCompra?.fechaOrden ? dayjs(infoOrdenCompra?.fechaOrden).format("DD/MM/YYYY") : "-"}</SoftTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SoftTypography variant="h6">Etapa: {infoOrdenCompra?.proyectoFase?.etapa ? infoOrdenCompra?.proyectoFase?.etapa?.nombre : "-"}</SoftTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <SoftTypography variant="h6">Fase: {infoOrdenCompra?.proyectoFase ? infoOrdenCompra?.proyectoFase?.nombre : "-"}</SoftTypography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SoftTypography variant="h6">Proveedor: {infoOrdenCompra?.proveedor ? infoOrdenCompra?.proveedor?.nombre : "-"}</SoftTypography>
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox py={3}>
              <TableContainer>
                <Table>
                  <SoftBox component="thead">
                    <TableRow>
                      {renderColumns(columns)}
                    </TableRow>
                  </SoftBox>  
                  <TableBody>
                    {
                      infoOrdenCompra?.detalle?.length > 0 && (
                        infoOrdenCompra?.detalle?.map((item, key) => {
                            return (
                              <TableItemDetalle
                              key={item?.id}
                              dataFila={item}
                              cantidad={infoOrdenCompra?.proyectoFase?.cantidad}
                              />
                            )
                        })
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {
                (infoOrdenCompra?.detalle?.length == 0) && (<SinDatos/>) 
              }

              <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                <SoftTypography variant="h5" fontWeight="bold">
                  Total:&nbsp;
                </SoftTypography>
                <SoftTypography variant="h5" fontWeight="bold">
                  {infoOrdenCompra?.totalOrden  ? "$ " + formatMoneyPunto(infoOrdenCompra?.totalOrden) : "$ 0.00"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cerrar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDetalle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idProyecto: PropTypes.string,
  idOrden: PropTypes.string,
  ordenTipo: PropTypes.string,
};
