import {
  Backdrop,
  Card,
  Fade,
  Grid,
  Icon,
  Modal,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useRef, useState } from "react";
// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { formatDate } from "utils/formatters";
import SoftBadge from "components/SoftBadge";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", md: "70%", lg: "60%", xl: "40%"},
  py: 4,
  px: 4,
  overflow: "auto",
};

export default function ModalProductoMovimientos({ open, handleClose, idProducto, idSucursal }) {
  const [movimientos, setMovimientos] = useState([])
  const [producto, setProducto] = useState({})
  const [load, setLoad] = useState(3)
  let tipos = ["Material", "Consumible", "Recurso"]
  let icons = ["category", "recycling", "layers"]

  const [getProducto, { loading }] = useLazyQuery(gql`
      query getProducto($idProducto: ID, $idSucursal: ID, $load: Int) {
        productos(filter: {id: $idProducto}) {
          id
          tipo
          codigo
          nombre
          stock(idSucursal: $idSucursal) {
            cantidad
          },
          movimientos(idSucursal: $idSucursal, limit: $load) {
            id
            cantidad
            createdAt
            movimiento {
              tipo
              user {
                username
                id
              }
            }
          }
        }
      }
    `, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (open && idProducto && idSucursal) {
      getProducto({ variables: { idProducto, idSucursal, load } }).then(({ data }) => {
        setMovimientos(data?.productos[0].movimientos)
        setProducto({
          id: data?.productos[0].id,
          tipo: data?.productos[0]?.tipo,
          codigo: data?.productos[0].codigo,
          nombre: data?.productos[0].nombre,
          stock: data?.productos[0].stock?.cantidad || 0
        })
      })
    } else {
      setLoad(3)
      setMovimientos([])
      setProducto({})
    }
  }, [open, load])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Últimos movimientos</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <MiniStatisticsCard
                direction="left"
                title={{ text: `${producto.nombre || ""} ${producto.codigo}` || "" }}
                count={producto.stock || 0}
                percentage={{ text: "en stock" }}
                icon={{ 
                  color: "primary",
                  component: icons[tipos.indexOf(producto.tipo)] || "layers" 
                }}
              />
            </SoftBox>
            <SoftBox pt={2}>
              <Card>
                <SoftBox>
                  <Grid container pr={1.5}>
                    <Grid item container xs={12} borderBottom="solid 1px #e9ecef" py={1} px={2}>
                      <Grid item xs={3} px={2} textAlign="center">
                        <SoftTypography variant="caption" fontWeight="medium" color="secondary" textTransform="uppercase">Fecha</SoftTypography>
                      </Grid>
                      <Grid item xs={3} px={2} textAlign="center">
                        <SoftTypography variant="caption" fontWeight="medium" color="secondary" textTransform="uppercase">Usuario</SoftTypography>
                      </Grid>
                      <Grid item xs={4} px={2} textAlign="center">
                        <SoftTypography variant="caption" fontWeight="medium" color="secondary" textTransform="uppercase">Movimiento</SoftTypography>
                      </Grid>
                      <Grid item xs={2} textAlign="center" px={2}>
                        <SoftTypography variant="caption" fontWeight="medium" color="secondary" textTransform="uppercase">Cantidad</SoftTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {movimientos?.length === 0 && !loading && <SinDatos />}
                  <Grid container maxHeight="40vh" overflow="scroll" sx={{ overflowX: "hidden" }}>
                    {movimientos?.map((sample, index) => (
                      <Grid key={index} item container p={1} xs={12} borderBottom="solid 1px #e9ecef" sx={{ "&:hover": { backgroundColor: "#f2f6fa" } }}>
                        <Grid item xs={3} px={2} textAlign="center">
                          <Tooltip title={formatDate(sample.createdAt, "dd/MM/yyyy hh:mm")} >
                            <SoftTypography variant="caption">
                              {formatDate(sample.createdAt, "XXXX a las hh:mm")}
                            </SoftTypography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={3} px={2} textAlign="center">
                          <SoftTypography variant="caption">
                            {sample.movimiento?.user?.username || "Sistema"}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={4} px={2} textAlign="center">
                          <SoftTypography variant="caption">
                            {sample.movimiento?.tipo}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={2} px={2} textAlign="center">
                          <SoftBadge
                            color={
                              sample.cantidad > 0
                                ? "success"
                                : sample.cantidad < 0
                                  ? "error"
                                  : "warning"
                            }
                            size="xs"
                            badgeContent={
                              <SoftTypography
                                verticalAlign="text-top"
                                variant="caption"
                                color="white"
                                fontWeight="medium"
                              >
                                {sample.cantidad > 0 ? "+" : ""}
                                {sample.cantidad}
                              </SoftTypography>
                            }
                            container
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  {loading && <Loading />}
                  {movimientos?.length === load ? (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{ "&:hover": { backgroundColor: "#f2f6fa", cursor: "pointer" } }}
                      >
                        <SoftBox display="flex" alignItems="center" p={2} onClick={() => {
                          setLoad(prev => prev + 3)
                        }}>
                          <Icon fontSize="small">add</Icon>
                          <SoftTypography variant="button" color="dark" fontWeight="regular">
                            &nbsp;Cargar más
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  ) : null}
                </SoftBox>
              </Card>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalProductoMovimientos.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idProducto: PropTypes.string,
  idSucursal: PropTypes.string,
};