// @mui material components
import { Card, Fade, Grid, Icon, InputLabel, MenuItem, Modal, Select } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// Kaizen Dashboard components
import { gql, useMutation, useQuery } from "@apollo/client";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflow: "auto",
  py: 4,
};

const ADD_NOTIFICATION = gql`
  mutation addNotificacion($notificacion: NotificacionInput!) {
    addNotificacion(input: $notificacion) {
      __typename
    }
  }
`;

export default function ModalAdd({ open, handleClose }) {
  const minDate = dayjs().format("YYYY-MM-DD HH:mm");
  const [notificacion, setNotificacion] = useState({
    titulo: "",
    descripcion: "",
    canal: "app",
    fechaDisponible: minDate,
  });
  const { handleSnackbar } = useContext(MessageManager);

  const [addNotificacion] = useMutation(ADD_NOTIFICATION);

  const [usersGroup, setUsersGroup] = useState([]);
  const [expandedUsers, setExpandedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          username
          email
          nombre
          apellido
        }
      }
    `
  );

  const handleSelectUser = (user) => {
    if (selectedUsers.includes(user)) {
      setSelectedUsers(selectedUsers.filter((p) => p !== user));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const handleExpandPantalla = (pantalla) => {
    if (expandedUsers.includes(pantalla)) {
      setExpandedUsers(expandedUsers.filter((p) => p !== pantalla));
    } else {
      setExpandedUsers([...expandedUsers, pantalla]);
    }
  };

  const handleChange = (event) => {
    setNotificacion({
      ...notificacion,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    addNotificacion({
      variables: {
        notificacion: {
          header: notificacion.titulo,
          body: notificacion.descripcion,
          channels: notificacion.canal === "app-email" ? ["app", "email"] : [notificacion.canal],
          programmedAt: dayjs(notificacion.fechaDisponible).format("YYYY-MM-DD HH:mm:00"),
          users: selectedUsers,
        },
      },
    })
      .then(() => {
        handleSnackbar("Notificación creada correctamente", "success");
        handleClose();
      })
      .catch((error) => {
        handleSnackbar("Ha ocurrido un error al crear la notificación", "error");
        handleClose();
      });
  };

  useEffect(() => {
    setNotificacion({
      titulo: "",
      descripcion: "",
      canal: "app",
      fechaDisponible: minDate,
    });
    setSelectedUsers([]);
  }, [open]);

  useEffect(() => {
    if (usuarios) {
      let usersGroup = [];
      usuarios.usuarios.forEach((usuario) => {
        let userGroup = usersGroup.find((ug) => ug.id === usuario.id);
        if (!userGroup) {
          usersGroup.push({
            nombre: usuario.nombre ? `${usuario.nombre} ${usuario.apellido}` : usuario.username,
            id: usuario.id,
            username: usuario.username,
            email: usuario.email,
          });
        }
      });
      setUsersGroup([
        {
          nombre: "Todos los empleados",
          usuarios: usersGroup,
        },
      ]);
    }
  }, [usuarios]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">Nueva notificación</SoftTypography>
            </SoftBox>
            <SoftBox px={3} py={3}>
              <Grid container spacing={2} display={"flex"} alignItems={"flex-start"}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12} md={12} xxl={6}>
                    <InputLabel htmlFor="Título">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Título
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="Título"
                      placeholder="Inserte el título de la notificación"
                      type="text"
                      name="titulo"
                      value={notificacion.titulo}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xxl={3}>
                    <InputLabel htmlFor="Canal">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Canal
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="canal"
                      sx={{ cursor: "pointer" }}
                      value={notificacion.canal}
                      onChange={handleChange}
                    >
                      <MenuItem value={"app"}>App</MenuItem>
                      <MenuItem value={"email"} disabled>
                        Email
                      </MenuItem>
                      <MenuItem value={"app-email"} disabled>
                        Email & App
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} md={6} xxl={3}>
                    <InputLabel htmlFor="Disponible desde">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Disponible desde
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="Disponible desde"
                      type="datetime-local"
                      name="fechaDisponible"
                      value={notificacion.fechaDisponible}
                      onChange={handleChange}
                      inputProps={{ min: minDate }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="descripcion">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Descripción&nbsp;
                        <SoftTypography variant="caption" fontWeight="light">
                          (Opcional)
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="Descripción"
                      type="text"
                      placeholder="Inserte la descripción de la notificación"
                      multiline
                      rows={4}
                      name="descripcion"
                      value={notificacion.descripcion}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={3}
                      pb={0}
                    >
                      <SoftTypography variant="h6">Seleccione los empleados</SoftTypography>
                    </SoftBox>
                    <Card>
                      <SoftBox p={3}>
                        <SoftBox
                          sx={{
                            maxHeight: "30vh",
                            overflowY: "auto",
                          }}
                        >
                          {usersGroup.map((users, index) => (
                            <SoftBox key={index}>
                              <MenuItem>
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    sx={{
                                      width: "100%",
                                    }}
                                    onClick={() => handleExpandPantalla(users.nombre)}
                                  >
                                    <Icon
                                      sx={{
                                        verticalAlign: "middle",
                                        cursor: "pointer",
                                        color: getUproColor("sistemasAmarillo"),
                                      }}
                                      fontSize="lg"
                                    >
                                      {expandedUsers.includes(users.nombre)
                                        ? "expand_more"
                                        : "chevron_right"}
                                    </Icon>
                                    <SoftTypography variant="p" fontWeight="bold">
                                      {users.nombre}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox>
                                    <SoftBadge
                                      color={
                                        users.usuarios.every((p) => selectedUsers.includes(p.id))
                                          ? "sistemasGris"
                                          : users.usuarios.every(
                                              (p) => !selectedUsers.includes(p.id)
                                            )
                                          ? "secondary"
                                          : "sistemasAmarillo"
                                      }
                                      badgeContent={
                                        users.usuarios.every((p) => selectedUsers.includes(p.id))
                                          ? "Enviar a todos"
                                          : users.usuarios.every(
                                              (p) => !selectedUsers.includes(p.id)
                                            )
                                          ? "No enviar"
                                          : "Enviar a algunos"
                                      }
                                      onClick={() => {
                                        if (!expandedUsers.includes(users.nombre)) {
                                          setExpandedUsers([...expandedUsers, users.nombre]);
                                        }

                                        const newSelectedUsers = selectedUsers.filter(
                                          (p) => !users.usuarios.map((p) => p.id).includes(p)
                                        );
                                        if (
                                          users.usuarios.every((p) => selectedUsers.includes(p.id))
                                        ) {
                                          setSelectedUsers(newSelectedUsers);
                                        } else {
                                          setSelectedUsers([
                                            ...newSelectedUsers,
                                            ...users.usuarios.map((p) => p.id),
                                          ]);
                                        }
                                      }}
                                    />
                                  </SoftBox>
                                </SoftBox>
                              </MenuItem>
                              {expandedUsers.includes(users.nombre) &&
                                users.usuarios.map((p, index) => (
                                  <MenuItem key={index}>
                                    <SoftBox
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      ml={3}
                                      sx={{
                                        width: "100%",
                                      }}
                                    >
                                      <SoftBox>
                                        <SoftTypography variant="p">{p.nombre}</SoftTypography>
                                      </SoftBox>
                                      <SoftBox>
                                        <SoftBadge
                                          color={
                                            selectedUsers.includes(p.id) ? "dark" : "secondary"
                                          }
                                          badgeContent={
                                            selectedUsers.includes(p.id) ? "Enviar" : "No enviar"
                                          }
                                          onClick={() => handleSelectUser(p.id)}
                                        />
                                      </SoftBox>
                                    </SoftBox>
                                  </MenuItem>
                                ))}
                            </SoftBox>
                          ))}
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Card>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3}>
              <SoftBox mr={2}>
                <SoftButton variant="gradient" color="dark" onClick={handleClose} circular>
                  Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  onClick={handleSave}
                  disabled={
                    notificacion.titulo === "" ||
                    notificacion.fechaDisponible === "" ||
                    selectedUsers.length === 0 ||
                    notificacion.fechaDisponible === ""
                  }
                  circular
                >
                  Enviar&nbsp;&nbsp;
                  <Icon>send</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalAdd.defaultProps = {
  recordatorio: {
    titulo: "",
    descripcion: "",
    fechaVencimiento: "",
  },
};

ModalAdd.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idUser: PropTypes.string,
};
