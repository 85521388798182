// React
import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import DataTable from "components/Tables/DataTable";
import ModalSolicitud from "layouts/compras/components/ModalSolicitud";
import verificacionesData from "layouts/compras/data/verificacionesData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
  p: 3,
};

export default function ModalSolicitudes({ open, handleClose, setVerificacionesPendientes, idSucursal }) {
  const [openModalNuevaSolicitud, setOpenModalNuevaSolicitud] = useState(false);
  const [rowsVerificacion, setRowsVerificacion] = useState([]);
  const [selectedSolicitud, setSelectedSolicitud] = useState();
  const { columnsVerificacion, getRowsVerificacion } = verificacionesData;
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "createdAt",
    orden: "DESC",
  });

  const [solicitudesChecked, setSolicitudesChecked] = useState([]);

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const {
    data: verificaciones,
    loading: loadingVerificaciones,
    refetch: refetchVerificaciones,
  } = useQuery(
    gql`
      query getSolicitudesVerificacion($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        solicitudesVerificacion(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          estado
          tipo
          user {
            id
            username
          }
          observaciones
          solicitudVerificacionDetalles {
            id
            producto {
              tipo
              id
              codigo
              nombre
              categoria
            }
            cantidadSolicitada
            cantidadVerificada
          }
          createdAt
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "no-cache",
    }
  );

  const handleOpenSolicitud = (solicitud) => {
    setSelectedSolicitud(solicitud);
    setOpenModalNuevaSolicitud(true);
  };

  const handleCheckSolicitud = (solicitud) => {
    if (solicitudesChecked.find((s) => s.id === solicitud.id)) {
      setSolicitudesChecked(solicitudesChecked.filter((s) => s.id !== solicitud.id));
    } else {
      setSolicitudesChecked([...solicitudesChecked, solicitud]);
    }
  };

  useEffect(() => {
    if (verificaciones && verificaciones?.solicitudesVerificacion) {
      setVerificacionesPendientes(
        verificaciones.solicitudesVerificacion.filter((s) => s.estado === "Pendiente").length
      );
      setRowsVerificacion(
        getRowsVerificacion(
          verificaciones.solicitudesVerificacion,
          handleOpenSolicitud,
          handleCheckSolicitud,
          solicitudesChecked.map((s) => s.id)
        )
      );
      setPagesInfo(verificaciones?.paginationInfo);
    }
  }, [verificaciones]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" mb={2}>
            <SoftBox>
              <SoftTypography variant="h6">Solicitudes de verificación</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Tooltip title="Cerrar" placement="top">
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
          <SoftBox>
            <SoftBox display="flex" justifyContent="end" mb={2}>
              <SoftBox
                sx={{
                  width: "100%",
                }}
              >
                <SoftInput
                  placeholder="Escribe aquí..."
                  icon={{ component: "search", direction: "left" }}
                  onChange={handleSearch}
                />
              </SoftBox>
            </SoftBox>
            <SoftBox>
              {loadingVerificaciones ? (
                <Loading />
              ) : verificaciones?.solicitudesVerificacion?.length > 0 ? (
                <DataTable
                  columns={columnsVerificacion}
                  rows={rowsVerificacion}
                  order={orderBy}
                  setOrder={handleOrderBy}
                />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loadingVerificaciones}
              length={verificaciones?.solicitudesVerificacion?.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
            <ModalSolicitud
              open={openModalNuevaSolicitud}
              handleClose={() => {
                setOpenModalNuevaSolicitud(false);
                setSelectedSolicitud();
              }}
              selectedSolicitud={selectedSolicitud}
              refetchSolicitudes={() => {
                refetchVerificaciones();
              }}
              idSucursal={idSucursal}
              editMode
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSolicitudes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setVerificacionesPendientes: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
};
