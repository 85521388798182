// React
import { useContext, useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { formatDate } from "utils/formatters";
import SoftInput from "components/SoftInput";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "80%", sm: "70%", xxl: "30%" },
  overflow: "auto",
  p: 2,
};

export default function Comentarios({ open, handleClose, idMovimiento, setCountComentarios}) {
  const refMessageContainer = useRef();
  const { user } = useContext(UserContext);
  const [comentarios, setComentarios] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const { loading } = useQuery(gql`
    query getComentariosMovimiento($idMovimiento: ID!){
      movimientos(filter: {id: $idMovimiento}){
        id
        comentarios{
          idUser
          emisor
          fecha
          comentario
        }
      }
    }
  `,{
    variables: {idMovimiento},
    onCompleted: (data) => {
      if(data.movimientos.length === 0) return;
      setComentarios(data.movimientos[0].comentarios);
    },
    fetchPolicy: "no-cache",
  });
  const [sendComentario, {loading: loadingSend}] = useMutation(gql`
    mutation addComentarioMovimiento($input: InputMovimientoComentario!){
      addComentarioMovimiento(input: $input){
        idUser
        emisor
        fecha
        comentario
      }
    }
  `, {
    onCompleted: (data) => {
      setComentarios(prev => [...prev, data.addComentarioMovimiento]);
    },
  });

  const scroll = () => {
    if(refMessageContainer.current){
      refMessageContainer.current.scrollTop = refMessageContainer.current.scrollHeight;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      scroll();
    }, 100);
  }, [comentarios, loadingSend, open]);

  useEffect(() => {
    setCountComentarios(comentarios?.length || 0);
  }, [comentarios]);

  const handleSend = () => {
    if(!mensaje || loading || loadingSend) return;
    let currentUser = user.nombre ? `${user.nombre} ${user.apellido}` : user.username;
    let newComentario = {
      emisor: currentUser,
      fecha: formatDate(new Date(), "yyyy-MM-dd hh:mm:ss"),
      comentario: mensaje,
    };
    sendComentario({variables: {input: {idMovimiento, ...newComentario}}});
    setMensaje("");
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox p={2}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Comentarios</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Tooltip title="Cerrar" placement="top">
                  <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">close</Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox ref={refMessageContainer} display="flex" flexDirection="column" gap={2} pr={2} sx={{maxHeight: "40vh", overflowY: "scroll"}} onCompleted={scroll}>
              {comentarios.map((comentario, index) => (
                <SoftBox 
                  key={index}
                  bgColor={comentario.idUser == user.id ? "grey-100" : "grey-200"}
                  borderRadius="lg"
                  p={2}
                  mr={comentario.idUser == user.id ? 0:3}
                  ml={comentario.idUser != user.id ? 0:3}
                >
                  <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox display="flex" alignItems="center" gap={1}>
                      <Icon fontSize="medium">account_circle</Icon>
                      <SoftTypography variant="h6">{comentario.emisor}</SoftTypography>
                    </SoftBox>
                    <SoftBox>
                      <SoftTypography variant="caption">{formatDate(comentario.fecha, "XXXX a las hh:mm")}</SoftTypography>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox ml={4}>
                    <SoftTypography variant="body2">{comentario.comentario}</SoftTypography>
                  </SoftBox>
                </SoftBox>
              ))}
              {comentarios.length === 0 && <SinDatos />}
              {loading || loadingSend && <Loading />}
            </SoftBox>
            <SoftBox display="flex" alignItems="center" gap={2} mt={2}>
              <SoftInput
                placeholder= {comentarios.length <= 10 ? "Escribe un comenterio." : "Limite de mensajes alcanzado"}
                inputProps={{maxLength: 512}}
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                disabled={comentarios.length > 10}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSend();
                  }
                }}
              />
              <Icon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleSend}>send</Icon>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

Comentarios.defaultProps = {
  open: false,
  handleClose: () => {},
  idMovimiento: "",
  setCountComentarios: () => {},
};

Comentarios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idMovimiento: PropTypes.string,
  setCountComentarios: PropTypes.func,
};
