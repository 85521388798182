// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import { Icon, Tooltip } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { useEffect, useState } from "react";

function TableItem({ material, tipo, editMode, handleUpdateMaterial }) {
  const { light } = colors;
  const { borderWidth } = borders;

  const [materialData, setMaterialData] = useState({
    ...material,
    cantidadVerificada: material?.cantidadVerificada,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdateMaterial(materialData);
  }, [materialData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} display="flex">
            <Tooltip title={material.tipo} placement="top">
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color:
                    material.tipo === "Material"
                      ? getUproColor("sistemasAmarillo")
                      : material.tipo === "Consumible"
                      ? getUproColor("sistemasGris")
                      : getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                {material.tipo === "Material"
                  ? "category"
                  : material.tipo === "Consumible"
                  ? "recycling"
                  : "layers"}
              </Icon>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
          {material?.codigo}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign={"middle"}
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.nombre?.length > 70
            ? makeNiceText(material?.nombre).slice(0, 70) + "..."
            : makeNiceText(material?.nombre)}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign={"middle"}
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.categoria ? makeNiceText(material?.categoria) : "Sin rubro"}
        </SoftTypography>
      </SoftBox>
      {tipo === "Solicitud" && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
          sx={{
            verticalAlign: "bottom",
          }}
        >
          <Tooltip
            title={material?.observaciones?.length > 70 ? material?.observaciones : ""}
            placement="top"
          >
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {material?.observaciones
                ? material?.observaciones?.length > 70
                  ? makeNiceText(material?.observaciones).slice(0, 70) + "..."
                  : makeNiceText(material?.observaciones)
                : "Sin observaciones"}
            </SoftTypography>
          </Tooltip>
        </SoftBox>
      )}
      {tipo === "Solicitud" && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"center"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox>
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {`$${formatMoneyPunto(material?.precioEstimado)}`}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {tipo === "Solicitud" && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"center"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox>
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {material?.cantidad}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {tipo === "SolicitudVerificacion" && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"center"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox>
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {material?.cantidadSolicitada}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {tipo === "SolicitudVerificacion" && !editMode && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"center"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox>
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {material?.cantidadVerificada ? (
                material?.cantidadVerificada
              ) : (
                <Tooltip title="Esperando verificación de pañol" placement="top">
                  <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                  color="primary"
                  fontSize="small"
                >
                  schedule
                </Icon>
                </Tooltip>
              )}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {tipo === "SolicitudVerificacion" && editMode && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"center"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox>
            <SoftTypography
              verticalAlign={"middle"}
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {material?.stock?.cantidad}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {tipo === "SolicitudVerificacion" && editMode && (
        <SoftBox
        component="td"
        p={1}
        pr={2}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox>
        <SoftInputNumber
              placeholder="Cantidad"
              name="cantidadVerificada"
              value={materialData?.cantidadVerificada}
              error={materialData?.cantidadVerificada < 0}
              onChange={handleChanges}
            />
        </SoftBox>
      </SoftBox>
      )}
    </TableRow>
  );
}

// Typechecking props of the TableItem
TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  tipo: PropTypes.string,
  editMode: PropTypes.bool,
  handleUpdateMaterial: PropTypes.func,
};

export default TableItem;
