// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import { Icon, Tooltip } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";

function TableItemDetalle({ dataFila,cantidad }) {
  const { light } = colors;
  const { borderWidth } = borders;

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} >
            <Tooltip title={dataFila?.tipo == "concepto" ? "Mano de obra" : "Material"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: dataFila?.tipo == "concepto" ? getUproColor("sistemasGris") :  getUproColor("sistemasAmarillo"),
                }}
                  fontSize="lg"
                >
                  {dataFila?.tipo == "concepto"  ? "inventory_1" : "inventory_2"}
                </Icon>
              </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
        lineHeight={1}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {dataFila?.nombre ? dataFila?.nombre : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {dataFila?.cantidad ? `${dataFila?.cantidad * cantidad} ${dataFila?.producto?.unidadMedida || "u"}` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {dataFila?.cantComprada ? `${dataFila?.cantComprada} ${dataFila?.producto?.unidadMedida || "u"}` : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {dataFila?.precioCompra ?   "$ "+formatMoneyPunto(dataFila?.precioCompra) : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {dataFila?.tipoMonedaCompra ? dataFila?.tipoMonedaCompra : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {dataFila?.cotizacionCompra ? "$ "+formatMoneyPunto(dataFila?.cotizacionCompra) : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {(dataFila?.cantComprada && dataFila?.precioCompra) ? "$ "+formatMoneyPunto(dataFila?.cantComprada * dataFila?.precioCompra) : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <Tooltip
        placement="top"
        title={dataFila?.recibido == dataFila?.cantComprada 
          ? "La cantidad de material recibido es igual a la cantidad ordenada" 
          : dataFila?.recibido < dataFila?.cantComprada 
          ? "La cantidad de material recibido es menor a la cantidad ordenada" 
          : dataFila?.recibido > dataFila?.cantComprada 
          ? "La cantidad de material recibido es mayor a la cantidad ordenada" 
          : "" }
        >
          <SoftTypography 
          verticalAlign={"middle"} 
          variant="caption" 
          color={dataFila?.recibido == dataFila?.cantComprada 
            ? "success" 
            : (dataFila?.recibido < dataFila?.cantComprada || dataFila?.recibido > dataFila?.cantComprada)
            ? "error" 
            : "dark"
          }
          fontWeight="bold"
          >
              {dataFila?.recibido ? dataFila?.recibido+"u" : "-"}
          </SoftTypography>
        </Tooltip>
      </SoftBox>
    </TableRow>
  );
}

// Typechecking props of the TableItemDetalle
TableItemDetalle.propTypes = {
  dataFila: PropTypes.object.isRequired,
  cantidad: PropTypes.number,
 };

export default TableItemDetalle;
