// Components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// @mui material components
import { Card, Icon, Table, TableBody, TableContainer, TableRow, Tooltip,} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { useContext, useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import SoftCircularProgress from "components/SoftCircularProgress";
import ModalOrdenes from "./components/ModalOrdenes";
import SinDatos from "components/Carteles/SinDatos";
import Loading from "components/Carteles/Loading";
import CustomPagination from "components/Pagination";
import { formatMoneyPunto } from "utils/formatters";
import { MenuContext } from "context/menuContext";
import SoftBadge from "components/SoftBadge";
import ModalDelete from "components/Modals/Delete";
import ModalAprobarOrden from "./components/ModalAprobarOrden";
import ModalPagos from "./components/ModalPagos";
import ModalRecepcion from "./components/ModalRecepcion";
import ModalDetalle from "./components/ModalDetalle";

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo de Moneda", align: "left", noOrder: true },
  { name: "precio", desc: "Precio Unitario", align: "left", noOrder: true },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
  { name: "subtotal", desc: "Subtotal", align: "left", noOrder: true },

  { name: "incTotal", desc: "Inc. Total", align: "left",noOrder: true},
  { name: "incParcial", desc: "Inc. Parcial", align: "left",noOrder: true},

  { name: "ant", desc: "Anterior", align: "center", noOrder: true, width: "10%" },
  { name: "act", desc: "Actual", align: "center", noOrder: true, width: "12%" },
  { name: "acm", desc: "Acumulado", align: "center", noOrder: true, width: "10%" },

  { name: "montoAvance", desc: "Monto", align: "center", noOrder: true },
];

function Ordenes() {
    const {menu, handleClose} = useContext(MenuContext);
    const {handleSnackbar} = useContext(MessageManager);
    const [ordenTipo,setOrdenTipo] = useState("");
    const navigate = useNavigate();
    const { id } = useParams();
    const [deleteOrden,setDeleteOrden] = useState(null);
    const [openDelete,setOpenDelete] = useState(false);
    const [modalOrden,setModalOrden] = useState(false);
    const [ordenSelect,setOrdenSelect] = useState(null);
    const [modalAprobarOrden,setModalAprobarOrden] = useState(false);
    const [dataPago,setDataPago] = useState({});
    const [modalPagos,setModalPagos]  = useState(false);
    const [modalRecepcion,setModalRecepcion] = useState(false);
    const [modalDetalle,setModalDetalle] = useState(false);
    
    const [page,setPage] = useState(1);
    const [pagesInfo, setPagesInfo] = useState({
      pages: 0,
      total: 0,
    });  

    const { loading, error, data: dataOrdenes, refetch } = useQuery(
      gql`
        query getOrdenes($search: String, $page: Int, $order: Order, $filter: JSON) {
          paginationInfo {
            pages
            total
          }
          ordenesCompra(
            search: $search
            pagination: { page: $page, limit: 10 }
            order: $order
            filter: $filter
          ) {
            id
            totalOrden
            estado
            tipo
            proveedor{
              id
              tipoProveedor
              razonSocial
              nombre
              apellido
            }
            ordenPago{
              id
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
            }
          }
        }
      `,
      {
        variables: {
          page: page,
          filter: {
            idProyecto: id
          }
        },
        onCompleted: (data) => {
          if (!data) return;
          setPagesInfo({
            pages: data?.paginationInfo.pages,
            total: data?.paginationInfo.total,
          });
        },
        fetchPolicy: "network-only",
      }
    );

    const [deleteOrdenCompra] = useMutation(
      gql`
        mutation deleteOrdenCompra($id: ID!) {
          deleteOrdenCompra(id: $id) {
            id
          }
        }
      `
    );

    const [saveOrdenCompra, { loading: loadingSave }] = useMutation(gql`
      mutation saveOrdenCompra($input: OrdenCompraInput!) {
        saveOrdenCompra(input: $input) {
          id
        }
      }
    `);

    const [getProyecto, { loading: loadingProyecto, error: errorProyecto, data: dataProyecto }] = useLazyQuery(
      gql`
        query getProyecto($id: ID!) {
          proyecto(id: $id) {
            id
            nombre
            sucursal {
              id
            }
          }
        }
    `);

    useEffect(() => {
      if (id) {
        getProyecto({ variables: { id: id } });
      }
    }, [id]);

    const handleDeleteOrden = async (id)=>{
      try {
        await deleteOrdenCompra({ variables: { id } });
        refetch();
        handleSnackbar("Orden de compra eliminada","success");
      } catch (error) {
        console.log(error);
        handleSnackbar("Error al eliminar la orden de compra","error");
      } finally {
        setDeleteOrden(null);
      }
    }

   const handleAprobarOrden = (id)=>{
      const data = {
        id: id,
        estado: "Aprobado"
      }

      saveOrdenCompra({
        variables: {
          input: data
        }
      }).then((res) => {
        handleSnackbar("La orden de compra ha sido aprobada", "success");
        refetch();
      }).catch((err) => {
        console.log(err);
        handleSnackbar("Error al aprobar la orden de compra", "error");
      }).finally(() => {
        setModalAprobarOrden(false);
        setOrdenSelect(null);
      });
    
   }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox pt={3}>
                <SoftBox px={3} pb={3} display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">
                        Ordenes de compra
                    </SoftTypography>

                    <SoftBox display="flex" alignItems="center" gap={1}>
                        <Tooltip title="Volver a proyectos" placement="top">
                          <SoftButton color="sistemasGris" circular onClick={()=>navigate("/proyectos")}>
                            <Icon>arrow_back</Icon>&nbsp;Volver
                          </SoftButton>
                        </Tooltip>
                        <Tooltip title="Agregar orden de compra" placement="top">
                            <SoftButton color="primary" 
                            circular 
                            onClick={(event) => {
                              menu({
                                open: event.currentTarget,
                                align: "right",
                                options: [
                                  {
                                    name: "Materiales",
                                    icon: { icon: "inventory_2", color: "success" },
                                    onClick: () => {
                                      setOrdenTipo("Materiales");
                                      setModalOrden(true);
                                      handleClose();
                                    },
                                  },
                                  {
                                    name: "Mano de Obra",
                                    icon: { icon: "group", color: "warning" },
                                    onClick: () => {
                                      setOrdenTipo("Concepto");
                                      setModalOrden(true);
                                      handleClose();
                                    },
                                  }
                                ],
                                handleClose: () => {},
                              });
                            }}
                            
                            >
                                <Icon>add</Icon>&nbsp;Agregar
                            </SoftButton>
                        </Tooltip>
                    </SoftBox>
                </SoftBox>

                <SoftBox mt={2}>
                  {
                    loading ? (
                      <Loading />
                    ) : dataOrdenes?.ordenesCompra?.length > 0 ? (
                      dataOrdenes?.ordenesCompra?.map((orden)=>{
                        return (
                          <Card sx={{mb:3,mx:3}} key={orden.id}>
                            <SoftBox p={2} display="flex" justifyContent="space-between" alignItems="center">
                              
                              <SoftBox display="flex" alignItems="center">
                                <Tooltip title={orden?.tipo == "Concepto" ? "Mano de Obra" : "Materiales"} placement="top">
                                  <SoftBox
                                    display="flex"
                                    alignItems="center"
                                    mr={2}
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    <SoftCircularProgress
                                      color={
                                        orden?.tipo == "Concepto"
                                          ? "primary"
                                          : "success"
                                      }
                                      icon={
                                        orden?.tipo == "Concepto"
                                          ? "group"
                                          : "inventory_2"
                                      }
                                      value={0}
                                    />
                                  </SoftBox>
                                </Tooltip>
                                <SoftBox lineHeight={1}>
                                  <SoftTypography variant="button">
                                    Orden de Compra Nº {orden.id} - Proveedor {orden?.proveedor?.tipoProveedor == "Empresa" ? orden?.proveedor?.razonSocial : orden?.proveedor?.nombre+" "+orden?.proveedor?.apellido}
                                  </SoftTypography>
                                  <SoftBox display="flex" alignItems="center">
                                    <SoftTypography variant="h5" fontWeight="bold" color={"primary"}>
                                      {orden.totalOrden ? "$ "+formatMoneyPunto(orden.totalOrden) : "$ 0.00"}
                                    </SoftTypography>
                                    <SoftBox ml={1}>
                                      <SoftBadge
                                        badgeContent={orden?.estado}
                                        color={
                                          orden?.estado == "Pendiente"
                                            ? "secondary"
                                            : orden?.estado == "Aprobado"
                                            ? "info"
                                            : orden?.estado == "Pagado"
                                            ? "success"
                                            : orden?.estado == "Finalizado"
                                            ? "dark" 
                                            : null
                                        }
                                      />
                                    </SoftBox>
                                  </SoftBox>
                                </SoftBox>
                              </SoftBox>
  
                              <SoftBox display="flex" alignItems="center" gap={1}>
                                <Tooltip placement="top" title="Ver orden">
                                  <SoftButton
                                    onClick={() => {
                                      setOrdenSelect(orden.id);
                                      setModalDetalle(true);
                                    }}
                                    circular
                                    iconOnly
                                    color="warning"
                                  >
                                    <Icon>visibility</Icon>
                                  </SoftButton>
                                </Tooltip>
                                {
                                  orden?.estado == "Pendiente" && (
                                    <>
                                      <Tooltip placement="top" title="Aprobar orden de compra">
                                        <SoftButton
                                          onClick={() => {
                                            setOrdenSelect(orden.id);
                                            setModalAprobarOrden(true);
                                          }}
                                          circular
                                          iconOnly
                                          color="success"
                                        >
                                          <Icon>check</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                
                                      <Tooltip placement="top" title="Descartar Orden">
                                        <SoftButton 
                                        circular 
                                        iconOnly 
                                        color="error"
                                        onClick={()=>{
                                          setDeleteOrden(orden);
                                          setOpenDelete(true);
                                        }}
                                        >
                                          <Icon>delete</Icon>
                                        </SoftButton>
                                      </Tooltip>
                                    </>
                                  )
                                }
                                {
                                  orden?.estado == "Aprobado" && (
                                    <>
                                      {orden?.tipo == "Materiales" && (
                                        <>
                                          <Tooltip placement="top" title={orden?.ordenPago ? "Ver orden de pago" : "Generar orden de pago"}>
                                            <SoftButton
                                              onClick={() =>{
                                                setDataPago({
                                                  idProyecto: id,
                                                  idProveedor: orden?.proveedor?.id,
                                                  idOrdenCompra: orden?.id,
                                                  totalOrden: orden?.totalOrden,
                                                  ...orden?.ordenPago 
                                                });
                                                setModalPagos(true)
                                              } }
                                              circular
                                              iconOnly
                                              color="info"
                                            >
                                              <Icon>attach_money</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                          <Tooltip placement="top" title="Recepción de materiales">
                                            <SoftButton
                                              onClick={() => {
                                                setOrdenSelect(orden.id);
                                                setModalRecepcion(true);
                                              }}
                                              circular
                                              iconOnly
                                              color="sistemasGris"
                                            >
                                              <Icon>category</Icon>
                                            </SoftButton>
                                          </Tooltip>
                                        </>
                                      )}
                                    </>
                                  )
                                }
                              </SoftBox>  
                            </SoftBox>
                          </Card>
                        )
                      })
                    ) : (
                      <SinDatos/>
                    )
                  } 
                </SoftBox>
                <CustomPagination
                  loading={loading}
                  length={dataOrdenes?.ordenesCompra?.length}
                  total={pagesInfo?.total}
                  pages={pagesInfo?.pages}
                  page={page}
                  setPage={setPage}
                  noCard
                />
            </SoftBox>

            <ModalOrdenes
            open={modalOrden}
            handleClose={()=>{
              setOrdenTipo("");
              setModalOrden(false);
            }}
            refetch={refetch}
            idProyecto={id}
            idSucursal={dataProyecto?.proyecto?.sucursal?.id}
            ordenTipo={ordenTipo}
            />

            <ModalDelete
            open={openDelete}
            handleClose={()=>{
              setOpenDelete(false);
              setDeleteOrden(null);
            }}
            nombre={`la Orden de Compra N° ${deleteOrden?.id}`}
            functionDelete={() => {
              handleDeleteOrden(deleteOrden?.id);
              setOpenDelete(false);
            }}
            />

            <ModalAprobarOrden
            open={modalAprobarOrden}
            handleClose={()=>{
              setOrdenSelect(null);
              setModalAprobarOrden(false);
            }}
            functionAprobar={()=>{
              handleAprobarOrden(ordenSelect);
              setModalAprobarOrden(false);
            }}
            />

            <ModalPagos
            open={modalPagos}
            handleClose={() =>{
              setDataPago({});
              setModalPagos(false)
            }}
            dataPago={dataPago}
            refetch={refetch}
            />
            <ModalRecepcion
            open={modalRecepcion}
            handleClose={() => setModalRecepcion(false)}
            idOrden={ordenSelect}
            />
            <ModalDetalle
            open={modalDetalle}
            handleClose={() => setModalDetalle(false)}
            idOrden={ordenSelect}
            />
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />

    </DashboardLayout>
  );
}

export default Ordenes;
