// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import SoftInput from "components/SoftInput";
import { useEffect, useState } from "react";
import { makeNiceText } from "utils/formatters";
import { Icon, Tooltip } from "@mui/material";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { fromPromise } from "apollo-link";
import { formatMoneyPunto } from "utils/formatters";
import { getUproColor } from "utils/colors";

function TableItem({ material, handleUpdateMaterial, handleRemoveMaterial, disabled }) {
  const { light } = colors;
  const { borderWidth } = borders;

  const [materialData, setMaterialData] = useState({
    ...material,
    cantidad: material?.cantidad || 0,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    handleUpdateMaterial(materialData);
  }, [materialData]);

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} display="flex">
            <Tooltip title={material.tipo} placement="top">
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: material.tipo === "Material"
                  ? getUproColor("sistemasAmarillo")
                  : material.tipo === "Consumible"
                  ? getUproColor("sistemasGris")
                  : getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                {material.tipo === "Material"
                  ? "category"
                  : material.tipo === "Consumible"
                  ? "recycling"
                  : "layers"}
              </Icon>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign="text-top" variant="caption" color="dark" fontWeight="bold">
          {material?.id}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.nombre?.length > 70
            ? makeNiceText(material?.nombre).slice(0, 70) + "..."
            : makeNiceText(material?.nombre)}
          {material?.disabled && (
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="error"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
            >
              &nbsp; (Deshabilitado)
            </SoftTypography>
          )}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.categoria ? makeNiceText(material?.categoria) : "Sin rubro"}
        </SoftTypography>
      </SoftBox>
      {!disabled && (
        <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="text-top"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.stock?.cantidad}
        </SoftTypography>
      </SoftBox>
      )}
      <SoftBox
        component="td"
        p={1}
        pr={disabled ? 3 : 0}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox>
          {disabled ? (
            <SoftTypography
            verticalAlign="text-top"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
              {material?.cantidad}
            </SoftTypography>
          ) : (
            <SoftInputNumber
              placeholder="Cantidad"
              name="cantidad"
              value={materialData?.cantidad}
              error={materialData?.cantidad < 0 || materialData?.cantidad > material?.stock?.cantidad}
              onChange={handleChanges}
            />
          )}
        </SoftBox>
      </SoftBox>
      {!disabled && (
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title="Quitar de la lista" placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                  color="error"
                  fontSize="lg"
                  onClick={() => handleRemoveMaterial(material)}
                >
                  delete
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      )}
    </TableRow>
  );
}

// Typechecking props of the TableItem
TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  handleUpdateMaterial: PropTypes.func.isRequired,
  handleRemoveMaterial: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TableItem;
