import {
    Backdrop,
    Card,
    CircularProgress,
    Collapse,
    Fade,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Skeleton,
    Tooltip,
  } from "@mui/material";
  import PropTypes from "prop-types";
  import { useContext, useEffect, useRef, useState } from "react";
  // Kaizen Dashboard components
  import SoftBox from "components/SoftBox";
  import SoftButton from "components/SoftButton";
  import SoftTypography from "components/SoftTypography";
  import SoftInput from "components/SoftInput";
  import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
  import { MessageManager } from "context";
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90%",
    width: { xs: "90%", sm: "90%", md: "60%", lg: "50%", xl: "40%" },
    py: 4,
    px: 4,
    overflow: "auto",
  };
  
  export default function ModalAddDeposito({
    open,
    handleClose,
    refetch,
    selectDeposito
  }) {
    const { handleSnackbar } = useContext(MessageManager);
    const [deposito, setDeposito] = useState();
    const [edit,setEdit] = useState(false);

    const { data: usuarios } = useQuery(
      gql`
        query getUsuarios {
          usuarios {
            id
            nombre
            apellido
          }
        }
      `
    );

    const { data: provincias } = useQuery(gql`
      query GetProvincias {
        provincias {
          id
          nombre
        }
      }
    `);
    
    const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
      query GetLocalidades($provincia: ID!) {
        localidades(provincia: $provincia) {
          id
          nombre
          provincia {
            id
          }
        }
      }
    `);

    const [saveSucursal,{loading: loadingSave}] = useMutation(gql`
      mutation SaveSucursal($input: SucursalInput!) {
        saveSucursal(input: $input) {
          id
        }
      }
    `);

    useEffect(() => {
      if(selectDeposito){
        setEdit(true);
        setDeposito({
          id: selectDeposito?.id,
          nombre: selectDeposito?.nombre,
          idSede: selectDeposito?.sede?.id,
          administraStock: selectDeposito?.administraStock,
          principal: selectDeposito?.principal,
          mantenimiento: selectDeposito?.mantenimiento,
          tipo: selectDeposito?.tipo,
          direccion: selectDeposito?.direccion,
          localidad: selectDeposito?.localidad?.id,
          provincia: selectDeposito?.localidad?.provincia?.id,
          idResponsable: selectDeposito?.responsable?.id ,
        });
      }else{
        setEdit(false);
        setDeposito({});
      }
    },[selectDeposito,open]);

    useEffect(() => {
        if (deposito?.provincia) {
        getLocalidades({ variables: { provincia: deposito?.provincia } }).then((data) => {
            if (!data?.data?.localidades?.find((localidad) => localidad.id === deposito?.localidad)) {
                setDeposito({
                    ...deposito,
                    localidad: -1,
                });
            }
        });
        }
    }, [deposito?.provincia]);

    const handleChanges = (e) => {
        setDeposito({
          ...deposito,
          [e.target.name]: e.target.value,
        });
    };

    const handleSave = ()=>{
      const dataDeposito = {
        id: deposito?.id ? deposito?.id : null,
        nombre: deposito?.nombre ? deposito?.nombre : null,
        idSede: deposito?.idSede ? deposito?.idSede : 1,
        administraStock: deposito?.administraStock ? deposito?.administraStock : true,
        principal: deposito?.principal ? deposito?.principal : true,
        mantenimiento: deposito?.mantenimiento ? deposito?.mantenimiento : true,
        tipo: deposito?.tipo ? deposito?.tipo : "Pañol",
        direccion: deposito?.direccion ? deposito?.direccion : null,
        idLocalidad: deposito?.localidad ? deposito?.localidad : null,
        idProvincia: deposito?.provincia ? deposito?.provincia : null,
        idResponsable: deposito?.idResponsable ? deposito?.idResponsable : null,
      }

      saveSucursal({ variables: { input: dataDeposito } }).then((data) => {
        handleSnackbar("Deposito guardado correctamente", "success");
        refetch();
        handleClose();
      }).catch((e) => {
        console.log(e);
        handleSnackbar("Ha ocurrido un error al crear el deposito", "error");
      });
    }
    
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Card sx={style}>
                <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox display="flex" gap={1}>
                        <SoftTypography variant="h6">Deposito</SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="space-between">
                        <SoftBox>
                            <Tooltip title="Cerrar" placement="top">
                                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                                close
                                </Icon>
                            </Tooltip>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                sx={{
                  zIndex: 1,
                }}
              >
                <SoftTypography component="label" variant="caption" color="secondary">
                  (*) Campos obligatorios
                </SoftTypography>
               
              </SoftBox>

              <SoftBox py={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                          <InputLabel htmlFor="nombre">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Nombre
                            </SoftTypography>
                            <SoftTypography
                              pl={0.5}
                              component="label"
                              variant="caption"
                              color="secondary"
                            >
                              (*)
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            name="nombre"
                            value={deposito?.nombre || ""}
                            onChange={handleChanges}
                            placeholder="Nombre"
                          />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          <InputLabel htmlFor="direccion">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Dirección
                            </SoftTypography>
                          </InputLabel>
                          <SoftInput
                            name="direccion"
                            value={deposito?.direccion || ""}
                            onChange={handleChanges}
                            placeholder="Dirección"
                          />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="idResponsable">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Responsable
                          </SoftTypography>
                        </InputLabel>
                        <Select
                          fullWidth
                          label="Responsable"
                          name="idResponsable"
                          value={deposito?.idResponsable || -1}
                          onChange={handleChanges}
                        >
                          <MenuItem value={-1} disabled>
                            Seleccione un responsable
                          </MenuItem>
                          {usuarios?.usuarios.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              {user.nombre} {user.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="provincia">
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                    Provincia
                                </SoftTypography>
                            </InputLabel>
                            <Select
                                name="provincia"
                                value={deposito?.provincia || -1}
                                onChange={handleChanges}
                            >
                                <MenuItem value={-1} disabled>
                                Seleccione uno
                                </MenuItem>
                                {provincias?.provincias?.map((provincia) => (
                                <MenuItem key={provincia.id} value={provincia.id}>
                                    {provincia.nombre}
                                </MenuItem>
                                ))}
                            </Select>
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                            <InputLabel htmlFor="localidad">
                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Localidad
                                </SoftTypography>
                            </InputLabel>
                            <Select
                                name="localidad"
                                value={deposito?.localidad || -1}
                                onChange={handleChanges}
                            >
                                <MenuItem value={-1} disabled>
                                Seleccione uno
                                </MenuItem>
                                {localidades?.localidades?.map((localidad) => (
                                <MenuItem key={localidad.id} value={localidad.id}>
                                    {localidad.nombre}
                                </MenuItem>
                                ))}
                            </Select>
                    </Grid>    
                </Grid>
              </SoftBox>
            
              <SoftBox
                display={{
                  xs: "flex-row",
                  md: "flex",
                }}
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
                mt={3}
              >
                <SoftBox>
                  <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    disabled={loadingSave}
                    fullWidth={{
                      xs: true,
                      md: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    md: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                     fullWidth={{
                      xs: true,
                      md: false,
                    }}
                    disabled={
                      !deposito?.nombre
                      || loadingSave
                    }
                    onClick={handleSave}
                  >
                    {loadingSave ? (
                      <>
                        <CircularProgress size={15} color="white" />
                        &nbsp;
                      </>
                    ) : ( 
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </Card>
          </Fade>
        </Modal>
      </>
    );
  }
  
  ModalAddDeposito.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    refetch: PropTypes.func,
    selectDeposito: PropTypes.object,
  };
  