/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip
                title={item?.disabled ? item?.tipo + "deshabilitado" : item?.tipo}
                placement="top"
              >
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: item?.disabled
                      ? getUproColor()
                      : item.tipo === "Material"
                      ? getUproColor("sistemasAmarillo")
                      : item.tipo === "Consumible"
                      ? getUproColor("sistemasGris")
                      : getUproColor("sistemasAmarillo"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipo === "Material" ? "category" : "recycling"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombre.length > 70
                ? makeNiceText(item?.nombre).slice(0, 70) + "..."
                : makeNiceText(item?.nombre)}
              {item?.disabled && (
                <SoftTypography
                  verticalAlign="text-top"
                  variant="caption"
                  color="error"
                  fontWeight="medium"
                  sx={{ cursor: "pointer" }}
                >
                  &nbsp; (Deshabilitado)
                </SoftTypography>
              )}
            </SoftTypography>
          </SoftBox>
        ),
        categoria: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.categoria ? makeNiceText(item?.categoria) : "Sin rubro"}
            </SoftTypography>
          </SoftBox>
        ),
        stock: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.stock?.cantidad || 0}
            </SoftTypography>
          </SoftBox>
        ),
        stockNew: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.cantidad}
            </SoftTypography>
          </SoftBox>
        ),
        diferencia: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftBadge
              color={
                item?.cantidad > (item?.stock?.cantidad || 0)
                  ? "success"
                  : item?.cantidad < (item?.stock?.cantidad || 0)
                  ? "error"
                  : "warning"
              }
              size="xs"
              badgeContent={
                <SoftTypography
                  verticalAlign="text-top"
                  variant="caption"
                  color="white"
                  fontWeight="medium"
                >
                  {item?.cantidad > (item?.stock?.cantidad || 0)
                    ? "+" + (item?.cantidad - (item?.stock?.cantidad || 0))
                    : item?.cantidad < (item?.stock?.cantidad || 0)
                    ? "-" + ((item?.stock?.cantidad || 0) - item?.cantidad)
                    : "0"}
                </SoftTypography>
              }
              container
            />
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left", width: "30%" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "stock", desc: "Stock anterior", align: "center", width: "1%" },
  { name: "stockNew", desc: "Stock nuevo", align: "center", width: "1%" },
  { name: "diferencia", desc: "Diferencia", align: "center", width: "1%" },
];

export default { columns, getRows };
