// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import dayjs from "dayjs";
import { makeNiceText } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "70%" },
  overflow: "auto",
  p: 3,
};

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "cantidadOrdenada", desc: "Cant. Ordenado", align: "left" },
  { name: "cantidadAcumulada", desc: "Cant. Recibida Acumulada", align: "left" },
  { name: "cantidad", desc: "Cant. Recibida", align: "left" },
  { name: "precio", desc: "Precio Unitario", align: "left"},
  { name: "subtotal", desc: "subtotal", align: "left" },
];

function TableItem({ material}) {
    const { light } = colors;
    const { borderWidth } = borders;
  
    return (
      <TableRow hover>
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftBox pl={3} mr={2} >
              <Tooltip title={"Material"} placement="top">
                  <Icon
                    sx={{
                      verticalAlign: "middle",
                      cursor: "pointer",
                      color: getUproColor("sistemasAmarillo"),
                  }}
                    fontSize="lg"
                  >
                    inventory_2
                  </Icon>
                </Tooltip>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
          sx={{
            verticalAlign: "middle",
          }}
        >
          <SoftTypography
            verticalAlign="middle"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {material?.nombre?.length > 70
              ? makeNiceText(material?.nombre).slice(0, 70) + "..."
              : makeNiceText(material?.nombre)}
          </SoftTypography>
        </SoftBox>
        <SoftBox
            component="td"
            p={1}
            textAlign={"left"}
            borderBottom={`${borderWidth[1]} solid ${light.main}`}
            sx={{
              verticalAlign: "middle",
            }}
          >
            <SoftTypography verticalAlign={"middle"} variant="caption"  color="secondary"  fontWeight="bold">
            {material?.cantidadComprada ? `${material?.cantidadComprada} ${material?.unidadMedida || "u"}` : `1u`}
            </SoftTypography>
        </SoftBox>
        <SoftBox
            component="td"
            p={1}
            textAlign={"left"}
            borderBottom={`${borderWidth[1]} solid ${light.main}`}
            sx={{
              verticalAlign: "middle",
            }}
          >
            <SoftTypography verticalAlign={"middle"} variant="caption"  
            color={material?.totalAcumulado > material?.cantidadComprada 
              ? "error" 
              : material?.totalAcumulado < material?.cantidadComprada 
              ? "secondary" 
              : material?.totalAcumulado === material?.cantidadComprada 
              ? "success" 
              : "secondary"}   
            fontWeight="bold">
            {material?.totalAcumulado ? `${material?.totalAcumulado} ${material?.unidadMedida || "u"}` : `1u`}
            </SoftTypography>
        </SoftBox>
        <SoftBox
            component="td"
            p={1}
            textAlign={"left"}
            borderBottom={`${borderWidth[1]} solid ${light.main}`}
            sx={{
              verticalAlign: "middle",
            }}
          >
            <SoftTypography verticalAlign={"middle"} variant="caption"  color="secondary"  fontWeight="bold">
            {material?.cantidad ? `${material?.cantidad} ${material?.unidadMedida || "u"}` : `1u`}
            </SoftTypography>
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
          sx={{
            verticalAlign: "middle",
          }}
        >
          <SoftTypography
            verticalAlign="middle"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            {material?.precio ? "$ " + formatMoneyPunto(material?.precio) : "$ 0.00"}
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component="td"
          p={1}
          textAlign={"left"}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
          sx={{
            verticalAlign: "middle",
          }}
        >
          <SoftTypography
            verticalAlign="middle"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            $
            {material?.precio &&
              material?.cantidad &&
              formatMoneyPunto(material?.cantidad * material?.precio)}
          </SoftTypography>
        </SoftBox>
      </TableRow>
    );
  }

TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  cantidad: PropTypes.number.isRequired,
};

export default function ModalDetalle({
  open,
  handleClose,
  selectedRemito,
}) {
  const [productosRemito, setProductosRemito] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  useEffect(() => {
    setProductosRemito([]);
    if (open) {
      
      let productosCargados = [];
      selectedRemito.movimientoDetalles.forEach((item) => {
        if(selectedRemito?.detalle?.some((element) => element?.producto?.id === item.producto.id)){
          const index = selectedRemito?.detalle?.findIndex((element) => element?.producto?.id === item.producto.id);
          productosCargados.push({
            ...item.producto,
            cantidad: item.cantidad,
            precio: item.precio,
            cantidadComprada: selectedRemito?.detalle[index].cantComprada,
            totalAcumulado: selectedRemito?.detalle[index].totalAcumulado
          });
        }else{
          productosCargados.push({
            ...item.producto,
            cantidad: item.cantidad,
            precio: item.precio,
            cantidadComprada: 0,
            totalAcumulado: 0
          })
        }
      });
      
      setProductosRemito(productosCargados);
      // let productosCargados = selectedRemito.movimientoDetalles.map((item) => ({
      //   ...item.producto,
      //   cantidad: item.cantidad,
      //   precio: item.precio,
      // }));
      // setProductosRemito(productosCargados);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Datos del remito</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="sede">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Deposito de destino
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {
                          selectedRemito?.sucursalDestino?.nombre ? (
                            `${selectedRemito?.sucursalDestino?.sede?.nombre} - ${selectedRemito?.sucursalDestino?.nombre}`
                          ) : (
                            <Skeleton width={100} />
                          )
                        }
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                        <InputLabel htmlFor="proveedor">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Proveedor
                          </SoftTypography>
                        </InputLabel>
                      </SoftBox>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {
                          selectedRemito?.proveedor?.razonSocial ||
                          selectedRemito?.proveedor?.nombre +
                            " " +
                            selectedRemito?.proveedor?.apellido
                        }
                      </SoftTypography>
                    </Grid>    

                    {/* <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="op">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Orden de Pago
                        </SoftTypography>
                      </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito?.ordenPago || "-"}
                        </SoftTypography>  
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel htmlFor="expediente">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Nº de Expediente
                            </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito?.numeroExpediente || "-"}
                        </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel htmlFor="fechaOP">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Fecha de Orden de Pago
                            </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito?.fechaOrdenPago ? dayjs(selectedRemito?.fechaOrdenPago).format("DD/MM/YYYY") : "-"}
                        </SoftTypography>
                    </Grid> */}


                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nroRemito">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nº de Remito
                        </SoftTypography>
                      </InputLabel>
                      <SoftTypography variant="h6" fontWeight="bold">
                        {selectedRemito?.nroRemito ?? "-"}
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel htmlFor="fechaEntrega">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Fecha de Entrega
                            </SoftTypography>
                        </InputLabel>
                        <SoftTypography variant="h6" fontWeight="bold">
                          {selectedRemito?.fechaEntrega  ? dayjs(selectedRemito?.fechaEntrega).format("DD/MM/YYYY") : "-"}
                        </SoftTypography>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                    <SoftBox>
                        <SoftTypography variant="h6">Productos</SoftTypography>
                    </SoftBox>
                    <SoftButton color="sistemasGris" circular>
                      Total:
                      <Icon sx={{ fontWeight: "light" }}>attach_money</Icon>
                        {formatMoneyPunto(
                            productosRemito.reduce((acc, item) => {
                              return acc + (item?.cantidad || 0) * item?.precio;
                            }, 0)
                        )}
                    </SoftButton>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRemito.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                            <TableRow>{renderColumnsSelect}</TableRow>
                        </SoftBox>
                        <TableBody>
                            {productosRemito.map((material) => {
                                return (
                                    <TableItem
                                        key={material.id}
                                        material={material}
                                    />
                                );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDetalle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedRemito: PropTypes.object,
};
