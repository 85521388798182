/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";


const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      let totalManoObra = 0;
      let totalMateriales = 0;
      
      if(item?.manoDeObra.length > 0){
        item?.manoDeObra.forEach((manoDeObra) => {
          totalManoObra += (manoDeObra?.horas * manoDeObra?.precio);
        });
      }

      if(item?.materiales.length > 0){
        item?.materiales.forEach((material) => {
          totalMateriales += (material?.cantidad * material?.precio);
        });
      }

      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasGris"),
                }}
                fontSize="lg"
              >
                construction
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Usuario"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  construction
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.nombre
                ? makeNiceText(item?.nombre, "nombre")
                : item?.username
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombre
                ? makeNiceText(item?.nombre,"nombre")
                : "Sin nombre"}
            </SoftTypography>
          </SoftBox>
        ),
        descripcion: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.descripcion}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.descripcion}
            </SoftTypography>
          </SoftBox>
        ),
        ordenTrabajo: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.ordenTrabajo}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.ordenTrabajo}
            </SoftTypography>
          </SoftBox>
        ),
        nombreUnidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.nombreUnidad}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombreUnidad}
            </SoftTypography>
          </SoftBox>
        ),
        precioBase: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.precioBase}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              $ {formatMoneyPunto(item?.precioBase)}
            </SoftTypography>
          </SoftBox>
        ),
        totalManoObra: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={totalManoObra}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              $ {formatMoneyPunto(totalManoObra)}
            </SoftTypography>
          </SoftBox>
        ),
        totalMateriales: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={totalMateriales}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              $ {formatMoneyPunto(totalMateriales)}
            </SoftTypography>
          </SoftBox>
        ),
        totalPlantilla: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={totalMateriales + totalManoObra}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              $ {formatMoneyPunto(totalMateriales + totalManoObra)}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "descripcion", desc: "Descripción", align: "left" },
  { name: "nombreUnidad", desc: "Unidad", align: "left" },
  { name: "totalManoObra", desc: "Total Mano de Obra", align: "left" },
  { name: "totalMateriales", desc: "Total Materiales", align: "left" },
  { name: "totalPlantilla", desc: "Total Plantilla", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
