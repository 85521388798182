// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Icon,
  Modal,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", lg: "50%",xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalMensajeFinal({ open, handleClose,titulo,descripcion }) {
  const navigate = useNavigate();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>            
              <SoftBox display="flex" flexDirection="column" gap={1.5} justifyContent="center" alignItems="center" pt={1} pb={3}>
                <SoftTypography variant="h4" fontWeight="bold">
                  {/* Presupuesto enviado con éxito! */}
                  {titulo}
                </SoftTypography>
                <SoftTypography variant="caption" fontWeight="medium" color="secondary" textAlign="center" sx={{width: "75%"}}>
                  {/* En proyecto fue creado y enviado correctamente, puedes ver el estado en la sección de &quot;Proyectos&quot;. */}
                  {descripcion}
                </SoftTypography>
              </SoftBox>

              <SoftBox display="flex" justifyContent="center" mt={1}>
                <SoftButton 
                onClick={()=>{
                  navigate("/proyectos");
                  handleClose();
                }} 
                color="sistemasAmarillo"
                circular
                >
                  <Icon>account_tree</Icon>&nbsp;
                  Volver a mis proyectos
                </SoftButton>
              </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalMensajeFinal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
};
