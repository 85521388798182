import { Backdrop, Card, Fade, Grid, Icon, MenuItem, Modal, Select, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Table from "components/Tables/Table";
import materialsData from "./data/materialsData";
import { useState, useEffect } from "react";
import SoftInput from "components/SoftInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  maxHeight: "90%",
  overflowY: "auto",
};

export default function ModalConfirmation({ open, handleClose, list, handleSave }) {
  const [rows, setRows] = useState([]);
  const { columns, getRows } = materialsData;

  useEffect(() => {
    setRows(getRows(list));
  }, [list]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox p={3}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftBox>
                <SoftTypography variant="h6">Ajustar stock</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Tooltip title="Cerrar" placement="top">
                  <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="secondary"
                fontWeight="medium"
                display="block"
              >
                Se van a realizar los siguientes ajustes, está seguro que desea continuar?
              </SoftTypography>
            </SoftBox>
            <SoftBox my={2}>
              <Card>
                <SoftBox
                  sx={{
                    overflow: "auto",
                    maxHeight: "50vh",
                  }}
                >
                  <Table columns={columns} rows={rows} />
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox display="flex" justifyContent="flex-end" mt={2}>
              <SoftBox mr={2}>
                <SoftButton color="primary" circular onClick={handleClose}>
                  <Icon sx={{ fontWeight: "light" }}>arrow_back</Icon>
                  &nbsp;Seguir editando
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton color="sistemasGris" circular onClick={handleSave}>
                  <Icon sx={{ fontWeight: "light" }}>done</Icon>
                  &nbsp;Confirmar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  list: PropTypes.array,
  handleSave: PropTypes.func,
};
