/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto } from "utils/formatters";
import { makeNiceText } from "utils/formatters";


const getRows = (data, handleOpenMenu) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasGris"),
                }}
                fontSize="lg"
              >
                construction
              </Icon>
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Deposito"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasGris"),
                  }}
                  fontSize="lg"
                >
                  home_work
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        nombre: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.nombre
                ? makeNiceText(item?.nombre, "nombre")
                : "Sin nombre"
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nombre
                ? makeNiceText(item?.nombre,"nombre")
                : "Sin nombre"}
            </SoftTypography>
          </SoftBox>
        ),
        direccion: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={
              item?.direccion
                ? makeNiceText(item?.direccion, "nombre")
                : "Sin dirección"
            }
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.direccion
                ? makeNiceText(item?.direccion,"nombre")
                : "Sin dirección"}
            </SoftTypography>
          </SoftBox>
        ),
        descripcion: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.descripcion}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.descripcion}
            </SoftTypography>
          </SoftBox>
        ),
        localidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.localidad ? item?.localidad?.nombre : "Sin especificar"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.localidad ? item?.localidad?.nombre : "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        provincia: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.localidad?.provincia ? item?.localidad?.provincia?.nombre : "Sin especificar"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.localidad?.provincia ? item?.localidad?.provincia?.nombre : "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ),
        responsable: (
          <SoftBox
            sx={{
              cursor: "pointer",
              textWrap: "nowrap",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "250px",
              textOverflow: "ellipsis",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
            data={item?.responsable ? item?.responsable?.nombre : "Sin especificar"}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.responsable ? item?.responsable?.nombre : "Sin especificar"}
            </SoftTypography>
          </SoftBox>
        ), 
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenMenu(e, item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleOpenMenu(e, item)}
              >
                more_vert
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "direccion", desc: "Dirección", align: "left" },
  { name: "localidad", desc: "Localidad", align: "left" },
  { name: "provincia", desc: "Provincia", align: "left" },
  { name: "responsable", desc: "Responsable", align: "left" },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
