// React
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  Checkbox,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { gql, useQuery } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};


export default function ModalFormaPago({ open, handleClose, guardarFormaPago,presupuestoData,tipoMoneda,totalConceptos }) {
    const [formaPago,setFormaPago] = useState({
        fechaPago: "",
        tipoMoneda: "",
        precio: "",
        cotizacion: "",
        medioPago: "",
        observacionPago: "",
        montoAnticipo: 0,
        porcentajeAnticipo: 0,
    });

    useEffect(() => {
        if(open && presupuestoData){
            setFormaPago({
                fechaPago: presupuestoData?.fechaPago ? presupuestoData?.fechaPago : "",
                tipoMoneda: presupuestoData?.tipoMoneda ? presupuestoData?.tipoMoneda : tipoMoneda,
                precio: presupuestoData?.precio ? presupuestoData?.precio : totalConceptos,
                cotizacion: presupuestoData?.cotizacion ? presupuestoData?.cotizacion : (cotizacion?.cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta || (tipoMoneda === "Pesos" ? 1 : 0)),
                medioPago: presupuestoData?.medioPago ? presupuestoData?.medioPago : "",
                observacionPago: presupuestoData?.observacionPago ? presupuestoData?.observacionPago : "",
                montoAnticipo: presupuestoData?.montoAnticipo ? presupuestoData?.montoAnticipo : 0,
                porcentajeAnticipo: presupuestoData?.porcentajeAnticipo ? presupuestoData?.porcentajeAnticipo : 0,
            });
        }
        // else 
        // if(open){
        //     setFormaPago({
        //       ...formaPago,
        //       precio: totalConceptos || 0,
        //       tipoMoneda: tipoMoneda,
        //       cotizacion: cotizacion?.cotizaciones?.find(ctz => ctz?.nombre === tipoMoneda)?.venta || (tipoMoneda === "Pesos" ? 1 : 0),
        //     });
        // }
    },[open,presupuestoData]);

    const resetForm = () => {
        setFormaPago({
            fechaPago: "",
            tipoMoneda: "",
            precio: "",
            cotizacion: "",
            medioPago: "",
            observacionPago: "",
            montoAnticipo: "",
            porcentajeAnticipo: "",
        });
    }

    const handleChange = (event) => {
        setFormaPago({ 
            ...formaPago, 
            [event.target.name]: event.target.value 
        });
    }

    const {data:cotizacion} = useQuery(
        gql`
            query getCotizaciones{
                cotizaciones{
                agencia
                compra
                decimales
                fechaActualizacion
                nombre
                variacion
                variacion
                venta
                }
            }
        `
    );
    
    const calcularMontoAnticipo = (e) => {

        if(e.target.name == "precio" && formaPago?.porcentajeAnticipo > 0){
            setFormaPago({
                ...formaPago,
                precio: e.target.value, 
                montoAnticipo: parseFloat(e.target.value * (formaPago?.porcentajeAnticipo / 100)).toFixed(2)
            });
        }else if(e.target.name == "porcentajeAnticipo" && formaPago?.precio > 0){
            setFormaPago({
                ...formaPago,
                porcentajeAnticipo: e.target.value, 
                montoAnticipo: parseFloat(formaPago?.precio * (e.target.value / 100)).toFixed(2)
            });
        }else {
            setFormaPago({
                ...formaPago,
                [e.target.name]: e.target.value
            });
        }        
    }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleClose();
        // resetForm();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={1}>
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h6" lineHeight={1}>Forma de Pago</SoftTypography>
                <SoftTypography variant="caption" color="secondary">Campos Obligatorios (*)</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                        // resetForm();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4}>
              <SoftBox mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="fechaPago">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Fecha de Pago *
                            </SoftTypography>
                        </InputLabel>
                        <SoftInput
                        name="fechaPago"
                        label="Fecha de Pago"
                        type="date"
                        value={formaPago?.fechaPago || ""}
                        onChange={handleChange} 
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="tipoMoneda">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Tipo de Moneda *
                            </SoftTypography>
                        </InputLabel>
                        <Select
                        name="tipoMoneda"
                        value={formaPago?.tipoMoneda || ""}
                        onChange={(event)=>{
                            setFormaPago({
                                ...formaPago,
                                tipoMoneda: event.target.value,
                                cotizacion: cotizacion?.cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || (event.target.value === "Pesos" ? 1 : 0),
                            })
                        }}
                        >
                            <MenuItem value="Pesos">Pesos</MenuItem>
                            {
                                cotizacion?.cotizaciones?.map((ctz) => {
                                    return (
                                        <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                                            {ctz?.nombre}
                                        </MenuItem>
                                    )
                                }) 
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="precio">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Precio *
                            </SoftTypography>
                        </InputLabel>
                        <SoftInputNumber
                        name="precio"
                        label="Precio"
                        placeholder="Ej: 50000"
                        value={formaPago?.precio || ""}
                        onChange={calcularMontoAnticipo}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="cotizacion">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Cotización *
                            </SoftTypography>
                        </InputLabel>
                        <SoftInputNumber
                        name="cotizacion"
                        label="cotizacion"
                        placeholder="Ej: 1300"
                        value={formaPago?.cotizacion || ""}
                        onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="medioPago">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Medio de Pago *
                            </SoftTypography>
                        </InputLabel>
                        <Select
                         name="medioPago"
                         label="medio de pago"
                         value={formaPago?.medioPago || ""}
                         onChange={handleChange}
                        >
                            <MenuItem value="Cheque">Cheque</MenuItem>
                            <MenuItem value="Debito">Debito</MenuItem>
                            <MenuItem value="Efectivo">Efectivo</MenuItem>
                            <MenuItem value="Transferencia">Transferencia</MenuItem>
                            <MenuItem value="Otros">Otros</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="porcentajeAnticipo">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Porcentaje de Anticipo (%) *
                            </SoftTypography>
                        </InputLabel>
                        <SoftInput
                        name="porcentajeAnticipo"
                        label="porcentajeAnticipo"
                        placeholder="Ej: 10"
                        value={formaPago?.porcentajeAnticipo}
                        onChange={calcularMontoAnticipo}
                        error={formaPago?.porcentajeAnticipo != "" && (formaPago?.porcentajeAnticipo < 0 || formaPago?.porcentajeAnticipo > 100)}
                        type="number"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor="montoAnticipo">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Monto de Anticipo *
                            </SoftTypography>
                        </InputLabel>
                        <SoftInputNumber
                            name="montoAnticipo"
                            label="montoAnticipo"
                            placeholder="Ej: 50000"
                            value={formaPago?.montoAnticipo}
                            onChange={handleChange}
                        />
                    </Grid>  

                    <Grid item xs={12} sm={12}>
                        <InputLabel htmlFor="observacionPago">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                Observación de pago
                            </SoftTypography>
                        </InputLabel>
                        <SoftInput
                            name="observacionPago"
                            label="observacionPago"
                            placeholder="Ej: Observaciones"
                            value={formaPago?.observacionPago || ""}
                            onChange={handleChange}
                            multiline
                            rows={2}
                        />
                    </Grid>  
                </Grid>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      handleClose();
                      // resetForm();
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    onClick={() => {
                        guardarFormaPago({
                          ...formaPago,
                          montoAnticipo: parseFloat(formaPago?.montoAnticipo || 0),
                        });
                    }}
                    disabled={
                        formaPago?.fechaPago == "" ||
                        formaPago?.tipoMoneda == "" ||
                        !formaPago?.precio || 
                        formaPago?.precio < 0 ||
                        !formaPago?.cotizacion || 
                        formaPago?.cotizacion < 0 ||
                        formaPago?.medioPago == "" ||
                        (!formaPago?.montoAnticipo && formaPago?.montoAnticipo != 0) ||
                        formaPago?.montoAnticipo < 0 || 
                        formaPago?.montoAnticipo?.length == 0 ||
                        (!formaPago?.porcentajeAnticipo && formaPago?.porcentajeAnticipo != 0) ||
                        formaPago?.porcentajeAnticipo?.length == 0 ||
                        formaPago?.porcentajeAnticipo < 0 || 
                        formaPago?.porcentajeAnticipo > 100
                    }
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalFormaPago.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarFormaPago: PropTypes.func,
  presupuestoData: PropTypes.object,
  tipoMoneda: PropTypes.string,
  totalConceptos: PropTypes.number
};
