// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import { Checkbox, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/CheckBox";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function Cotizacion({ presupuesto, setPresupuesto, large }) {
  const { data: clientes } = useQuery(
    gql`
      query getClientes {
        clientes {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  const [habilitarNota,setHabilitarNota] = useState(false);

  useEffect(() => {
    if (presupuesto?.idProvincia) {
      getLocalidades({ variables: { provincia: presupuesto?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === presupuesto?.idLocalidad)) {
          let proyectoNew = { ...presupuesto };
          delete proyectoNew.idLocalidad;
          setPresupuesto(proyectoNew);
        }
      });
    }
  }, [presupuesto?.idProvincia]);

  useEffect(() => {
    if (presupuesto?.ocultarFases) {
      setHabilitarNota(presupuesto?.ocultarFases);
    }else{
      setHabilitarNota(false);
    }
  }, [presupuesto?.ocultarFases]);

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={2}>
        <SoftBox>
          <SoftTypography variant="h6">{`Nuevo proyecto - Cotización`}</SoftTypography>
        </SoftBox>
      </SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="nombreContacto">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del contacto
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="nombreContacto"
            placeholder="Ingrese el nombre de la persona de contacto"
            type="text"
            name="nombreContacto"
            value={presupuesto?.nombreContacto}
            onChange={(e) => setPresupuesto({ ...presupuesto, nombreContacto: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="ordenCompra">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Orden de compra
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="ordenCompra"
            placeholder="Ingrese el Nº de la orden de compra"
            type="text"
            name="ordenCompra"
            value={presupuesto?.ordenCompra}
            onChange={(e) => setPresupuesto({ ...presupuesto, ordenCompra: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputLabel htmlFor="nroContrato">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Número de contrato
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="nroContrato"
            placeholder="Ingrese el Nº de contrato"
            type="text"
            name="nroContrato"
            value={presupuesto?.nroContrato}
            onChange={(e) => setPresupuesto({ ...presupuesto, nroContrato: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="fechaInicioEstimada">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Fecha de inicio estimada
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="fechaInicioEstimada"
            type="date"
            name="fechaInicioEstimada"
            value={presupuesto?.fechaInicioEstimada}
            onChange={(e) => setPresupuesto({ ...presupuesto, fechaInicioEstimada: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="fechaFinEstimada">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Fecha de fin estimada
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="fechaFinEstimada"
            type="date"
            name="fechaFinEstimada"
            value={presupuesto?.fechaFinEstimada}
            error={dayjs(presupuesto?.fechaInicioEstimada).isAfter(dayjs(presupuesto?.fechaFinEstimada))}
            onChange={(e) => setPresupuesto({ ...presupuesto, fechaFinEstimada: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="terminosPago">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Términos de pago
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="terminosPago"
            name="terminosPago"
            placeholder="Ingrese los términos de pago"
            value={presupuesto?.terminosPago}
            onChange={(e) => setPresupuesto({ ...presupuesto, terminosPago: e.target.value })}
          />
          {/* <Select
            name="terminosPago"
            value={presupuesto?.terminosPago || -1}
            onChange={(e) => setPresupuesto({ ...presupuesto, terminosPago: e.target.value })}
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            <MenuItem value={"Pago inmediato"}>Pago inmediato</MenuItem>
            <MenuItem value={"Pago a 7 días"}>Pago a 7 días</MenuItem>
            <MenuItem value={"Pago a 15 días"}>Pago a 15 días</MenuItem>
            <MenuItem value={"Pago a 30 días"}>Pago a 30 días</MenuItem>
            <MenuItem value={"Pago a 45 días"}>Pago a 45 días</MenuItem>
            <MenuItem value={"Pago a 60 días"}>Pago a 60 días</MenuItem>
            <MenuItem value={"Pago a 90 días"}>Pago a 90 días</MenuItem>
          </Select> */}
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="importeDeposito">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Anticipo
            </SoftTypography>
          </InputLabel>
          <SoftInputNumber
            label="importeDeposito"
            name="importeDeposito"
            placeholder="Ingrese el importe del anticipo"
            value={presupuesto?.importeDeposito}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            onChange={(e) => setPresupuesto({ ...presupuesto, importeDeposito: e.target.value })}
          />
        </Grid>

        <Grid item xs={12}>
          <SoftBox>
            <Checkbox
            checked={habilitarNota}
            onChange={(e) => {
              setHabilitarNota(e.target.checked);
              setPresupuesto({ 
                ...presupuesto, 
                ocultarFases: e.target.checked,
                notasExtra: ""
              });
            }}
            />
            <SoftTypography component="label" variant="caption" fontWeight="medium">
              Mostrar nota en el presupuesto (Ocultar fases)
            </SoftTypography>
          </SoftBox>
          <InputLabel htmlFor="notasExtra">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nota Presupuesto
            </SoftTypography>
            {habilitarNota && (
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            )}
          </InputLabel>
          <SoftInput
            label="notasExtra"
            placeholder="Ingrese alguna nota"
            type="text"
            multiline
            rows={4}
            name="notasExtra"
            value={presupuesto?.notasExtra}
            onChange={(e) => setPresupuesto({ ...presupuesto, notasExtra: e.target.value })}
            disabled={!habilitarNota}
          />
        </Grid>

      </Grid>
    </SoftBox>
  );
}

Cotizacion.propTypes = {
  presupuesto: PropTypes.object,
  setPresupuesto: PropTypes.func,
  large: PropTypes.bool,
};
