import { useState, useContext } from "react";
import { ApolloProvider, useMutation, gql } from "@apollo/client";

// @mui material components
import Card from "@mui/material/Card";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved0.jpg";
import errorIcon from "assets/images/error.svg";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { addDocumento, addIncidente } from "layouts/soporte/querys";
import dayjs from "dayjs";

const INSERT_ERROR = gql`
  mutation addErrorLog($errorLogInput: ErrorLogInput!) {
    addErrorLog(input: $errorLogInput) {
      id
    }
  }
`;

function ErrorHandler() {
  const [errorMsg, setErrorMsg] = useState("");
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);

  let error = JSON.parse(localStorage.getItem("error"));

  const handleAddIncidente = async () => {
    setLoading(true);
    await addIncidente({
      idProyecto: "13",
      idSolicitante: 15,
      tarea: "Reporte de error del sistema (AUTO)",
      descripcionTarea: `Mensaje del usuario: ${errorMsg} / Error generado por el sistema: ${error.descripcion}: ${error.error}`,
      idPantalla: -1,
      prioridadSolicitanteUser: "Alta",
      estadoTarea: "Incidente",
      estadoTicketUser: "Nuevo",
      idUsuarioInterno: user?.id,
      nombreUsuarioInterno: user?.username,
      emailInterno: user?.email,
      fechaSprint: dayjs().format("YYYY-MM-DD"),
      dateAdd: dayjs().format("YYYY-MM-DD"),
    })
      .then((response) => {
        handleSnackbar(response.mensaje, response.estado ? "success" : "error");
        setLoading(false);
        localStorage.removeItem("error");
        window.location.href = "/";
      })
      .catch((error) => {
        handleSnackbar("Error al agregar el incidente, intente nuevamente más tarde", "error");
        setLoading(false);
      });
  };

  return (
    <BasicLayout
      title="Ha ocurrido un error"
      description="Nuestro equipo de soporte está trabajando para solucionarlo"
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <img style={{ height: "200px" }} src={errorIcon}></img>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form">
            <SoftBox px={3} mb={1} textAlign="center">
              <SoftTypography variant="h5" fontWeight="medium">
                Agrega una descripción del error
              </SoftTypography>
            </SoftBox>
            <SoftBox my={2}>
              <SoftInput
                variant="outlined"
                name="observaciones"
                placeholder="Describe el problema..."
                multiline
                rows={4}
                value={errorMsg}
                onChange={(e) => setErrorMsg(e.target.value)}
              />
            </SoftBox>
            <SoftBox mt={4}>
              <SoftButton
                color="primary"
                fullWidth
                circular
                disabled={errorMsg.length === 0 || loading}
                onClick={handleAddIncidente}
              >
                Enviar
              </SoftButton>
            </SoftBox>
            <SoftBox mt={2} mb={1}>
              <SoftButton
                color="sistemasGris"
                circular
                fullWidth
                onClick={() => {
                  localStorage.removeItem("error");
                  window.location.href = "/";
                }}
              >
                Volver a inicio
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default ErrorHandler;
