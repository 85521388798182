// React
import { useState, useEffect } from "react";

import PropTypes from "prop-types";

// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import ModalNuevoRemito from "./ModalNuevoRemito";
import SoftButton from "components/SoftButton";
import SinDatos from "components/Carteles/SinDatos";
import { gql, useQuery } from "@apollo/client";
import remitosData from "layouts/productos/data/remitosData";
import Loading from "components/Carteles/Loading";
import Table from "components/Tables/Table-v2";
import DataTable from "components/Tables/DataTable";
import SoftInput from "components/SoftInput";
import CustomPagination from "components/Pagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
  p: 3,
};

export default function ModalSeleccionarMateriales({
  open,
  handleClose,
  idSucursal,
  refetchProductos,
}) {
  const [openModalNuevoRemito, setOpenModalNuevoRemito] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRemito, setSelectedRemito] = useState();
  const { columns, getRows } = remitosData;
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "fechaOrdenPago",
    orden: "DESC",
  });

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const {
    data: remitos,
    loading,
    refetch,
  } = useQuery(
    gql`
      query getMovimientos($filter: JSON, $search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        movimientos(
          filter: $filter
          search: $search
          pagination: { page: $page, limit: 10 }
          order: $order
        ) {
          id
          tipo
          ordenPago
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          fechaEntrega
          fechaOrdenPago
          numeroExpediente
          proveedor {
            id
            razonSocial
            nombre
            apellido
          }
          user {
            id
            username
          }
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
        }
      }
    `,
    {
      variables: {
        filter: {
          tipo: "Remito Proveedor",
          idSucursalDestino: idSucursal,
        },
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const handleOpenRemito = (remito) => {
    setSelectedRemito(remito);
    setOpenModalNuevoRemito(true);
  };

  useEffect(() => {
    if (remitos && remitos?.movimientos) {
      setRows(getRows(remitos.movimientos, handleOpenRemito));
      setPagesInfo(remitos?.paginationInfo);
    }
  }, [remitos]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <Card>
            <SoftBox p={3}>
              <SoftBox display="flex" justifyContent="space-between" mb={2}>
                <SoftBox>
                  <SoftTypography variant="h6">Remitos Proveedores</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between" mb={2}>
                  <SoftBox mr={2}>
                    <SoftInput
                      placeholder="Escribe aquí..."
                      icon={{ component: "search", direction: "left" }}
                      onChange={handleSearch}
                    />
                  </SoftBox>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                {loading ? (
                  <Loading />
                ) : remitos?.movimientos?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
            </SoftBox>
          </Card>
          <CustomPagination
            loading={loading}
            length={remitos?.movimientos.length}
            total={pagesInfo?.total}
            pages={pagesInfo?.pages}
            page={page}
            setPage={setPage}
          />
          <SoftBox>
            <ModalNuevoRemito
              open={openModalNuevoRemito}
              handleClose={() => {
                setOpenModalNuevoRemito(false);
                setSelectedRemito();
              }}
              selectedRemito={selectedRemito}
              idSucursal={idSucursal}
              refetchProductos={() => {
                refetch();
                refetchProductos();
              }}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSeleccionarMateriales.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchProductos: PropTypes.func,
};
