// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import { Icon, Tooltip } from "@mui/material";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { getUproColor } from "utils/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { useEffect, useState } from "react";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

function TableItem({ material,cantidad, handleUpdateMaterial, totalProyecto,disabledPresupuesto }) {
  const { light } = colors;
  const { borderWidth } = borders;
  
  const incidenciaTotal = ()=>{
    const subTotal = material?.horas ? (material?.precio * material?.horas * cantidad) : material?.cantidad ? (material?.precio * material?.cantidad * cantidad) : (material?.precio || 0);
    const total =  parseFloat((subTotal * 100) / totalProyecto).toFixed(2);
    return parseFloat(total || 0);
  }

  const incidenciaParcial = (valorNuevo)=>{
    const porcentajeCompleto = material?.porcentajeAcumulado == 100 ? 100 : (parseFloat(material?.porcentajeAcumulado || 0) + parseFloat(valorNuevo || 0));
    const incidencia = incidenciaTotal();
    const total =  parseFloat((incidencia * porcentajeCompleto) / 100).toFixed(2);
    return parseFloat(total || 0);
  }

  const [materialData, setMaterialData] = useState({
    ...material,
    nuevoPorcentajeAvance: material?.nuevoPorcentajeAvance || 0,
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setMaterialData((prevState) => ({
      ...prevState,
      [name]: parseFloat(value),
      incParcial: incidenciaParcial(value),
      montoAvance: calculoMonto(value),
    }));
  };

  useEffect(() => {
    handleUpdateMaterial(materialData);
  }, [materialData]);

  const calculoMonto = (valorNuevo)=>{
    const subTotal = material?.horas ? (material?.precio * material?.horas * cantidad) : material?.cantidad ? (material?.precio * material?.cantidad * cantidad) : (material?.precio || 0);
    const porcentajeCompleto = material?.porcentajeAcumulado == 100 ? 100 : (parseFloat(material?.porcentajeAcumulado || 0) + parseFloat(valorNuevo || 0));
    const total =  parseFloat((subTotal * porcentajeCompleto) / 100).toFixed(2);
    return parseFloat(total || 0);
  }

  const handleCompletar = ()=>{
    const total = 100 - parseFloat(material?.porcentajeAcumulado || 0);
    setMaterialData((prevState) => ({
      ...prevState,
      nuevoPorcentajeAvance: parseFloat(total),
      incParcial: incidenciaParcial(total),
      montoAvance: calculoMonto(total),
    }));
  }

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} >
            <Tooltip title={material?.horas ? "Mano de obra" : material?.cantidad ? "Material" : "Concepto"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: material?.horas ? getUproColor("sistemasGris") :  material?.cantidad ? getUproColor("sistemasAmarillo") : getUproColor("sistemasNegro"),
                }}
                  fontSize="lg"
                >
                  {material?.horas ? "schedule" : material?.cantidad ? "inventory_2" : "inventory_1"}
                </Icon>
              </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
        lineHeight={1}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.producto?.nombre ? material?.producto?.nombre : material?.nombre}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {material?.horas ? `${material?.horas * cantidad}hs` : material?.cantidad ? `${material?.cantidad * cantidad}u` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.tipoMoneda ||  "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
         {`$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {`$${formatMoneyPunto(material?.cotizacion || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.horas ? `$${formatMoneyPunto(material?.precio * material?.horas * cantidad)}` : material?.cantidad ? `$${formatMoneyPunto(material?.precio * material?.cantidad * cantidad)}` : `$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {incidenciaTotal() >= 0 ? `${incidenciaTotal()}%` : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.incParcial >= 0 ? `${parseFloat(material?.incParcial)}%` : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.porcentajeAcumulado ? `${material?.porcentajeAcumulado}%` : "0.00%"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftBox display="flex" gap={1}>
            <SoftInput
                name="nuevoPorcentajeAvance"
                value={material?.nuevoPorcentajeAvance ?? 0.0}
                onChange={(e)=>{
                    if(e.target.value == "" || (e.target.value >= 0 &&
                        e.target.value <= 100 &&
                        ((parseFloat(material?.porcentajeAcumulado || 0) + parseFloat(e.target.value)) <= 100)
                    )){
                        handleChanges(e)
                    }
                }}
                error={material?.nuevoPorcentajeAvance != "" && (material?.nuevoPorcentajeAvance < 0 || material?.nuevoPorcentajeAvance > 100)}
                type="number"
                icon={{
                component: "percent",
                direction: "right",
                }}
                disabled={disabledPresupuesto}
            />
            <Tooltip title="Completar" placement="top">
              <SoftBox>
                  <SoftButton 
                  circular 
                  iconOnly 
                  color="success"
                  disabled={(((material?.porcentajeAcumulado || 0) + (material?.nuevoPorcentajeAvance || 0)) == 100 ) || disabledPresupuesto}
                  onClick={() => handleCompletar(material)}
                  >
                      <Icon>
                          check
                      </Icon>
                  </SoftButton>
              </SoftBox>
            </Tooltip>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
         <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {parseFloat((material?.porcentajeAcumulado || 0) + (material?.nuevoPorcentajeAvance || 0)).toFixed(2) + "%"}
         </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.montoAvance ? `$${formatMoneyPunto(material?.montoAvance)}` : "$0.00"}
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

// Typechecking props of the TableItem
TableItem.propTypes = {
  material: PropTypes.object.isRequired,
  cantidad: PropTypes.number,
  handleUpdateMaterial: PropTypes.func,
  totalProyecto: PropTypes.number,
  disabledPresupuesto: PropTypes.bool
};

export default TableItem;
