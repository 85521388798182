// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import TableItem from "../TableItem";
import SinDatos from "components/Carteles/SinDatos";
import SoftInput from "components/SoftInput";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "75%" },
  overflowY: "auto",
  p: 3,
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true },
  { name: "restante", desc: "Restante", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo Moneda", align: "left", noOrder: true },
  { name: "precio", desc: "Precio Unit.", align: "left", noOrder: true },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
  { name: "subtotalEst", desc: "Subtotal est.", align: "left", noOrder: true },
  
  // { name: "stockDisponible", desc: "Stock", align: "center", noOrder: true },
  // { name: "stockUsado", desc: "Stock a Ocupar", align: "center", noOrder: true , width: "9%"},
  { name: "cantComprada", desc: "Cant. a Comprar", align: "center", noOrder: true , width: "9%"},

  { name: "precioCompra ", desc: "Precio Unit.", align: "center", noOrder: true, width: "9%" },
  { name: "tipoMonedaCompra ", desc: "Tipo Moneda", align: "center", noOrder: true, width: "9%" },
  { name: "cotizacionCompra ", desc: "Cotización", align: "center", noOrder: true , width: "9%"},
  { name: "subtotal", desc: "Subtotal", align: "center", noOrder: true },
];

const columnsConcepto = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "nombre", desc: "Nombre", align: "left", noOrder: true },
  { name: "cantidad", desc: "Cantidad", align: "left", noOrder: true },
  { name: "restante", desc: "Restante", align: "left", noOrder: true },
  { name: "moneda", desc: "Tipo Moneda", align: "left", noOrder: true },
  { name: "precio", desc: "Precio Unit.", align: "left", noOrder: true },
  { name: "cotizacion", desc: "Cotización", align: "left", noOrder: true },
  { name: "subtotalEst", desc: "Subtotal est.", align: "left", noOrder: true },
  { name: "cantComprada", desc: "Cant. a Comprar", align: "center", noOrder: true , width: "9%"},
  { name: "subtotal", desc: "Subtotal", align: "center", noOrder: true },
];

export default function ModalOrdenes({ open, handleClose, refetch, idProyecto,idSucursal,ordenTipo }) {
  const {handleSnackbar} = useContext(MessageManager);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const [infoFases,setInfoFases] = useState([]);
  const [infoOrdenCompra,setInfoOrdenCompra] = useState({});

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          nombre
          apellido
        }
      }
    `
  );
  
  const [getFases, { data: dataFases, loading: loadingFases, error }] = useLazyQuery(gql`
    query getFases($id: ID!, $idSucursal: ID!) {
      proyecto(id: $id) {
        presupuestoAprobadoLast{
          id
          fases{
            id
            nombre
            descripcion
            nombreUnidad
            fechaInicioEstimado
            fechaFinEstimado
            cantidad
            porcentajeGanancia
            subtotal
            numero
            etapa{
              id
              nombre
            }
            materiales {
              id
              producto {
                id
                nombre
                unidadMedida
                stock(idSucursal: $idSucursal){
                  cantidad
                }
              }
              nombre
              cantidad
              tipoMoneda
              precio
              cotizacion
            }
            concepto {
              id
              nombre
              tipoMoneda
              precio
              cotizacion
            }
            presupuesto {
              id
              nombre{
                id
                razonSocial
                nombre
                apellido
              }
              file
              aprobado
              motivoAprobacion
              observaciones
              nroPresupuesto
              fechaPago
              tipoMoneda
              precio
              cotizacion
              medioPago
              observacionPago
              montoAnticipo
              porcentajeAnticipo
            }
           
          }
        }
      }
    }
  `, {
    onCompleted: (data) => {
      if(!data) return;
      let arrayFases = [];
      data?.proyecto?.presupuestoAprobadoLast?.fases.forEach(fase => {
        if(ordenTipo == "Materiales" && fase?.materiales?.length > 0){
          arrayFases.push(fase);
        }else if(ordenTipo == "Concepto" && fase?.concepto?.length > 0 && !fase?.nombreUnidad){
          arrayFases.push(fase);
        }
      });
      arrayFases.sort((a, b) => a?.etapa?.id - b?.etapa?.id);
      setInfoFases(arrayFases);
    },
    fetchPolicy: "no-cache"
  });

  const { data: proveedores, loading } = useQuery(
    gql`
      query getProveedores {
        proveedores {
          id
          razonSocial
          nombre
          apellido
        }
      }
    `
  );

  const [saveOrdenCompra, { loading: loadingSave }] = useMutation(gql`
    mutation saveOrdenCompra($input: OrdenCompraInput!) {
      saveOrdenCompra(input: $input) {
        id
      }
    }
  `);

  const [getOrdenes, { data: dataOrdenes, loading: loadingOrdenes, error: errorOrdenes }] = useLazyQuery(gql`
    query getOrdenes($filter: JSON) {
     ordenesCompra(
        filter: $filter
      ) {
        id
        detalle{
          id
          producto{
            id
          }
          nombre
          stockUsado
          cantComprada
          tipo
        }
      }
    }
  `, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if(open){
      getFases({
        variables: {
          id: idProyecto,
          idSucursal: idSucursal
        }
      });
    }else{
      setSelectedFase(null);
      setProveedorSelected(null);
      setInfoOrdenCompra({});
    }
  }, [open]);
 
  const [selectedFase, setSelectedFase] = useState(null);
  const [proveedorSelected, setProveedorSelected] = useState(null);

  const handleFases = (fase) => {
    //verifico si hay fases guardadas en orden de compra
    getOrdenes({
      variables: {
        filter: {
          idProyecto: idProyecto,
          numeroFase: fase.numero
        }
      }
    }).then((res) => {
      if(res?.data?.ordenesCompra?.length > 0){
        let arrayOrdenes = [];
        res?.data?.ordenesCompra?.forEach((element) => {
          element?.detalle?.forEach((item) => {
            if(item?.tipo === "materiales"){
              if(arrayOrdenes.some((orden) => orden?.producto?.id == item?.producto?.id)){
                let index = arrayOrdenes.findIndex((orden) => orden?.producto?.id == item?.producto?.id);
                arrayOrdenes[index].stockUsado += item?.stockUsado;
                arrayOrdenes[index].cantComprada += item?.cantComprada;
              }else{
                arrayOrdenes.push(item);
              }
            }else{
              if(arrayOrdenes.some((orden) => orden?.nombre === item?.nombre)){
                let index = arrayOrdenes.findIndex((orden) => orden?.nombre === item?.nombre);
                arrayOrdenes[index].stockUsado += item?.stockUsado;
                arrayOrdenes[index].cantComprada += item?.cantComprada;
              }else{
                arrayOrdenes.push(item);
              }
            }
          })
        });

        let arrayFaseMateriales = [];
        let arrayFaseConcepto = [];
        fase?.materiales?.forEach((material) => {
          if(arrayOrdenes.some((orden) => orden?.producto?.id == material?.producto?.id)){
            let materialOrden = arrayOrdenes.find((orden) => orden?.producto?.id == material?.producto?.id);
            const totalOrdenado = ((materialOrden?.cantComprada || 0) + (materialOrden?.stockUsado || 0));
            const totalFase = (material?.cantidad * fase?.cantidad) || 0;
            if((totalOrdenado) < totalFase){
              arrayFaseMateriales.push({
                ...material,
                restante: totalFase - totalOrdenado,
                ordenPrevia: true
              });
            } 
          }else{
            arrayFaseMateriales.push(material);
          }
        });

        fase?.concepto?.forEach((concepto) => {
          if(!arrayOrdenes.some((orden) => orden?.nombre === concepto?.nombre)){
            arrayFaseConcepto.push(concepto);
          }
        });
        setSelectedFase({ ...fase, materiales: arrayFaseMateriales, concepto: arrayFaseConcepto });
        
      }else{
        setSelectedFase(fase);
      }
    }).catch((err) => {
      console.log("error",err);
    });
  }

  const renderColumns = (columns) => {
    return columns.map(({ name, desc, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 1;
        pr = 1;
      } else if (key === columns.length - 1) {
        pl = 1;
        pr = 1;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 1}
          pr={align === "right" ? pr : 1}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {desc ? desc.toUpperCase() : name.toUpperCase()}
        </SoftBox>
      );
    });
  };

  const validarOrden = () => {
    return (
      !proveedorSelected ||
      proveedorSelected <= 0 ||
      !selectedFase ||
      (selectedFase?.materiales?.every((material) => (!material.stockUsado && !material.cantComprada && !material.precioCompra && !material.tipoMonedaCompra && !material.cotizacionCompra)) &&
      selectedFase?.concepto?.every((concepto) => (!concepto.cantComprada && !concepto.precioCompra && !concepto.tipoMonedaCompra && !concepto.cotizacionCompra)) ) ||
      (!validarMateriales() &&
      !validarConceptos())
    );
  }

  const validarMateriales = ()=>{
    let contadorFilasValidas = 0;
    for(let mat of selectedFase?.materiales){
      if((mat?.stockUsado || mat?.cantComprada) && mat?.precioCompra && mat?.tipoMonedaCompra && mat?.cotizacionCompra){
        contadorFilasValidas++;
      }  
    }
    return contadorFilasValidas > 0 
  }
  const validarConceptos = ()=>{
    let contadorFilasValidas = 0;
    for(let con of selectedFase?.concepto){
      // if(con?.cantComprada && con?.precioCompra && con?.tipoMonedaCompra && con?.cotizacionCompra){
      if(con?.cantComprada){
        contadorFilasValidas++;
      }  
    }
    return contadorFilasValidas > 0
  }

  const filasValidas = (tipo)=>{
    let array = [];
    if(tipo == "materiales"){
      for(let mat of selectedFase?.materiales){
        if((mat?.stockUsado || mat?.cantComprada) && mat?.precioCompra && mat?.tipoMonedaCompra && mat?.cotizacionCompra){
          delete mat.__typename;
          array.push({
            tipo: "materiales",
            idProducto: mat?.producto?.id ? mat?.producto?.id : null,
            nombre: mat?.producto?.nombre ? mat?.producto?.nombre : null,
            cantidad: mat?.cantidad ?? null,
            precio: mat?.precio ? parseFloat(mat?.precio) : null,
            tipoMoneda: mat?.tipoMoneda ? mat?.tipoMoneda : null,
            cotizacion: mat?.cotizacion ? parseFloat(mat?.cotizacion) : null,

            stockUsado: mat?.stockUsado ? Number(mat?.stockUsado) : null,
            cantComprada: mat?.cantComprada ? Number(mat?.cantComprada) : null,
            precioCompra: mat?.precioCompra ? parseFloat(mat?.precioCompra) : null,
            tipoMonedaCompra: mat?.tipoMonedaCompra ? mat?.tipoMonedaCompra : null,
            cotizacionCompra: mat?.cotizacionCompra ? parseFloat(mat?.cotizacionCompra) : null,
          });
        }  
      }
    }else if(tipo == "concepto"){
      for(let con of selectedFase?.concepto){
        // if(con?.cantComprada && con?.precioCompra && con?.tipoMonedaCompra && con?.cotizacionCompra){
        if(con?.cantComprada){
          delete con.__typename;
          array.push({
            tipo: "concepto",
            idProducto: null,
            nombre: con?.nombre ? con?.nombre : null,
            cantidad: null,
            precio: con?.precio ? parseFloat(con?.precio) : null,
            tipoMoneda: con?.tipoMoneda ? con?.tipoMoneda : null,
            cotizacion: con?.cotizacion ? parseFloat(con?.cotizacion) : null,

            stockUsado: null,
            cantComprada: con?.cantComprada ? Number(con?.cantComprada) : null,
            precioCompra: con?.precio ? parseFloat(con?.precio) : null,
            tipoMonedaCompra: con?.tipoMoneda ? con?.tipoMoneda : null,
            cotizacionCompra: con?.cotizacion ? parseFloat(con?.cotizacion) : null,
          });
        }  
      }
    }

    return array;
  }
 
  const handleSave = async () => {

    const arrayConcepto = filasValidas("concepto");
    const arrayMateriales = filasValidas("materiales");

    if(arrayConcepto.length != 0 || arrayMateriales.length != 0){
      const data = {
        ...infoOrdenCompra,
        estado: "Pendiente",
        tipo: ordenTipo,
        totalOrden: parseFloat(totalOrdenCompra()),
        idProyecto: idProyecto,
        idProveedor: proveedorSelected,
        numeroFase: selectedFase?.numero,
        observacion: null,
        detalle: [...arrayConcepto, ...arrayMateriales],
      }
      
      saveOrdenCompra({
        variables: {
          input: data
        }
      }).then((res) => {
        handleSnackbar("Orden de compra guardada correctamente", "success");
        handleClose();
        refetch();
      }).catch((err) => {
        console.log(err);
        handleSnackbar("Error al guardar orden de compra", "error");
      });
    }else{
      handleSnackbar("No hay filas válidas", "error");
    }

  }

  const totalOrdenCompra = ()=>{
    let total = 0;
    for(let con of selectedFase?.concepto){
      if(con?.cantComprada && con?.precio){
        total += con?.cantComprada * con?.precio;
      }  
    }
    for(let mat of selectedFase?.materiales){
      if( mat?.cantComprada && mat?.precioCompra){
        total += (mat?.cantComprada) * mat?.precioCompra;
      }  
    }
    return parseFloat(total).toFixed(2);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" pb={1}>
              <SoftBox lineHeight={1}>
                <SoftTypography variant="h6" lineHeight={1}>
                    Orden de compra
                </SoftTypography>
                <SoftTypography variant="caption" color="secondary">
                  Campos obligatorios (*)
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                  <InputLabel htmlFor="responsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Fecha 
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                  name="fechaOrden"
                  value={infoOrdenCompra?.fechaOrden || ""}
                  onChange={(e) => setInfoOrdenCompra({ ...infoOrdenCompra, fechaOrden: e.target.value })}
                  type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <InputLabel htmlFor="responsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Etapa y Fase
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="fase"
                    value={selectedFase?.id || -1}
                    onChange={(e)=>{
                      const fase = infoFases.find((fase) => fase.id === e.target.value);
                      handleFases(fase);
                      if(ordenTipo === "Concepto"){
                        if(fase?.presupuesto?.some((pres) => pres?.aprobado === true)){
                          const idProveedorAprobado = fase?.presupuesto?.find((pres) => pres?.aprobado === true)?.nombre?.id;
                          setProveedorSelected(idProveedorAprobado);
                        }else{
                          setProveedorSelected(-2);
                        }
                      }
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione una fase
                    </MenuItem>
                    {infoFases.map((fase, index) => (
                      <MenuItem
                        key={index}
                        value={fase.id}
                      >
                        {fase?.etapa?.nombre} - {fase.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <InputLabel htmlFor="responsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Proveedor
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    fullWidth
                    label="proveedor"
                    value={proveedorSelected || -1}
                    onChange={(e)=>{
                      setProveedorSelected(e.target.value);
                    }}
                    disabled={ordenTipo == "Concepto"}
                  >
                    {
                      ordenTipo == "Concepto" && (
                        <MenuItem value={-2} disabled>
                          Sin proveedor - Falta aprobar presupuesto
                        </MenuItem>
                      )
                    }
                    <MenuItem value={-1} disabled>
                      Seleccione un proveedor
                    </MenuItem>
                    {proveedores?.proveedores?.map((proveedor, index) => (
                      <MenuItem
                        key={index}
                        value={proveedor.id}
                      >
                        {proveedor.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox py={3}>
              <TableContainer>
                <Table>
                  <SoftBox component="thead">
                    <TableRow>
                      {
                        ordenTipo == "Concepto" ? renderColumns(columnsConcepto) : renderColumns(columns)
                      }
                      {/* {renderColumns(columns)} */}
                    </TableRow>
                  </SoftBox>  
                  <TableBody>
                    {
                      (ordenTipo == "Materiales" && selectedFase?.materiales?.length > 0) && (
                        selectedFase?.materiales?.map((material, key) => {
                            return (
                              <TableItem
                              key={material?.id}
                              material={material}
                              cantidad={selectedFase?.cantidad}
                              handleUpdateMaterial={(modMaterial)=>{
                                const modifMaterial = selectedFase?.materiales?.map((mat) => {
                                  if (mat.id === modMaterial.id) {
                                    return modMaterial
                                  } else {
                                    return mat
                                  }
                                });
                                setSelectedFase({...selectedFase, materiales: modifMaterial})
                              }}
                              cotizaciones={cotizacion?.cotizaciones}
                              ordenTipo={ordenTipo}
                              />
                            )
                        })
                      )
                    }
                    {
                      (ordenTipo == "Concepto" && selectedFase?.concepto?.length > 0) && (
                        selectedFase?.concepto?.map((concepto, key) => {
                          return (
                            <TableItem
                            key={concepto?.id}
                            material={concepto}
                            cantidad={selectedFase?.cantidad}
                            handleUpdateMaterial={(modConcepto)=>{
                              const modifConcepto = selectedFase?.concepto?.map((con) => {
                                if (con.id === modConcepto.id) {
                                  return modConcepto
                                } else {
                                  return con
                                }
                              });
                              setSelectedFase({...selectedFase, concepto: modifConcepto})
                            }}
                            cotizaciones={cotizacion?.cotizaciones}
                            ordenTipo={ordenTipo}
                            />
                          )
                        })
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
              {
                !selectedFase ? (
                  <SinDatos/>
                ) : selectedFase?.[ordenTipo.toLowerCase()]?.length == 0 ? (
                  <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
                    <Icon fontSize="large">
                      {ordenTipo == "Concepto" ? "group" : "inventory_2"}
                    </Icon>
                    <SoftTypography variant="h6" >
                      {ordenTipo == "Concepto" ? "No hay Mano de Obra" : "No hay Materiales"}
                    </SoftTypography>
                    <SoftTypography  variant="caption" component="p" color="text">
                      Todos los elementos de esta fase ya han sido ordenados  
                    </SoftTypography>
                  </SoftBox>
                ) : null
              }
              <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                <SoftTypography variant="h5" fontWeight="bold">
                  Total:&nbsp;
                </SoftTypography>
                <SoftTypography variant="h5" fontWeight="bold">
                  {(!selectedFase || (selectedFase?.materiales?.length == 0 && selectedFase?.concepto?.length == 0)) ? "$ 0.00" : "$ " + formatMoneyPunto(totalOrdenCompra())}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  disabled={loadingSave}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="sistemasGris"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                onClick={handleSave}
                disabled={validarOrden() || loadingSave}
                >
                  {loadingSave ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalOrdenes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  idProyecto: PropTypes.string,
  idSucursal: PropTypes.string,
  ordenTipo: PropTypes.string,
};
