// React
import { gql, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Badge,
  Card,
  Fade,
  Grid,
  Icon,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import TableItem from "./TableItem";
import DinamicCard from "components/Cards/InfoCards/DinamicCard";
import { formatDate } from "utils/formatters";
import Comentarios from "./Comentarios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "80%", xxl: "50%" },
  overflow: "auto",
  p: 3,
};

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%" },
  { name: "id", desc: "ID", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "enviado", desc: "Enviado", align: "right" },
  { name: "recibido", desc: "Recibido", align: "right" },
  { name: "check", desc: " ", align: "right", width: "1%" },
];

export default function ModalVerDetalle({
  open,
  handleClose,
  movimiento,
  idSucursal,
  refetch,
}) {
  const [productosRecepcion, setProductosRecepcion] = useState([]);
  const [openComentarios, setOpenComentarios] = useState(false);
  const [countComentarios, setCountComentarios] = useState(0);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const estados = ["Pendiente", "Diferencias", "Finalizado", "Revisado"];
  const colorsArray = ["warning", "error", "success", "info"];
  const icons = ["hourglass_empty", "error", "check_circle", "check_circle"];
  const [updateEstado, { loading: loadingUpdateEstado }] = useMutation(gql`
    mutation updateEstadoMovimiento($id: ID!, $estado: String!) {
      updateEstadoMovimiento(id: $id, estado: $estado) {
        id
      }
    }
  `);

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 1;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  useEffect(() => {
    if (movimiento && open) {
      let productosCargados = movimiento.movimientoDetalles
        .map((item) => ({
          ...item.producto,
          id: item.producto.id,
          enviado: -item.cantidad,
          recibido: movimiento.recepcion?.movimientoDetalles?.find(
            (md) => md.producto.id == item.producto.id
          )?.cantidad,
        }));
      setProductosRecepcion(productosCargados);
    }
  }, [open, movimiento]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">Detalle de la transferencia</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon sx={{ cursor: "pointer" }} onClick={handleClose} fontSize="medium">close</Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <Grid container p={3} pb={1} rowSpacing={1}>
                  <Grid item xs={12} md={5.5}>
                    <DinamicCard
                      title={movimiento?.sucursalOrigen?.sede?.nombre + " - " + movimiento?.sucursalOrigen?.nombre}
                      data={{
                        "Movimiento": movimiento?.id,
                        "usuario": movimiento?.user?.nombre ? `${movimiento?.user?.nombre} ${movimiento?.user?.apellido}` : movimiento?.user?.username,
                        "fecha": formatDate(movimiento?.createdAt, "dd/MM/yyyy hh:mm"),
                        "Observación": movimiento?.observaciones,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={1} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                    <SoftBox
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <Tooltip title={movimiento?.estado}>
                        <Icon
                          fontSize="medium"
                          color={colorsArray[estados.indexOf(movimiento?.estado)]}
                        >
                          {icons[estados.indexOf(movimiento?.estado)]}
                        </Icon>
                      </Tooltip>
                    </SoftBox>
                    <SoftBox display={{ xs: "none", md: "block" }}><Icon fontSize="medium" color="dark" >arrow_forward</Icon></SoftBox>
                    <SoftBox display={{ xs: "block", md: "none" }}><Icon fontSize="medium" color="dark" >arrow_downward</Icon></SoftBox>
                  </Grid>
                  <Grid item xs={12} md={5.5}>
                    <DinamicCard
                      title={movimiento?.sucursalDestino?.sede?.nombre + " - " + movimiento?.sucursalDestino?.nombre}
                      data={{
                        "Movimiento": movimiento?.recepcion?.id || "",
                        "usuario": movimiento?.recepcion?.user?.nombre ? `${movimiento?.recepcion?.user?.nombre} ${movimiento?.recepcion?.user?.apellido}` : movimiento?.recepcion?.user?.username,
                        "fecha": formatDate(movimiento?.recepcion?.createdAt, "dd/MM/yyyy hh:mm") || "",
                      }}
                    >
                      {!movimiento?.recepcion ? (
                        <SoftBox display="flex" justifyContent="center">
                          <Tooltip title="No recepcionado">
                            <Icon>local_shipping</Icon>
                          </Tooltip>
                        </SoftBox>
                      ) : null}
                    </DinamicCard>
                  </Grid>
                </Grid>
                <SoftBox display="flex" justifyContent="flex-end" px={3} pb={1}>
                  <SoftBox>
                    {["Revisado", "Diferencias"].includes(movimiento?.estado) ? (
                      <SoftButton variant="text" color="dark" disabled={loadingUpdateEstado} onClick={() => {
                        let estado = movimiento?.estado == "Diferencias" ? "Revisado" : "Diferencias";
                        updateEstado({ variables: { id: movimiento.id, estado } }).then(() => {
                          refetch();
                        })
                      }}>
                        {movimiento?.estado == "Diferencias" ? (
                          <>
                            <Icon>check_circle</Icon>
                            &nbsp;Marcar como revisado
                          </>
                        ) : (
                          <>
                            <Icon>error</Icon>
                            &nbsp;Marcar con diferencias
                          </>
                        )}
                      </SoftButton>
                    ) : null}
                    <SoftButton variant="text" color="dark" onClick={() => window.open(`productos/pdf/${movimiento?.id}`)}>
                      <Icon>launch</Icon>
                      &nbsp;Descargar remito
                    </SoftButton>
                    <SoftButton variant="text" color="dark" onClick={() => setOpenComentarios(true)}>
                      <Badge color="primary" variant="dot" invisible={countComentarios < 1}>
                        <Icon>comments</Icon>
                      </Badge>
                      &nbsp;comentarios
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosRecepcion.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>{renderColumnsSelect}</TableRow>
                        </SoftBox>
                        <TableBody>
                          {productosRecepcion.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                disabled={false}
                                handleUpdateMaterial={() => { }}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
            </SoftBox>
            {movimiento?.id ? (
              <Comentarios
                open={openComentarios}
                handleClose={() => setOpenComentarios(false)}
                idMovimiento={movimiento?.id}
                setCountComentarios={setCountComentarios}
              />
            ) : null}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerDetalle.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  movimiento: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetch: PropTypes.func,
};
