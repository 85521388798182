// React
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import SinResultados from "components/Carteles/SinResultados";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import { getUproColor } from "utils/colors";
import TableItem from "./TableItem";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "codigo", desc: "Código", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidad", desc: "cantidad", align: "center", width: "100px" },
  { name: "eliminar", desc: " ", align: "left", width: "1%", noOrder: true },
];

const columnsSelect = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "codigo", desc: "Código", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidad", desc: "Solicitado", align: "center" },
  { name: "cantidadEntregada", desc: "Entregado", align: "center" },
];

export default function ModalNuevaSolicitud({
  open,
  handleClose,
  selectedSolicitud,
  idSucursal,
  refetchSolicitudes,
}) {
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [productoToAdd, setProductoToAdd] = useState();
  const [solicitud, setSolicitud] = useState();
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [productosSolicitud, setProductosSolicitud] = useState([]);
  const [productosBase, setProductosBase] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const [sucursales, setSucursales] = useState([]);
  const { user } = useContext(UserContext);
  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          categoria
          nombre
          disabled
          stock(idSucursal: $idSucursal) {
            cantidad
            sucursal {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );
  const [saveSolicitud, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveSolicitud($input: SolicitudInput!) {
        saveSolicitud(input: $input) {
          id
        }
      }
    `
  );

  const { data: sucursalesData } = useQuery(
    gql`
      query getSucursales {
        sucursales {
          id
          nombre
          sede {
            id
            nombre
          }
        }
      }
    `
  );

  const [saveMovimiento, { loading: loadingSaveMovimiento }] = useMutation(
    gql`
      mutation saveMovimiento($input: MovimientoInput!) {
        saveMovimiento(input: $input) {
          id
        }
      }
    `
  );

  const handleChanges = (event) => {
    setSolicitud({ ...solicitud, [event.target.name]: event.target.value });
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _or: [
              {
                codigo: { _like: `%${event.target.value}%` },
              },
              {
                nombre: { _like: `%${event.target.value}%` },
              },
              {
                categoria: { _like: `%${event.target.value}%` },
              },
            ],
            _stock: {
              cantidad: { _gte: 1 },
              idSucursal: idSucursal,
            },
            idSucursal: idSucursal,
          },
        },
      });
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const handleUpdateProductoSolicitud = (material) => {
    setProductosSolicitud(
      productosSolicitud.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const handleAddProduct = (product) => {
    setProductosSolicitud([...productosSolicitud, product]);
  };

  const handleRemoveProduct = (product) => {
    setProductosSolicitud(productosSolicitud.filter((item) => item.id !== product.id));
  };

  const renderColumnsBase = columnsBase.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsBase.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 1;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  useEffect(() => {
    setSolicitud();
    setProductosSolicitud([]);
    if (selectedSolicitud && open) {
      if (selectedSolicitud?.vencimiento) {
        setSolicitud({
          vencimiento: selectedSolicitud.vencimiento,
        });
      }
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            _stock: {
              cantidad: { _gte: 1 },
              idSucursal: idSucursal,
            },
          },
          idSucursal: idSucursal,
        },
      }).then((data) => {
        let movimientos = [];

        if (selectedSolicitud?.movimiento?.movimientoDetalles) {
          if (selectedSolicitud.tipo === "Prestamo") {
            movimientos = selectedSolicitud.movimiento.movimientoDetalles.map((item) => ({
              ...item,
              cantidad: item.cantidad * -1,
            }));
          } else {
            movimientos = selectedSolicitud.movimiento.movimientoDetalles.filter(
              (item) => item.cantidad > 0
            );
          }
        }

        let productosCargados = selectedSolicitud.solicitudDetalles
          .filter((item) => item.cantidad > 0)
          .map((item) => ({
            ...item.producto,
            cantidad: item.cantidad,
            cantidadEntregada:
              movimientos.find((movimiento) => movimiento.producto.id === item.producto.id)
                ?.cantidad || 0,
            stock: {
              cantidad: data.data.productos.find((producto) => producto.id === item.producto.id)
                ? data.data.productos.find((producto) => producto.id === item.producto.id).stock
                    ?.cantidad
                : 0,
            },
          }));

        movimientos.forEach((movimiento) => {
          if (!productosCargados.find((producto) => producto.id === movimiento.producto.id)) {
            productosCargados.push({
              ...movimiento.producto,
              cantidad: 0,
              cantidadEntregada: movimiento.cantidad,
              stock: {
                cantidad: data.data.productos.find(
                  (producto) => producto.id === movimiento.producto.id
                )
                  ? data.data.productos.find((producto) => producto.id === movimiento.producto.id)
                      .stock?.cantidad
                  : 0,
              },
            });
          }
        });

        setProductosBase(productosCargados);
        setProductosSolicitud(productosCargados);
      });
    }
  }, [open]);

  const handleTransferir = () => {
    let detalles = productosSolicitud.map((item) => ({
      idProducto: item.id,
      idSucursal: idSucursal,
      cantidad: -item.cantidad,
    }));
    detalles = detalles.concat(
      productosSolicitud.map((item) => ({
        idProducto: item.id,
        idSucursal: selectedSolicitud.sucursal.id,
        cantidad: item.cantidad,
      }))
    );
    saveMovimiento({
      variables: {
        input: {
          tipo: "Transferencia",
          movimientoDetalle: detalles,
          idSucursalOrigen: idSucursal,
          idSucursalDestino: selectedSolicitud.sucursal.id,
          observaciones: solicitud?.observaciones || null,
        },
      },
    })
      .then((data) => {
        saveSolicitud({
          variables: {
            input: {
              id: selectedSolicitud.id,
              estado: "Finalizado",
              idMovimiento: data.data.saveMovimiento.id,
            },
          },
        })
          .then(() => {
            handleSnackbar("La transferencia se ha realizado correctamente", "success");
            handleClose();
            refetchSolicitudes();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al realizar la transferencia", "error");
          });
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar la transferencia", "error");
      });
  };

  const handlePrestar = () => {
    let detalles = productosSolicitud.map((item) => ({
      idProducto: item.id,
      idSucursal: idSucursal,
      cantidad: -item.cantidad,
    }));
    detalles = detalles.concat(
      productosSolicitud.map((item) => ({
        idProducto: item.id,
        idSucursal: selectedSolicitud.sucursal.id,
        cantidad: item.cantidad,
      }))
    );
    saveMovimiento({
      variables: {
        input: {
          tipo: "Prestamo",
          movimientoDetalle: detalles,
          idSucursalOrigen: idSucursal,
          idSucursalDestino: selectedSolicitud.sucursal.id,
          observaciones: solicitud?.observaciones || null,
          idResponsable: selectedSolicitud?.user?.id,
          vencimiento: dayjs(solicitud?.vencimiento).format("YYYY-MM-DD HH:mm:ss"),
        },
      },
    })
      .then((data) => {
        saveSolicitud({
          variables: {
            input: {
              id: selectedSolicitud.id,
              estado: "En curso",
              idMovimiento: data.data.saveMovimiento.id,
            },
          },
        })
          .then(() => {
            handleSnackbar("El prestamo se ha realizado correctamente", "success");
            handleClose();
            refetchSolicitudes();
          })
          .catch(() => {
            handleSnackbar("Ha ocurrido un error al realizar el prestamo", "error");
          });
      })
      .catch(() => {
        handleSnackbar("Ha ocurrido un error al realizar el prestamo", "error");
      });
  };

  const hasChanges = () => {
    return JSON.stringify(productosBase) !== JSON.stringify(productosSolicitud);
  };

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  useEffect(() => {
    if (sucursalesData && idSucursal) {
      const thisSede = sucursalesData?.sucursales?.find((sucursal) => sucursal?.id === idSucursal);
      setSucursales(
        sucursalesData?.sucursales?.filter((sucursal) => sucursal?.sede?.id === thisSede?.sede?.id)
      );
    }
  }, [sucursalesData, idSucursal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">{`Solicitud Nº${selectedSolicitud?.id} - Creada por ${selectedSolicitud?.user?.username}`}</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={
                        solicitud?.tipo === "Prestamo" ||
                        (selectedSolicitud && selectedSolicitud.tipo !== "Prestamo")
                          ? 6
                          : selectedSolicitud
                          ? 4
                          : 12
                      }
                    >
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Tipo
                          {!selectedSolicitud && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      <SoftBox>
                        {selectedSolicitud ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedSolicitud?.tipo}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="tipo"
                            value={solicitud?.tipo || ""}
                            onChange={handleChanges}
                          >
                            <MenuItem value={"Prestamo"}>Prestamo</MenuItem>
                            <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                          </Select>
                        )}
                      </SoftBox>
                    </Grid>
                    {(solicitud?.tipo === "Prestamo" || selectedSolicitud?.tipo === "Prestamo") && (
                      <Grid item xs={12} md={selectedSolicitud ? 4 : 6}>
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            {selectedSolicitud?.estado === "Aceptado"
                              ? "Vencimiento solicitado"
                              : "Vencimiento"}
                            {!selectedSolicitud && (
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            )}
                          </SoftTypography>
                        </InputLabel>
                        <SoftBox>
                          {selectedSolicitud ? (
                            <SoftTypography variant="h6" fontWeight="bold">
                              {selectedSolicitud?.estado === "Aceptado"
                                ? dayjs(selectedSolicitud?.vencimiento).format("DD/MM/YYYY HH:mm")
                                : dayjs(selectedSolicitud?.movimiento?.vencimiento).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                            </SoftTypography>
                          ) : (
                            <SoftInput
                              label="Fecha de vencimiento"
                              type="datetime-local"
                              name="vencimiento"
                              value={solicitud?.vencimiento || ""}
                              onChange={handleChanges}
                              error={dayjs(solicitud?.vencimiento).isBefore(dayjs())}
                            />
                          )}
                        </SoftBox>
                      </Grid>
                    )}
                    {selectedSolicitud && (
                      <Grid
                        item
                        xs={12}
                        md={selectedSolicitud && selectedSolicitud.tipo === "Prestamo" ? 4 : 6}
                      >
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Estado
                          </SoftTypography>
                        </InputLabel>
                        <SoftBox>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            color={
                              selectedSolicitud?.estado === "Pendiente"
                                ? "sistemasAmarillo"
                                : selectedSolicitud?.estado === "Aceptado"
                                ? "sistemasGris"
                                : selectedSolicitud?.estado === "Rechazado"
                                ? "sistemasAmarillo"
                                : selectedSolicitud?.estado === "En curso"
                                ? "sistemasNegro"
                                : "sistemasGris"
                            }
                          >
                            <Icon sx={{ fontWeight: "light" }}>
                              {selectedSolicitud?.estado === "Pendiente"
                                ? "history"
                                : selectedSolicitud?.estado === "Aceptado"
                                ? "new_releases"
                                : selectedSolicitud?.estado === "Rechazado"
                                ? "close"
                                : selectedSolicitud?.estado === "En curso"
                                ? "schedule_send"
                                : "done_all"}
                            </Icon>
                            &nbsp;
                            {selectedSolicitud?.estado === "Aceptado"
                              ? "Nuevo"
                              : selectedSolicitud?.estado}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Descripción
                          {!selectedSolicitud && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      <SoftBox>
                        {selectedSolicitud ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedSolicitud?.observaciones}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder="Ingrese una descripción de por qué y para qué se solicitan los productos."
                            type="text"
                            name="observaciones"
                            value={solicitud?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            fullWidth
                            rows={4}
                          />
                        )}
                      </SoftBox>
                    </Grid>
                    {selectedSolicitud &&
                      selectedSolicitud.tipo === "Prestamo" &&
                      selectedSolicitud.estado === "Aceptado" && (
                        <Grid item xs={12} md={12}>
                          <InputLabel htmlFor="observaciones">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Fecha y hora de vencimiento
                              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                              </SoftTypography>
                            </SoftTypography>
                          </InputLabel>
                          <SoftBox>
                            <SoftInput
                              label="Fecha de vencimiento"
                              type="datetime-local"
                              name="vencimiento"
                              value={solicitud?.vencimiento || ""}
                              onChange={handleChanges}
                              error={dayjs(solicitud?.vencimiento).isBefore(dayjs())}
                            />
                          </SoftBox>
                        </Grid>
                      )}
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Observaciones
                          {(!selectedSolicitud || hasChanges()) && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      <SoftBox>
                        {selectedSolicitud && selectedSolicitud.estado !== "Aceptado" ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedSolicitud?.movimiento?.observaciones || "Sin observaciones"}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder={
                              hasChanges()
                                ? "Ingrese una descripción de por qué se modificó la solicitud"
                                : "De ser necesario, ingrese una descripción de la solicitud"
                            }
                            type="text"
                            name="observaciones"
                            value={solicitud?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            fullWidth
                            rows={4}
                          />
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">
                      {selectedSolicitud?.estado === "Aceptado"
                        ? "Productos"
                        : `Productos - Despachados por ${selectedSolicitud?.movimiento?.user?.username}`}
                    </SoftTypography>
                  </SoftBox>
                  {selectedSolicitud?.estado === "Aceptado" && (
                    <SoftBox display="flex" justifyContent="end" alignItems="flex-end">
                      <SoftBox
                        sx={{
                          width: "400px",
                        }}
                      >
                        <SoftInput
                          placeholder="Busca aquí materiales..."
                          icon={{ component: "search", direction: "left" }}
                          onChange={handleSearch}
                          onKeyPress={() => {
                            setOpenSelectionMenu(true);
                          }}
                          onClick={() => {
                            getProductos({
                              variables: {
                                page: 1,
                                filter: {
                                  disabled: false,
                                  _stock: {
                                    cantidad: { _gte: 1 },
                                    idSucursal: idSucursal,
                                  },
                                },
                                idSucursal: idSucursal,
                              },
                            });
                            setOpenSelectionMenu(true);
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (e.relatedTarget?.tagName !== "LI") {
                              setOpenSelectionMenu(false);
                            } else {
                              e.target.focus();
                            }
                          }}
                        />
                        {openSelectionMenu && (
                          <SoftBox sx={{ position: "fixed", zIndex: 99 }}>
                            <Card>
                              <SoftBox
                                p={
                                  !loading &&
                                  productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosSolicitud.find((item) => item.id === producto.id)
                                  ).length > 0
                                    ? 3
                                    : 0
                                }
                                sx={{
                                  width: "400px",
                                  maxHeight: "30vh",
                                  overflowY: "auto",
                                }}
                              >
                                {openSelectionMenu && loading ? (
                                  <Loading />
                                ) : productos?.productos?.length > 0 &&
                                  productos?.productos.filter(
                                    (producto) =>
                                      !productosSolicitud.find((item) => item.id === producto.id)
                                  ).length > 0 &&
                                  openSelectionMenu ? (
                                  productos?.productos
                                    .filter(
                                      (producto) =>
                                        !productosSolicitud.find((item) => item.id === producto.id)
                                    )
                                    .map((producto) => (
                                      <MenuItem
                                        key={producto.id}
                                        onClick={() => {
                                          handleAddProduct(producto);
                                          setOpenSelectionMenu(false);
                                        }}
                                      >
                                        <SoftBox mr={2}>
                                          <Icon
                                            sx={{
                                              color:
                                                producto.tipo === "Material"
                                                  ? getUproColor("sistemasAmarillo")
                                                  : producto.tipo === "Consumible"
                                                  ? getUproColor("sistemasGris")
                                                  : getUproColor("sistemasAmarillo"),
                                            }}
                                            fontSize="small"
                                          >
                                            {producto.tipo === "Material"
                                              ? "category"
                                              : producto.tipo === "Consumible"
                                              ? "recycling"
                                              : "layers"}
                                          </Icon>
                                        </SoftBox>
                                        <SoftBox>
                                          &nbsp;{`${producto.codigo} - ${producto.nombre}`}
                                        </SoftBox>
                                      </MenuItem>
                                    ))
                                ) : (
                                  <SinResultados />
                                )}
                              </SoftBox>
                            </Card>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </SoftBox>
                  )}
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosSolicitud.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          {selectedSolicitud?.estado === "Aceptado" ? (
                            <TableRow>{renderColumnsBase}</TableRow>
                          ) : (
                            <TableRow>{renderColumnsSelect}</TableRow>
                          )}
                        </SoftBox>
                        <TableBody>
                          {productosSolicitud.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                handleUpdateMaterial={handleUpdateProductoSolicitud}
                                handleRemoveMaterial={() => handleRemoveProduct(material)}
                                disabled={selectedSolicitud?.estado !== "Aceptado"}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
              {selectedSolicitud?.estado === "Aceptado" && (
                <Card sx={{ marginTop: 2 }}>
                  <SoftBox display="flex" justifyContent="end" alignItems="center" p={2}>
                    <SoftBox mr={2}>
                      <SoftButton color="primary" circular onClick={handleClose}>
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                      </SoftButton>
                    </SoftBox>
                    <SoftBox>
                      <SoftButton
                        color="sistemasGris"
                        circular
                        disabled={
                          loadingSave ||
                          loadingSaveMovimiento ||
                          productosSolicitud.length === 0 ||
                          productosSolicitud.some(
                            (item) =>
                              !item.cantidad ||
                              item.cantidad == 0 ||
                              item.cantidad > item.stock.cantidad ||
                              item?.cantidad?.toString().includes(".")
                          ) ||
                          (hasChanges() && !solicitud?.observaciones) ||
                          (selectedSolicitud?.tipo === "Prestamo" && !solicitud?.vencimiento) ||
                          (selectedSolicitud?.tipo === "Prestamo" &&
                            solicitud?.vencimiento &&
                            dayjs(solicitud?.vencimiento).isBefore(dayjs()))
                        }
                        onClick={
                          selectedSolicitud?.tipo === "Transferencia"
                            ? handleTransferir
                            : handlePrestar
                        }
                      >
                        {loadingSave || loadingSaveMovimiento ? (
                          <CircularProgress size={15} color="white" />
                        ) : (
                          <Icon sx={{ fontWeight: "light" }}>
                            {selectedSolicitud?.tipo === "Transferencia" ? "swap_horiz" : "send"}
                          </Icon>
                        )}
                        &nbsp;
                        {selectedSolicitud?.tipo === "Transferencia" ? "Transferir" : "Prestar"}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Card>
              )}
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalNuevaSolicitud.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedSolicitud: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchSolicitudes: PropTypes.func,
};
