/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { getUproColor } from "utils/colors";

const getRows = (data, handleSelect) => {
  const rows = [];
  if (data) {
    data?.forEach((item) => {

      const superaOrden = item?.movimientoDetalles?.some((m) => m?.superaOrdenProducto);

      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getUproColor("sistemasAmarillo"),
                }}
                fontSize="lg"
              >
                inventory_2
              </Icon>
            }
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Materiales"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getUproColor("sistemasAmarillo"),
                  }}
                  fontSize="lg"
                >
                  inventory_2
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        sucursal: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.sucursalDestino
              ? `${item?.sucursalDestino?.sede?.nombre} - ${item?.sucursalDestino?.nombre}`
              : "Sin nombre"}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalDestino
                ? `${item?.sucursalDestino?.sede?.nombre} - ${item?.sucursalDestino?.nombre}`
                : "Sin nombre"}
            </SoftTypography>
          </SoftBox>
        ),
        proveedor: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.proveedor?.razonSocial || item?.proveedor?.nombre + " " + item?.proveedor?.apellido}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.proveedor?.razonSocial || item?.proveedor?.nombre + " " + item?.proveedor?.apellido}
            </SoftTypography>
          </SoftBox>
        ),
        nroRemito: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.nroRemito ? item?.nroRemito : "Sin especificar" }
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.nroRemito ? item?.nroRemito : "Sin especificar" }
            </SoftTypography>
          </SoftBox>
        ),
        fechaRecepcion: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.fechaEntrega ? dayjs(item?.fechaEntrega).format("DD/MM/YYYY") : "Sin especificar" }
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.fechaEntrega ? dayjs(item?.fechaEntrega).format("DD/MM/YYYY") : "Sin especificar" }
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {
              superaOrden && (
                <Tooltip title="Hay materiales recibidos que superan la cantidad de la orden" placement="top">
                  <Icon
                    sx={{  cursor: "pointer", mx: 0.5 }}
                    fontSize="small"
                    onClick={() => handleSelect(item)}
                    color="error"
                  >
                    difference
                  </Icon>
                </Tooltip>
              )
            }
            <Tooltip title="Ver detalles" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={() => handleSelect(item)}
              >
                arrow_forward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left",noOrder: true  },
  { name: "sucursal", desc: "Deposito", align: "left" ,noOrder: true },
  { name: "proveedor", desc: "Proveedor", align: "left",noOrder: true  },
  { name: "nroRemito", desc: "Nro. Remito", align: "left",noOrder: true  },
  { name: "fechaRecepcion", desc: "Fecha recepción", align: "left",noOrder: true  },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
