import * as React from "react";
import { Modal, Fade, Backdrop, Card, Grid, InputLabel, Tooltip, Icon, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import { MessageManager } from "context";
import { useContext } from "react";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "40%" },
  overflowY: "auto",
  py: 3,
};

export default function ModalPagos({ open, handleClose ,dataPago,refetch}) {
  const {handleSnackbar} = useContext(MessageManager);
  const [formaPago,setFormaPago] = useState({
    fechaPago: "",
    tipoMoneda: "",
    precio: "",
    cotizacion: "",
    medioPago: "",
    observacionPago: "",
    montoAnticipo: "",
    porcentajeAnticipo: "",
  });

  const handleChange = (event) => {
    if(!dataPago?.id){
      setFormaPago({ 
          ...formaPago, 
          [event.target.name]: event.target.value 
      });
    }
  }

  const resetForm = () => {
    setFormaPago({
        fechaPago: "",
        tipoMoneda: "",
        precio: "",
        cotizacion: "",
        medioPago: "",
        observacionPago: "",
        montoAnticipo: "",
        porcentajeAnticipo: "",
    });
  }

  const {data:cotizacion} = useQuery(
    gql`
        query getCotizaciones{
            cotizaciones{
            agencia
            compra
            decimales
            fechaActualizacion
            nombre
            variacion
            variacion
            venta
            }
        }
    `
  );

  const [saveOrdenPago, { data, loading, error }] = useMutation(gql`
    mutation saveOrdenPago($input: OrdenPagoInput!) {
      saveOrdenPago(input: $input) {
        id
      }
    }
  `);

  const handleSave  = ()=>{
    const infoPago = {
      ...formaPago,
      precio: formaPago?.precio ? parseFloat(formaPago?.precio) : 0,
      cotizacion: formaPago?.cotizacion ? parseFloat(formaPago?.cotizacion) : 0,
      montoAnticipo: formaPago?.montoAnticipo ? parseFloat(formaPago?.montoAnticipo) : 0,
      porcentajeAnticipo: formaPago?.porcentajeAnticipo ? parseFloat(formaPago?.porcentajeAnticipo) : 0,
      idProyecto: dataPago?.idProyecto,
      idProveedor: dataPago?.idProveedor,
      idOrdenCompra: dataPago?.idOrdenCompra,
      estado: "Pendiente", 
    };

    saveOrdenPago({
        variables: {
            input: infoPago
        }
    }).then(() => {
        resetForm();
        handleClose();
        handleSnackbar("Forma de pago guardada correctamente","success");
        refetch();
    }).catch((error) => {
        console.log(error);
        handleSnackbar("Error al guardar la forma de pago","error");
    });
  }

  const calcularMontoAnticipo = (e) => {
    if(!dataPago?.id){   
      if(e.target.name == "precio" && formaPago?.porcentajeAnticipo > 0){
          setFormaPago({
              ...formaPago,
              precio: e.target.value, 
              montoAnticipo: parseFloat(e.target.value * (formaPago?.porcentajeAnticipo / 100)).toFixed(2)
          });
      }else if(e.target.name == "porcentajeAnticipo" && formaPago?.precio > 0){
          setFormaPago({
              ...formaPago,
              porcentajeAnticipo: e.target.value, 
              montoAnticipo: parseFloat(formaPago?.precio * (e.target.value / 100)).toFixed(2)
          });
      }else {
          setFormaPago({
              ...formaPago,
              [e.target.name]: e.target.value
          });
      }        
    }
  }

  useEffect(() => {
    if(open){
      if(dataPago?.id){
        setFormaPago({
          fechaPago: dataPago?.fechaPago || "",
          tipoMoneda: dataPago?.tipoMoneda || "",
          precio: dataPago?.precio || "",
          cotizacion: dataPago?.cotizacion || "",
          medioPago: dataPago?.medioPago || "",
          observacionPago: dataPago?.observacionPago || "",
          montoAnticipo: dataPago?.montoAnticipo || "",
          porcentajeAnticipo: dataPago?.porcentajeAnticipo || "",
        });
      }else{
        setFormaPago({
          fechaPago: "",
          tipoMoneda: "",
          precio: dataPago?.totalOrden || 0,
          cotizacion: "",
          medioPago: "",
          observacionPago: "",
          montoAnticipo: "",
          porcentajeAnticipo: "",
        });
      }
    }
  },[open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3} display="flex" justifyContent="space-between" mb={1}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h6" lineHeight={1}>Forma de Pago</SoftTypography>
              <SoftTypography variant="caption" color="secondary">Campos Obligatorios (*)</SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <Tooltip title="Cerrar" placement="top">
                  <Icon
                    fontSize="medium"
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    close
                  </Icon>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          </SoftBox>

          <SoftBox px={3}>
            <SoftBox mb={3}>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="fechaPago">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Fecha de Pago *
                          </SoftTypography>
                      </InputLabel>
                      <SoftInput
                      name="fechaPago"
                      label="Fecha de Pago"
                      type="date"
                      value={formaPago?.fechaPago || ""}
                      onChange={handleChange} 
                      disabled={dataPago?.id ? true : false}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="tipoMoneda">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Tipo de Moneda *
                          </SoftTypography>
                      </InputLabel>
                      <Select
                      name="tipoMoneda"
                      value={formaPago?.tipoMoneda || -1}
                      onChange={(event)=>{
                          setFormaPago({
                              ...formaPago,
                              tipoMoneda: event.target.value,
                              cotizacion: cotizacion?.cotizaciones?.find(ctz => ctz?.nombre === event.target.value)?.venta || (event.target.value === "Pesos" ? 1 : 0),
                          })
                      }}
                      disabled={dataPago?.id ? true : false}
                      > 
                          <MenuItem value={-1} disabled>Seleccion un tipo de moneda</MenuItem>
                          <MenuItem value="Pesos">Pesos</MenuItem>
                          {
                              cotizacion?.cotizaciones?.map((ctz) => {
                                  return (
                                      <MenuItem key={ctz?.nombre} value={ctz?.nombre}>
                                          {ctz?.nombre}
                                      </MenuItem>
                                  )
                              }) 
                          }
                      </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="precio">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Precio *
                          </SoftTypography>
                      </InputLabel>
                      <SoftInputNumber
                      name="precio"
                      label="Precio"
                      placeholder="Ej: 50000"
                      value={formaPago?.precio || ""}
                      onChange={calcularMontoAnticipo}
                      disabled={dataPago?.id ? true : false}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="cotizacion">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Cotización *
                          </SoftTypography>
                      </InputLabel>
                      <SoftInputNumber
                      name="cotizacion"
                      label="cotizacion"
                      placeholder="Ej: 1300"
                      value={formaPago?.cotizacion || ""}
                      onChange={handleChange}
                      disabled={dataPago?.id ? true : false}
                      />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                      <InputLabel htmlFor="medioPago">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Medio de Pago *
                          </SoftTypography>
                      </InputLabel>
                      <Select
                       name="medioPago"
                       label="medio de pago"
                       value={formaPago?.medioPago || -1}
                       onChange={handleChange}
                       disabled={dataPago?.id ? true : false}
                      >
                          <MenuItem value={-1} disabled>Seleccion un medio de pago</MenuItem>
                          <MenuItem value="Cheque">Cheque</MenuItem>
                          <MenuItem value="Debito">Debito</MenuItem>
                          <MenuItem value="Efectivo">Efectivo</MenuItem>
                          <MenuItem value="Transferencia">Transferencia</MenuItem>
                          <MenuItem value="Otros">Otros</MenuItem>
                      </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="porcentajeAnticipo">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Porcentaje de Anticipo (%) 
                          </SoftTypography>
                      </InputLabel>
                      <SoftInput
                      name="porcentajeAnticipo"
                      label="porcentajeAnticipo"
                      placeholder="Ej: 10"
                      value={formaPago?.porcentajeAnticipo || ""}
                      onChange={calcularMontoAnticipo}
                      error={formaPago?.porcentajeAnticipo != "" && (formaPago?.porcentajeAnticipo < 0 || formaPago?.porcentajeAnticipo > 100)}
                      type="number"
                      disabled={dataPago?.id ? true : false}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <InputLabel htmlFor="montoAnticipo">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Monto de Anticipo 
                          </SoftTypography>
                      </InputLabel>
                      <SoftInputNumber
                          name="montoAnticipo"
                          label="montoAnticipo"
                          placeholder="Ej: 50000"
                          value={formaPago?.montoAnticipo || ""}
                          onChange={handleChange}
                          disabled={dataPago?.id ? true : false}
                      />
                  </Grid>  
                  <Grid item xs={12} sm={12}>
                      <InputLabel htmlFor="observacionPago">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                              Observación de pago
                          </SoftTypography>
                      </InputLabel>
                      <SoftInput
                          name="observacionPago"
                          label="observacionPago"
                          placeholder="Ej: Observaciones"
                          value={formaPago?.observacionPago || ""}
                          onChange={handleChange}
                          multiline
                          rows={2}
                          disabled={dataPago?.id ? true : false}
                      />
                  </Grid>  
              </Grid>
            </SoftBox>
          </SoftBox>

          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3} pt={3}>
            {
              dataPago?.id ? (
                <SoftButton variant="gradient" color="dark" 
                  onClick={()=>{
                    handleClose();
                    resetForm();
                  }}
                  circular
                >
                  Cerrar
                </SoftButton>
              ) : (
                <>
                  <SoftBox mr={2}>
                    <SoftButton variant="gradient" color="dark" 
                      onClick={()=>{
                        handleClose();
                        resetForm();
                      }}
                      circular
                    >
                      Cancelar
                    </SoftButton>
                  </SoftBox>
                  <SoftBox>
                    <SoftButton variant="gradient" color="primary" 
                      onClick={handleSave}
                      circular
                      disabled={
                        formaPago?.fechaPago == "" ||
                        formaPago?.tipoMoneda == "" ||
                        !formaPago?.precio || 
                        formaPago?.precio < 0 ||
                        !formaPago?.cotizacion || 
                        formaPago?.cotizacion < 0 ||
                        formaPago?.medioPago == "" 
                      }
                      >
                      Guardar
                    </SoftButton>
                  </SoftBox>
                </>
              )
            }
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalPagos.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataPago: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};
