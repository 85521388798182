/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";
import dayjs from "dayjs";

const getRows = (data, handleSelect) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2} display="flex">
              <Tooltip title={item.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item?.tipo === "Transferencia"
                        ? getUproColor("sistemasGris")
                        : getUproColor("sistemasAmarillo"),
                  }}
                  fontSize="lg"
                >
                  {item?.tipo === "Transferencia" ? "import_export" : "autorenew"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        sucursalDestino: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.sucursalDestino?.nombre + " - " + item?.sucursalDestino?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.movimientoDetalles.filter((detalle) => detalle.cantidad > 0).length +
                " productos"}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.nombre
                ? `${makeNiceText(item?.user?.nombre, "nombre")} ${makeNiceText(
                    item?.user?.apellido,
                    "nombre"
                  )}`
                : item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        responsable: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.responsable?.nombre
                ? `${makeNiceText(item?.responsable?.nombre, "nombre")} ${makeNiceText(
                    item?.responsable?.apellido,
                    "nombre"
                  )}`
                : "Sin responsable"}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip
              title={
                dayjs(item?.vencimiento).isBefore(dayjs())
                  ? "Vencido"
                  : dayjs(item?.vencimiento).isSame(dayjs(), "day")
                  ? "Vence hoy"
                  : "Pendiente de devolución"
              }
              placement="top"
            >
              <SoftBadge
                color={
                  dayjs(item?.vencimiento).isBefore(dayjs())
                    ? "error"
                    : dayjs(item?.vencimiento).isSame(dayjs(), "day")
                    ? "warning"
                    : "success"
                }
                size="xs"
                badgeContent={
                  <SoftTypography
                    verticalAlign="middle"
                    variant="caption"
                    color="white"
                    fontWeight="medium"
                  >
                    {dayjs(item?.vencimiento).format("DD/MM/YYYY HH:mm")}
                  </SoftTypography>
                }
                container
              />
            </Tooltip>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip title="Ver detalles" placement="top">
              <SoftBox display="flex">
                <Icon
                  sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                  fontSize="small"
                  onClick={() => handleSelect(item)}
                >
                  arrow_forward
                </Icon>
              </SoftBox>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  {
    name: "sucursalDestino",
    desc: "Sucursal de Destino",
    align: "left",
    orderField: "idSucursalDestino",
  },
  { name: "cantidad", desc: "Cantidad de productos", align: "center", noOrder: true },
  { name: "user", desc: "Solicitado por", align: "left", orderField: "idUser" },
  { name: "responsable", desc: "Responsable", align: "left", orderField: "idUser" },
  { name: "fecha", desc: "Vencimiento", align: "left", orderField: "createdAt" },
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
