// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { formatMoneyPunto, makeNiceText } from "utils/formatters";

function TableItemPDFCertificado({ material,cantidad, totalProyecto }) {
  const { light } = colors;
  const { borderWidth } = borders;

  const incidenciaTotal = ()=>{
    const subTotal = material?.horas ? (material?.precio * material?.horas * cantidad) : material?.cantidad ? (material?.precio * material?.cantidad * cantidad) : (material?.precio || 0);
    const total =  parseFloat((subTotal * 100) / totalProyecto).toFixed(2);
    return parseFloat(total || 0);
  }

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
        lineHeight={1}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold" lineHeight={1}>
            {material?.producto?.nombre ? material?.producto?.nombre : material?.nombre}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
        {material?.horas ? `${material?.horas * cantidad}hs` : material?.cantidad ? `${material?.cantidad * cantidad}u` : "1"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
         {`$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.horas ? `$${formatMoneyPunto(material?.precio * material?.horas * cantidad)}` : material?.cantidad ? `$${formatMoneyPunto(material?.precio * material?.cantidad * cantidad)}` : `$${formatMoneyPunto(material?.precio || 0)}`}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {incidenciaTotal() >= 0 ? `${incidenciaTotal()}%` : "-"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.incParcial >= 0 ? `${parseFloat(material?.incParcial)}%` : "0.00%"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.porcentajeAcumulado ? `${material?.porcentajeAcumulado}%` : "0.00%"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.nuevoPorcentajeAvance ? `${material?.nuevoPorcentajeAvance}%` : "0.00%"}
        </SoftTypography>  
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
         <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {parseFloat((material?.porcentajeAcumulado || 0) + (material?.nuevoPorcentajeAvance || 0)).toFixed(2) + "%"}
         </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "center",
        }}
      >
        <SoftTypography verticalAlign={"middle"} variant="caption" color="dark" fontWeight="bold">
            {material?.montoAvance ? `$${formatMoneyPunto(material?.montoAvance)}` : "$0.00"}
        </SoftTypography>
      </SoftBox>
    </TableRow>
  );
}

// Typechecking props of the TableItemPDFCertificado
TableItemPDFCertificado.propTypes = {
  material: PropTypes.object.isRequired,
  cantidad: PropTypes.number,
  totalProyecto: PropTypes.number,
};

export default TableItemPDFCertificado;
