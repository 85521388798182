// React
import { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import {
  Card,
  Fade,
  Grid,
  Icon,
  Pagination,
  Skeleton,
  Tooltip,
  Menu,
  MenuItem,
  CircularProgress,
  Badge,
  Stack,
  Chip,
} from "@mui/material";

// Data
// import dataUsuarios from "layouts/usuarios/data/dataUsuarios";
// import dataClientes from "./data/dataClientes";
import dataOrdenesPago from "./data/dataOrdenesPago";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import { getUproColor } from "utils/colors";
import SoftInput from "components/SoftInput";
import { MessageManager } from "context";
import { UserContext } from "context/user";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import "dayjs/locale/es";

const date = new Date().toLocaleString("af-ZA", {
  hour12: false,
});

function ServerDay(props) {
    
  const { eventos = {}, day, outsideCurrentMonth, ...other } = props;
  const isSelected = !props.outsideCurrentMonth && eventos[props.day.date()]?.length > 0;
  // const longitud = eventos[props.day.date()]?.length;
  const ordenesPagas = eventos[props.day.date()]?.filter(
    (cheque) => cheque.estado === "Pagado"
  ).length;
  const ordenesNoPagas = eventos[props.day.date()]?.filter(
    (cheque) => cheque.estado !== "Pagado"
  ).length;
  return (
    <>
      <Badge
        key={props.day.toString()}
        overlap="circular"
        color={ dayjs(date).format("D") <= props.day.date() ? "dark" : "warning" }
        badgeContent={isSelected ? ordenesNoPagas : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          color: "#ffffff",
        }}
      >
        <Badge
          key={props.day.toString()}
          overlap="circular"
          badgeContent={isSelected ? ordenesPagas : undefined}
          sx={{
            position: "absolute",
            top: "14%",
            left: "14%",
            color: "#ffffff",
            backgroundColor: "#66d432",
            " .MuiBadge-badge": {
              backgroundColor: "#66d432",
            },
          }}
        ></Badge>
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    </>
  );
}

export default function OrdenPago() {
  dayjs.locale("es");
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "id",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDocumentacion, setOpenModalDocumentacion] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataOrdenesPago;

  const [vistaCalendario,setVistaCalendario] = useState(false);
  const [loadingCalendario, setLoadingCalendario] = useState(false);
  const [mes, setMes] = useState(dayjs(date).format("MM"));
  const [anio, setAnio] = useState(dayjs(date).format("YYYY"));
  const [dia, setDia] = useState(dayjs(date).format("D"));
  const [eventos,setEventos] = useState({});
  const [diaElegido, setDiaElegido] = useState(false);
  const [ordenes,setOrdenes] = useState([]);
 
  const handleMonthChange = (date) => {
    setDiaElegido(false);
    setMes(date.$M + 1);
    if (date.$y !== anio) {
      setAnio(date.$y);
    }
  };

  const handleChangeDia = (e) => {
      setDiaElegido(true);
      setDia(e.$D);
  };

  useEffect(() => {
    if(dia){
      filtrarOrdenes(dayjs(`${anio}-${mes}-${dia}`).format("YYYY-MM-DD"));
    }else{
      setRows([]);
    }
  }, [dia]);

  const filtrarOrdenes = (fechaFiltro) => {
    const ordenesFecha = ordenes?.filter((orden) => {
      return (
        dayjs(orden?.fechaPago).format("YYYY-MM-DD") ===
        dayjs(`${fechaFiltro}`).format("YYYY-MM-DD")
      );
    });

    if(ordenesFecha?.length > 0){
      setRows(getRows(ordenesFecha, handleOpenMenu));
    }else{
      setRows([]);
    }
  }

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteCliente] = useMutation(
    gql`
      mutation deleteCliente($id: ID!) {
        deleteCliente(id: $id) {
          id
        }
      }
    `
  );

  const[getOrdenes,{ loading, error, data }] = useLazyQuery(
    gql`
      query getOrdenes($search: String,  $order: Order,$fechaInicio: String, $fechaFin: String, $pagination: PaginationInput) {
        paginationInfo {
          pages
          total
        }
        ordenesPago(
          search: $search
          pagination: $pagination
          order: $order
          fechaInicio: $fechaInicio
          fechaFin: $fechaFin
        ) {
            id
            proyecto{
                id
                nombre
            }
            proveedor{
                id
                tipoProveedor
                nombre
                apellido
                razonSocial
            }
            ordenCompra{
                id
            }
            certificado{
                id
            }
            fechaPago
            tipoMoneda
            precio
            cotizacion
            medioPago
            observacionPago
            montoAnticipo
            porcentajeAnticipo
            estado
        }
      }
    `,{
      fetchPolicy: "no-cache",
    }
  );

  const agruparDias = (ordenes) => {
    const groupedCheques = ordenes.reduce((result, evento) => {
      const day = dayjs(evento.fechaPago).format("D");
      result[day] = [...(result[day] || []), evento];
      return result;
    }, {});

    setEventos(groupedCheques);
  }

  useEffect(() => {
    if(vistaCalendario){ 
      setLoadingCalendario(true);
      const ultimo = new Date(anio, mes, 0).getDate();
      getOrdenes({
        variables: {
          search: search || null,
          fechaInicio: dayjs(`${anio}-${mes}-01`).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
          fechaFin: dayjs(`${anio}-${mes}-${ultimo}`).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        },
     }).then((res) => {
       setOrdenes(res?.data?.ordenesPago);
       filtrarOrdenes(dayjs(`${anio}-${mes}-${dia}`).format("YYYY-MM-DD"));
       agruparDias(res?.data?.ordenesPago);
     }).finally(() => {
       setLoadingCalendario(false);
     });
    }else{
      setDia(dayjs().format("D"));
      setMes(dayjs(date).format("MM"));
      getOrdenes({
        variables: {
          search: search || null,
          pagination: {page: page, limit: 10},
          fechaInicio: null,
          fechaFin: null
        },
      }).then((res) => {
        setRows(getRows(res?.data?.ordenesPago, handleOpenMenu));
        setPagesInfo(res?.data?.paginationInfo);
      });
    }
  }, [vistaCalendario,page,mes,search]);

  const renderMenu = (
    <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(openMenu)} onClose={handleCloseMenu}>
        <MenuItem
            disabled={false}
            onClick={() => {
              setOpenModalAdd(true);
              handleCloseMenu();
            }}
          >
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox>
                <SoftTypography variant="p">Ver detalle</SoftTypography>
              </SoftBox>
              <SoftBox>
                <Icon
                  fontSize="small"
                  sx={{
                    verticalAlign: "middle",
                    color: getUproColor("sistemasAmarillo"),
                  }}
                >
                  visibility
                </Icon>
              </SoftBox>
            </SoftBox>
        </MenuItem>
        <MenuItem
          disabled={selectedUsuario?.tipoUser === "Administrador"}
          onClick={() => {
            setOpenModalDelete(true);
            handleCloseMenu();
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <SoftBox>
              <SoftTypography variant="p">Eliminar</SoftTypography>
            </SoftBox>
            <SoftBox>
              <Icon
                fontSize="small"
                sx={{
                  verticalAlign: "middle",
                  color:
                    selectedUsuario?.tipoUser === "Administrador"
                      ? getUproColor()
                      : getUproColor("sistemasGris"),
                }}
              >
                delete
              </Icon>
            </SoftBox>
          </SoftBox>
        </MenuItem>
    </Menu>
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteCliente({ variables: { id } });
      refetch();
      handleSnackbar("Cliente eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar("Error al eliminar cliente", "error");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="Es" adapterLocale="ES">
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Ordenes de pago</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Ver calendario de pagos" placement="top">
                        <SoftButton
                          color="primary"
                          circular
                          iconOnly
                          onClick={() => setVistaCalendario(()=>!vistaCalendario)}
                        >
                           <Icon>
                             {vistaCalendario ? "reorder" : "calendar_month" }
                           </Icon>
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Ver calendario de pagos" placement="top">
                        <SoftButton color="primary" circular 
                        onClick={() => setVistaCalendario(()=>!vistaCalendario)}
                        >
                          <Icon>
                             {vistaCalendario ? "reorder" : "calendar_month" }
                           </Icon>
                          &nbsp;{vistaCalendario ? "Ver Listado" : "Ver Calendario" }
                        </SoftButton>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            {
              vistaCalendario ? (
                <SoftBox pb={4}>
                  <DateCalendar
                    onMonthChange={handleMonthChange}
                      onYearChange={handleMonthChange}
                      onChange={handleChangeDia}
                      loading={loadingCalendario}
                      slots={{
                        day: ServerDay,
                      }}
                      slotProps={{
                        day: {
                          eventos,
                        },
                      }}
                      sx={{
                        "&.MuiDateCalendar-root": {
                          height: "400px",
                          maxHeight: "400px",
                          width: "100%",
                          maxWidth: "800px",
                        },
                        ".MuiDayCalendar-slideTransition":{
                          overflowX: "visible",
                        },
                        ".MuiDayCalendar-header": {
                          justifyContent: "space-evenly",
                        },
                        ".MuiDayCalendar-weekContainer": {
                          justifyContent: "space-evenly",
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        },
                        ".MuiPickersDay-root": {
                          height: "40px",
                          width: "40px",
                          fontSize: "1rem",
                        },
                        ".MuiPickersCalendarHeader-label:first-letter": {
                          textTransform: "uppercase",
                        },
                        }}
                        // renderLoading={() => <CircularProgress />}
                        renderLoading={() => (
                          <DayCalendarSkeleton 
                              sx={{
                                  "&.MuiDayCalendarSkeleton-root": {
                                    // height: "400px",
                                    // maxHeight: "400px",
                                    width: "100%",
                                  },
                                  ".MuiDayCalendarSkeleton-week": {
                                    justifyContent: "space-evenly",
                                    paddingBottom: "5px",
                                    paddingTop: "5px",
                                  },
                              }}
                          />
                      )}
                  />
                  <SoftBox display="flex" justifyContent="flex-end" alignItems="flex-start" p={3}>
                      <Stack direction="row" spacing={1}>
                        <Chip 
                          label="Pagos atrasados" 
                          color="primary" 
                        />
                        <Chip 
                          label="Pagos realizados" 
                          sx={{
                            backgroundColor: "#66d432",
                            color: "#ffffff",
                          }}
                        />
                        <Chip
                          label="Pagos pendientes"
                          sx={{
                            backgroundColor: "#002366",
                            color: "#ffffff",
                          }}
                        />  
                      </Stack>
                  </SoftBox>
                  {loading ? (
                    <Loading />
                  ) : rows?.length > 0 ? (
                    <SoftBox p={2} >
                      <SoftBox py={1}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Ordenes de Pago - Fecha: {dayjs(`${anio}-${mes}-${dia}`).format("DD/MM/YYYY")}
                        </SoftTypography>
                      </SoftBox>
                      <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} /> 
                    </SoftBox>
                  ) : (
                    <SoftBox pt={3}>
                      <SinDatos />
                    </SoftBox>
                  )}
                </SoftBox>
              ) : (
                <>
                  <SoftBox
                    sx={{
                      "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                            `${borderWidth[1]} solid ${borderColor}`,
                        },
                      },
                    }}
                  >
                    {loading ? (
                      <Loading />
                    ) : rows?.length > 0 ? (
                      <DataTable columns={columns} rows={rows} order={orderBy} setOrder={handleOrderBy} /> 
                    ) : (
                      <SinDatos />
                    )}
                  </SoftBox>
                  <CustomPagination
                    loading={loading}
                    length={data?.ordenesPago?.length}
                    total={pagesInfo?.total}
                    pages={pagesInfo?.pages}
                    page={page}
                    setPage={setPage}
                    noCard
                  />
                </>
              )
            }
          </Card>
          {renderMenu}
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
    </LocalizationProvider>
  );
}

ServerDay.propTypes = {
  day: PropTypes.any,
  outsideCurrentMonth: PropTypes.any,
  eventos: PropTypes.any,
};

