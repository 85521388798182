// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Tooltip
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "55%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalAddEtapa({ open, handleClose,guardarEtapa,etapas }) {
    const [dataEtapa,setDataEtapa] = useState({
        id: new Date().getTime(),
        nombre: "",
    });

    useEffect(() => {
        if (open) {
          setDataEtapa({
            id: new Date().getTime(),
            nombre: "",
          });
        }
      }, [open]);

    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        >
        <Fade in={open}>
            <Card sx={style}>
            <SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                    <SoftTypography variant="h6">Nueva etapa</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="space-between">
                    <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                        <Icon fontSize="medium" 
                        onClick={() => {
                        handleClose();
                        }}
                        sx={{ cursor: "pointer" }}>
                        close
                        </Icon>
                    </Tooltip>
                    </SoftBox>
                </SoftBox>
                </SoftBox>

                <SoftBox mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="nombre">
                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Nombre de la etapa 
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                                &nbsp;*
                            </SoftTypography>
                            </SoftTypography>
                        </InputLabel>
                        <Tooltip title={(dataEtapa?.nombre != "" && etapas?.some((item)=>item?.nombre === dataEtapa?.nombre)) ? "Ya existe una etapa con este nombre" : ""} placement="top">
                            <SoftInput
                                label="Nombre"
                                placeholder="Ingrese el nombre de la etapa"
                                name="nombre"
                                icon={{
                                component: "dns",
                                direction: "left",
                                }}
                                value={dataEtapa?.nombre || ""}
                                error={(dataEtapa?.nombre != "" && etapas?.some((item)=>item?.nombre === dataEtapa?.nombre))}
                                onChange={(e) => {
                                    setDataEtapa({
                                        ...dataEtapa,
                                        nombre: e.target.value,
                                    });
                                }}
                                fullWidth
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                </SoftBox>
            
                <SoftBox
                display={{
                    xs: "flex-row",
                    sm: "flex",
                }}
                justifyContent="flex-end"
                alignItems="center"
                >
                <SoftBox
                    display={{
                    xs: "flex-row",
                    sm: "flex",
                    }}
                    justifyContent="end"
                    alignItems="center"
                >
                    <SoftBox
                    mr={{
                        xs: 0,
                        sm: 2,
                    }}
                    mt={{
                        xs: 2,
                        sm: 0,
                    }}
                    >
                    <SoftButton
                        color="primary"
                        circular
                        fullWidth={{
                        xs: true,
                        sm: false,
                        }}
                        onClick={()=>{
                         handleClose();
                         setDataEtapa({});
                        }}
                    >
                        <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                        &nbsp;Cancelar
                    </SoftButton>
                    </SoftBox>
                    <SoftBox
                    mt={{
                        xs: 2,
                        sm: 0,
                    }}
                    >
                    <SoftButton
                        color="sistemasGris"
                        circular
                        fullWidth={{
                        xs: true,
                        sm: false,
                        }}
                        onClick={() => {
                            guardarEtapa(dataEtapa);
                        }}
                        disabled={
                            !dataEtapa?.nombre || dataEtapa?.nombre?.length < 2 || !dataEtapa?.id || dataEtapa?.id?.length <= 0 || etapas?.some((item)=>item?.nombre === dataEtapa?.nombre)
                        }
                    >
                        <Icon sx={{ fontWeight: "light" }}>save</Icon>
                        &nbsp;Guardar
                    </SoftButton>
                    </SoftBox>
                </SoftBox>
                </SoftBox>
            </SoftBox>
            </Card>
        </Fade>
        </Modal>
    );
}

ModalAddEtapa.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarEtapa: PropTypes.func,
  etapas: PropTypes.array
};
