// React
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

// @mui material components
import {
  Card,
  Checkbox,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Modal,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "40%" },
  overflowY: "auto",
  p: 3,
};


export default function ModalAprobacion({ open, handleClose, guardarMotivo }) {
  const [motivo,setMotivo] = useState("");

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox display="flex" flexDirection="column" lineHeight={1} mb={1}>
                <SoftTypography variant="h6">Aprobación de Presupuesto</SoftTypography>
                <SoftTypography variant="caption" color="secondary">Campos obligatorios (*)</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        handleClose();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={4}>
              <SoftBox mb={3}>
                <Grid container spacing={2}>              
                  <Grid item xs={12} sm={12}>
                    <InputLabel htmlFor="motivo">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Motivo Aprobación *
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="motivo"
                      placeholder="Inserte el motivo de aprobación del presupuesto"
                      type="text"
                      multiline
                      rows={3}
                      name="motivo"
                      value={motivo || ""}
                      onChange={(e)=>setMotivo(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>

            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={() => {
                      handleClose();
                      setMotivo("");
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    disabled={motivo?.trim() === ""}
                    onClick={() => {
                        guardarMotivo(motivo);
                        setMotivo("");
                    }}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAprobacion.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  guardarMotivo: PropTypes.func,
};
