// React
import PropTypes from "prop-types";

// @mui material components
import {
  Card,
  Fade,
  Icon,
  Modal,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { formatMoneyPunto } from "utils/formatters";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", lg: "50%",xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalDiferenciaMonto({ open, handleClose,montos}) {


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>            
              <SoftBox display="flex" flexDirection="column" gap={1.5} justifyContent="center" alignItems="center" pt={1} pb={2}>
                <SoftTypography variant="h4" fontWeight="bold">
                  Diferencia de importes
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="regular" color="secondary" textAlign="center" sx={{width: "90%"}} lineHeight={1.25}>
                    Se ha detectado una diferencia de montos entre el <strong>importe del presupuesto aprobado</strong> y la <strong>suma de los conceptos.</strong>
                    <br/>Verifique que los importes sean correctos.
                </SoftTypography>

                <SoftTypography variant="h6" fontWeight="regular" color="secondary" textAlign="center" sx={{width: "90%"}} lineHeight={1.35}>
                    Presupuesto aprobado: <strong>${formatMoneyPunto(montos?.presupuesto)}</strong>
                    <br/>
                    Total suma Conceptos: <strong>${formatMoneyPunto(montos?.conceptos)}</strong>
                </SoftTypography>
                
              </SoftBox>

              <SoftBox display="flex" justifyContent="center" mt={1}>
                <SoftButton 
                onClick={handleClose} 
                color="sistemasAmarillo"
                circular
                >
                 Aceptar
                </SoftButton>
              </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalDiferenciaMonto.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  montos: PropTypes.object
};
