import axios from "axios";

export const getIncidentes = async (idUser) => {
  let respuesta = [];

  await axios
    .post("https://tickets.esamba.online/sistema-tickets-api/public/api/tareasProyectoT", {
      idProyecto: "13",
      estadoTarea: "Incidente",
      idUsuarioInterno: idUser || "",
    })
    .then((response) => {
      respuesta = response.data.data;
    });

  return respuesta;
};

export const addIncidente = async (data) => {
  let respuesta = {};

  await axios
    .post("https://tickets.esamba.online/sistema-tickets-api/public/api/tareas", data)
    .then((response) => {
      respuesta = {
        mensaje: "Incidente agregado con éxito, nuestro equipo lo revisará en breve",
        estado: response.data.res,
        tarea: response.data.data,
      };
    })
    .catch(() => {
      respuesta = {
        mensaje: "Ha ocurrido un error al agregar el incidente",
        estado: response.data.res,
      };
    });

  return respuesta;
};

export const getModulos = async () => {
  let respuesta = [];

  await axios
    .get("https://tickets.esamba.online/sistema-tickets-api/public/api/pantallasProyecto/13")
    .then((response) => {
      respuesta = response.data.data;
    });

  return respuesta;
};

export const addDocumento = async (documento, idTarea) => {
  let respuesta = {};

  const data = {
    file: documento.file,
    tipoArchivo: "Cliente",
    idTarea: idTarea,
    nombreArchivo: documento.nombre,
  };

  await axios
    .post(
      "https://tickets.esamba.online/sistema-tickets-api/public/api/uploading-file-api",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      respuesta = response;
    })
    .catch(() => {
      respuesta = {
        mensaje: "Ha ocurrido un error al agregar el documento",
        estado: false,
      };
    });

  return respuesta;
};

export const getDocumentos = async (idTarea) => {
  let respuesta = [];

  await axios
    .post("https://tickets.esamba.online/sistema-tickets-api/public/api/filesTareas", {
      idTarea: idTarea,
      tipoArchivo: "Cliente",
    })
    .then((response) => {
      respuesta = response.data.files;
    });

  return respuesta;
};


export const deleteDocumento = async (id) => {
  let respuesta = {};

  await axios
    .post("https://tickets.esamba.online/sistema-tickets-api/public/api/deleteFile", {
      dateDel: new Date().toISOString().slice(0, 19).replace("T", " "),
      id: id,
    })
    .then((response) => {
      respuesta = {
        mensaje: "Documento eliminado con éxito",
        estado: response.success,
      }
    }).catch(() => {
      respuesta = {
        mensaje: "Ha ocurrido un error al eliminar el documento",
        estado: false,
      }
    });

  return respuesta;
};