// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { getUproColor } from "utils/colors";
import { validateEmail, validateUsername } from "utils/validations";
// import ModalAddPlantilla from "../ModalAddPlantilla";
import { UserContext } from "context/user";
import { validateDNI } from "utils/validations";
import { validateCUIL } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalClientes({ open, handleClose, refetch, selectedUsuario }) {
  const [cliente, setCliente] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenuPlantilla, setOpenMenuPlantilla] = useState(false);
  const { confirmar } = useContext(ConfirmarContext);
  const { verificarAcceso } = useContext(UserContext);

  const [edit,setEdit] = useState(false);

  const handleChanges = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };

  const { data: clientes } = useQuery(
    gql`
      query getClientes {
        clientes {
          id
         
        }
      }
    `
  );

  const [saveCliente, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveCliente($input: ClienteInput!) {
        saveCliente(input: $input) {
          id
        }
      }
    `
  );

  const [restoreTempPassword] = useMutation(
    gql`
      mutation restoreTempPassword($id: ID!) {
        restoreTempPassword(id: $id) {
          id
          tempPassword
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (cliente?.provincia) {
      getLocalidades({ variables: { provincia: cliente?.provincia } }).then((data) => {
        // Si la localidad no pertenece a la provincia seleccionada, se limpia el campo
        if (!data?.data?.localidades?.find((localidad) => localidad.id === cliente?.localidad)) {
          setCliente({
            ...cliente,
            localidad: -1,
          });
        }
      });
    }
  }, [cliente?.provincia]);
  
  const handleSave = () => {
    
    saveCliente({
          variables: {
            input: {
                id:  cliente.id || null,
                tipoCliente: cliente?.tipoCliente,
                razonSocial: cliente?.razonSocial,
                nombre: cliente?.nombre,
                apellido: cliente.apellido,
                tipoDocumento: cliente.tipoDocumento,
                documento: cliente.documento,
                domicilio: cliente.domicilio,
                telefono: cliente.telefono,
                correo: cliente.correo,
                idProvincia: cliente.provincia,
                idLocalidad: cliente.localidad
            },
          },
    })
      .then(() => {
            refetch();
            if(edit){
                handleSnackbar("Cliente editado correctamente", "success");
            }else{
                handleSnackbar("Cliente guardado correctamente", "success");
            }
            handleClose();
      })
      .catch((e) => {
            handleSnackbar("Error al guardar el cliente", "error");
      });
    
  };
 
  useEffect(() => {
    if (selectedUsuario) {
      setEdit(true);
      setCliente({
        id: selectedUsuario.id,
        nombre: selectedUsuario.nombre,
        apellido: selectedUsuario.apellido,
        tipoDocumento: selectedUsuario.tipoDocumento,
        documento: selectedUsuario.documento,
        domicilio: selectedUsuario.domicilio,
        telefono: selectedUsuario.telefono,
        correo: selectedUsuario.correo,
        provincia: selectedUsuario.localidad?.provincia?.id,
        localidad: selectedUsuario.localidad?.id
      });
    } else {
      setEdit(false);
      setCliente();
    }
  }, [selectedUsuario, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Cliente</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={3} pt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="tipoCliente">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Tipo de cliente
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="tipoCliente"
                    value={cliente?.tipoCliente || -1}
                    onChange={(e)=>{
                      setCliente({
                        ...cliente,
                        tipoCliente: e.target.value,
                        razonSocial: "",
                        nombre: "",
                        apellido: "",
                      });
                    }}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value="Particular">Particular</MenuItem>
                    <MenuItem value="Empresa">Empresa</MenuItem>
                  </Select>
                </Grid>
                {cliente?.tipoCliente === "Empresa" ? (
                  <Grid item xs={12} sm={8}>
                    <InputLabel htmlFor="razonSocial">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Razon Social
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="razonSocial"
                      placeholder="Inserte la razón social"
                      type="text"
                      name="razonSocial"
                      value={cliente?.razonSocial || ""}
                      onChange={handleChanges}
                    />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="nombre">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nombre
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                        <SoftInput
                          label="nombre"
                          placeholder="Inserte el nombre de cliente"
                          type="text"
                          name="nombre"
                          value={cliente?.nombre || ""}
                          error={ cliente?.nombre?.length > 0  && cliente?.nombre.trim() == "" }
                          onChange={handleChanges}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputLabel htmlFor="apellido">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Apellido
                          <SoftTypography variant="caption" fontWeight="bold" color="primary">
                            &nbsp;*
                          </SoftTypography>
                        </SoftTypography>
                      </InputLabel>
                        <SoftInput
                          label="apellido"
                          placeholder="Inserte el apellido de cliente"
                          type="text"
                          name="apellido"
                          value={cliente?.apellido || ""}
                          error={ cliente?.apellido?.length > 0  && cliente?.apellido.trim() == "" }
                          onChange={handleChanges}
                        />
                    </Grid>               
                  </>
                )}

                <Grid item xs={4} sm={3}>
                    <InputLabel htmlFor="tipoDocumento">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Tipo de documento
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <Select
                      name="tipoDocumento"
                      value={cliente?.tipoDocumento || -1}
                      onChange={handleChanges}
                    >
                      <MenuItem value={-1} disabled>
                        Seleccione uno
                      </MenuItem>
                      <MenuItem value={96}>DNI</MenuItem>
                      <MenuItem value={80}>CUIT</MenuItem>
                      <MenuItem value={86}>CUIL</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={8} sm={4}>
                    <InputLabel htmlFor="documento">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Documento
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="documento"
                      placeholder="Inserte el documento del proveedor"
                      type="text"
                      name="documento"
                      value={cliente?.documento || ""}
                      error={
                        cliente?.tipoDocumento === 96 && cliente?.documento?.length > 0
                          ? !validateDNI(cliente?.documento)
                          : cliente?.documento?.length > 0 && !validateCUIL(cliente?.documento)
                      }
                      onChange={handleChanges}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <InputLabel htmlFor="telefono">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Telefono
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="telefono"
                      placeholder="Inserte el telefono de cliente"
                      type="text"
                      name="telefono"
                      value={cliente?.telefono || ""}
                      error={ cliente?.telefono?.length > 0 && cliente?.telefono.trim() == "" }
                      onChange={handleChanges}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email
                    </SoftTypography>
                  </InputLabel>
                  <Tooltip
                    placement="bottom"
                    open={(cliente?.correo?.length > 0 && !validateEmail(cliente?.correo))}
                    title={
                      cliente?.correo?.length > 0 && !validateEmail(cliente?.correo)
                        ? "Ingrese un email válido"
                        : "Ingrese un email"
                    }
                  >
                    <SoftInput
                      label="correo"
                      placeholder="Inserte el email de cliente"
                      type="text"
                      name="correo"
                      value={cliente?.correo || ""}
                      error={
                        cliente?.correo?.length > 0 &&
                        (!validateEmail(cliente?.correo) ||
                          clientes?.clientes.find((u) => u.correo === cliente?.correo))
                      }
                      onChange={handleChanges}
                    //   disabled={selectedUsuario?.correo}
                    //   readOnly={selectedUsuario?.username ? true : false}
                    />
                  </Tooltip>
                </Grid>      
                <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="domicilio">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Domicilio
                        </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="domicilio"
                      placeholder="Inserte el domicilio del cliente"
                      type="text"
                      name="domicilio"
                      value={cliente?.domicilio || ""}
                      onChange={handleChanges}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={cliente?.provincia || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={cliente?.localidad || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="sistemasGris"
                    circular
                    disabled={
                      loadingSave || (selectedUsuario
                        ? 
                          !cliente?.tipoCliente ||
                          (
                            cliente?.tipoCliente == "Empresa" &&
                            !cliente?.razonSocial
                          ) ||
                          (
                            cliente?.tipoCliente == "Particular" &&
                            !cliente?.nombre &&
                            !cliente?.apellido
                          ) ||
                          !cliente?.tipoDocumento ||
                          !cliente?.documento ||
                          (
                            // cliente?.nombre === selectedUsuario.nombre &&
                            // cliente?.apellido === selectedUsuario.apellido &&
                            cliente?.tipoDocumento === selectedUsuario.tipoDocumento &&
                            cliente?.documento === selectedUsuario.documento &&
                            cliente?.domicilio === selectedUsuario.domicilio &&
                            cliente?.provincia === selectedUsuario.localidad?.provincia?.id &&
                            cliente?.localidad === selectedUsuario.localidad?.id &&
                            cliente?.correo === selectedUsuario.correo && 
                          cliente?.telefono === selectedUsuario.telefono)
                        : 
                          !cliente?.tipoCliente ||
                          (
                            cliente?.tipoCliente == "Empresa" &&
                            !cliente?.razonSocial
                          ) ||
                          (
                            cliente?.tipoCliente == "Particular" &&
                            !cliente?.nombre &&
                            !cliente?.apellido
                          ) ||
                          !cliente?.tipoDocumento ||
                          !cliente?.documento ||
                          (cliente?.tipoDocumento === 96 && cliente?.documento?.length > 0
                            ? !validateDNI(cliente?.documento)
                            : cliente?.documento?.length > 0 &&
                              !validateCUIL(cliente?.documento))
                        )
                    }
                    onClick={handleSave}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalClientes.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedUsuario: PropTypes.object,
};
