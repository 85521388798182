/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getUproColor } from "utils/colors";
import dayjs from "dayjs";

const getRows = (data, handleSelect) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={item.tipo} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color:
                      item.tipo === "Merma"
                        ? getUproColor("sistemasAmarillo")
                        : item.tipo === "Devolucion Proveedor"
                        ? getUproColor("sistemasAmarillo")
                        : getUproColor("sistemasGris"),
                  }}
                  fontSize="sm"
                >
                  {item.tipo === "Merma"
                    ? "arrow_downward"
                    : item.tipo === "Devolucion Proveedor"
                    ? "undo"
                    : "drive_file_rename_outline"}
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        tipo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item.tipo}
            </SoftTypography>
          </SoftBox>
        ),
        sucursal: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.tipo.includes("Ajuste")
                ? item?.sucursalDestino?.nombre + " - " + item?.sucursalDestino?.sede?.nombre
                : item?.sucursalOrigen?.nombre + " - " + item?.sucursalOrigen?.sede?.nombre}
            </SoftTypography>
          </SoftBox>
        ),
        cantidad: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.movimientoDetalles.length + " productos"}
            </SoftTypography>
          </SoftBox>
        ),
        user: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.user?.username}
            </SoftTypography>
          </SoftBox>
        ),
        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {dayjs(item?.createdAt).format("DD/MM/YYYY")}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => handleSelect(item)}
          >
            <Tooltip title="Ver detalles" placement="top">
              <Icon
                sx={{ color: getUproColor("sistemasGris"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={() => handleSelect(item)}
              >
                arrow_forward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "tipo", desc: "Tipo", align: "left" },
  { name: "sucursal", desc: "Deposito", align: "left", orderField: "idSucursalDestino"},
  { name: "cantidad", desc: "Cantidad de productos", align: "center", noOrder: true},
  { name: "user", desc: "Creado por", align: "left", orderField: "idUser"},
  { name: "fecha", desc: "Fecha", align: "left", orderField: "createdAt"},
  { name: "accion", desc: " ", align: "center", width: "1%" },
];

export default { columns, getRows };
