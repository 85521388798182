// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { MenuContext } from "context/menuContext";
import Fases from "../Fases";
import ProyectoDetalles from "../ProyectoDetalles";
import Cotizacion from "../Cotizacion";
import { validateEmail } from "utils/validations";
import PresupuestoPDF from "../PDF/Presupuesto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalVerPresupuesto({ open, handleClose, idProyecto }) {
  const [mensaje, setMensaje] = useState({
    asunto: `Presupuesto`,
    mensaje: `Hola, te adjunto el presupuesto para que lo revises y me des tu opinión.`,
  });
  const [file, setFile] = useState(null);
  const [proyecto, setProyecto] = useState(null);
  const [presupuesto, setPresupuesto] = useState(null);

  const [getPresupuesto, { data: presupuestoData }] = useLazyQuery(gql`
    query getPresupuesto($id: ID!) {
      presupuesto(id: $id) {
        id
        numero
        version
        ocultarFases
        notasExtra
        proyecto {
          id
          cliente {
            id
            nombre
            apellido
            tipoCliente
            razonSocial
          }
          costoEstimado
          descripcion
          direccion
          fechaInicioEstimada
          fechaFinEstimada
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
          nombre
        }
        fases {
          id
          cantidad
          descripcion
          fechaFinEstimado
          fechaInicioEstimado
          nombre
          nombreUnidad
          porcentajeGanancia
          subtotal
          etapa{
            id
            nombre
          }
          manoDeObra {
            id
            horas
            nombre
            precio
          }
          materiales {
            id
            cantidad
            nombre
            precio
            producto {
              id
              nombre
            }
          }
          concepto {
            id
            precio
          }
          presupuesto{
            id
            aprobado
            precio
          }
        }
      }
    }
  `);

  const handleEtapas = (dataFases)=>{
    let etapas = [];
    let fases = [];

    dataFases.forEach((fase) => {
      fases.push({
        ...fase,
        idEtapa: fase?.etapa?.id
      });

      if (!etapas.some(etapa => etapa.id === fase.etapa.id)) {
        etapas.push({
          id: fase.etapa.id,
          nombre: fase.etapa.nombre
        });
      }
    });

    etapas.sort((a, b) => a.id - b.id);

    return {
      etapas: etapas,
      fases: fases
    };
  }

  useEffect(() => {
    if (open && idProyecto) {
      getPresupuesto({ variables: { id: idProyecto } }).then((data) => {
        let dataEtapaFases = handleEtapas(data?.data?.presupuesto?.fases);
        setProyecto({
            ...data?.data?.presupuesto?.proyecto,
            ...data?.data?.presupuesto,
            ...dataEtapaFases,
        });
        setPresupuesto(data?.data?.presupuesto);
      });
    }
  }, [open, idProyecto]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Enviar presupuesto para firmar</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12} sm={5}>
                <Card>
                  <SoftBox p={3}>
                    <Grid container spacing={2} alignItems="flex-start">
                      <Grid item xs={12}>
                        <InputLabel htmlFor="destinatario">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Destinatario
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          label="destinatario"
                          placeholder="Ingrese el email del destinatario"
                          type="email"
                          error={
                            mensaje?.destinatario?.length > 0 &&
                            !validateEmail(mensaje?.destinatario)
                          }
                          name="destinatario"
                          value={mensaje?.destinatario}
                          onChange={(e) => setMensaje({ ...mensaje, destinatario: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="asunto">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Asunto
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          label="asunto"
                          placeholder="Ingrese el asunto del mensaje"
                          type="text"
                          name="asunto"
                          value={mensaje?.asunto}
                          onChange={(e) => setMensaje({ ...mensaje, asunto: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="mensaje">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Mensaje
                          </SoftTypography>
                        </InputLabel>
                        <SoftInput
                          label="mensaje"
                          placeholder="Ingrese el mensaje"
                          type="text"
                          multiline
                          rows={4}
                          name="mensaje"
                          value={mensaje?.mensaje}
                          onChange={(e) => setMensaje({ ...mensaje, mensaje: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SoftButton color="primary" circular fullWidth>
                          Enviar&nbsp;<Icon>send</Icon>
                        </SoftButton>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftButton
                          color="sistemasGris"
                          circular
                          fullWidth
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(file);
                            link.download = `Presupuesto-${proyecto?.nombre}.pdf`;
                            link.click();
                          }}
                        >
                          Descargar PDF&nbsp;<Icon>download</Icon>
                        </SoftButton>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </Card>
              </Grid>
              <Grid item xs={12} sm={7}>
                {proyecto && <PresupuestoPDF 
                proyecto={proyecto} 
                presupuesto={presupuesto}
                setFile={setFile} />}
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalVerPresupuesto.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  idProyecto: PropTypes.string,
};
