// React
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

// Custom UI components
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { getUproColor } from "utils/colors";
import TableItem from "./TableItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflow: "auto",
  p: 3,
};

const columnsBase = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "codigo", desc: "Código", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "observacion", desc: "Observacion", align: "left" },
  { name: "precioEstimado", desc: "Precio estimado", align: "center" },
  { name: "cantidad", desc: "cantidad", align: "center", width: "100px" },
];

const columnsVerificacion = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "codigo", desc: "Código", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidadSolicitada", desc: "Cantidad solicitada", align: "center", width: "100px" },
  { name: "cantidadVerificada", desc: "Cantidad verificada", align: "center", width: "100px" },
];

const columnsVerificacionEdit = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "codigo", desc: "Código", width: "1%", align: "left" },
  { name: "nombre", desc: "Nombre", align: "left" },
  { name: "categoria", desc: "Rubro", align: "left" },
  { name: "cantidadSolicitada", desc: "Cantidad solicitada", align: "center", width: "100px" },
  { name: "stock", desc: "Stock", align: "center", width: "100px" },
  { name: "cantidadVerificada", desc: "Cantidad verificada", align: "center", width: "100px" },
];

export default function ModalSolicitud({
  open,
  handleClose,
  selectedSolicitud,
  idSucursal,
  refetchSolicitudes,
  editMode,
}) {
  const [productoToAdd, setProductoToAdd] = useState();
  const [solicitud, setSolicitud] = useState();
  const [productosSolicitud, setProductosSolicitud] = useState([]);
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;
  const { handleSnackbar } = useContext(MessageManager);
  const [getProductos, { data: productos, loading }] = useLazyQuery(
    gql`
      query getProductos($page: Int, $order: Order, $filter: JSON, $idSucursal: ID) {
        paginationInfo {
          pages
          total
        }
        productos(pagination: { page: $page, limit: 50 }, order: $order, filter: $filter) {
          id
          codigo
          tipo
          categoria
          nombre
          disabled
          stock(idSucursal: $idSucursal) {
            cantidad
            sucursal {
              id
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
    }
  );

  const [verificarSolicitud, { loading: loadingSave }] = useMutation(gql`
    mutation verificarSolicitud($input: SolicitudVerificacionInput!) {
      verificarSolicitud(input: $input) {
        id
      }
    }
  `);

  const handleChanges = (event) => {
    setSolicitud({ ...solicitud, [event.target.name]: event.target.value });
  };

  const handleAddProduct = (product) => {
    setProductosSolicitud([...productosSolicitud, product]);
  };

  const handleUpdateMaterial = (material) => {
    setProductosSolicitud(
      productosSolicitud.map((item) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      })
    );
  };

  const renderColumns = (columns) => {
    return columns.map(({ name, desc, align, width }, key) => {
      let pl;
      let pr;

      if (key === 0) {
        pl = 3;
        pr = 3;
      } else if (key === columns.length - 1) {
        pl = 3;
        pr = 3;
      } else {
        pl = 1;
        pr = 1;
      }

      return (
        <SoftBox
          key={name}
          component="th"
          width={width || "auto"}
          pt={1.5}
          pb={1.25}
          pl={align === "left" ? pl : 3}
          pr={align === "right" ? pr : 3}
          textAlign={align}
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          borderBottom={`${borderWidth[1]} solid ${light.main}`}
        >
          {desc ? desc.toUpperCase() : name.toUpperCase()}
        </SoftBox>
      );
    });
  };

  useEffect(() => {
    setSolicitud();
    setProductosSolicitud([]);
    if (selectedSolicitud && open) {
      if (selectedSolicitud?.vencimiento) {
        setSolicitud({
          vencimiento: selectedSolicitud.vencimiento,
        });
      }
      getProductos({
        variables: {
          page: 1,
          filter: {
            disabled: false,
            id: selectedSolicitud.solicitudVerificacionDetalles.map((item) => item.producto.id),
            _stock: {
              idSucursal: idSucursal,
            },
          },
          idSucursal: idSucursal,
        },
      }).then((data) => {
        let arrayData =
          selectedSolicitud.solicitudVerificacionDetalles || selectedSolicitud.solicitudDetalles;
        let productosCargados = arrayData
          .filter((item) => item.cantidad > 0 || item.cantidadSolicitada > 0)
          .map((item) => ({
            ...item.producto,
            cantidad: item.cantidad,
            cantidadSolicitada: item.cantidadSolicitada,
            cantidadVerificada: item.cantidadVerificada,
            idDetalle: item.id,
            stock: {
              cantidad: data.data.productos.find((producto) => producto.id === item.producto.id)
                ? data.data.productos.find((producto) => producto.id === item.producto.id).stock
                    ?.cantidad
                : 0,
            },
            observaciones: item.observaciones,
            precioEstimado: item.precioEstimado,
          }));

        setProductosSolicitud(productosCargados);
      });
    }
  }, [open]);

  useEffect(() => {
    if (productoToAdd && productos?.productos?.length > 0) {
      if (productos.productos.find((producto) => producto.id === productoToAdd)) {
        handleAddProduct(productos.productos.find((producto) => producto.id === productoToAdd));
      }
      setProductoToAdd();
    }
  }, [productos]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={3}
                  pb={0}
                >
                  <SoftTypography variant="h6">{`${
                    selectedSolicitud?.__typename === "SolicitudVerificacion"
                      ? "Solicitud de Verificacion"
                      : "Solicitud de Compra"
                  } Nº${selectedSolicitud?.id} - Creada por ${
                    selectedSolicitud?.user?.username
                  }`}</SoftTypography>
                  <SoftBox>
                    <Tooltip title="Cerrar" placement="top">
                      <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                        close
                      </Icon>
                    </Tooltip>
                  </SoftBox>
                </SoftBox>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={
                        solicitud?.tipo === "Prestamo" ||
                        (selectedSolicitud && selectedSolicitud.tipo !== "Prestamo")
                          ? 6
                          : selectedSolicitud
                          ? 4
                          : 12
                      }
                    >
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Tipo
                          {!selectedSolicitud && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      <SoftBox>
                        {selectedSolicitud ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedSolicitud?.tipo}
                          </SoftTypography>
                        ) : (
                          <Select
                            name="tipo"
                            value={solicitud?.tipo || ""}
                            onChange={handleChanges}
                          >
                            <MenuItem value={"Prestamo"}>Prestamo</MenuItem>
                            <MenuItem value={"Transferencia"}>Transferencia</MenuItem>
                          </Select>
                        )}
                      </SoftBox>
                    </Grid>
                    {selectedSolicitud && (
                      <Grid item xs={12} md={6}>
                        <InputLabel htmlFor="observaciones">
                          <SoftTypography component="label" variant="caption" fontWeight="bold">
                            Estado
                          </SoftTypography>
                        </InputLabel>
                        <SoftBox>
                          <SoftTypography
                            variant="body2"
                            fontWeight="medium"
                            sx={{
                              color:
                                selectedSolicitud?.estado === "Verificado"
                                  ? getUproColor("sistemasGris")
                                  : getUproColor("sistemasAmarillo"),
                            }}
                          >
                            <Icon sx={{ fontWeight: "light" }}>
                              {selectedSolicitud?.estado === "Verificado"
                                ? "verified"
                                : "pending_actions"}
                            </Icon>
                            &nbsp;
                            {selectedSolicitud?.estado}
                          </SoftTypography>
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <InputLabel htmlFor="observaciones">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Descripción
                          {!selectedSolicitud && (
                            <SoftTypography variant="caption" fontWeight="bold" color="primary">
                              &nbsp;*
                            </SoftTypography>
                          )}
                        </SoftTypography>
                      </InputLabel>
                      <SoftBox>
                        {selectedSolicitud ? (
                          <SoftTypography variant="h6" fontWeight="bold">
                            {selectedSolicitud?.observaciones}
                          </SoftTypography>
                        ) : (
                          <SoftInput
                            label="observaciones"
                            placeholder="Ingrese una descripción de por qué y para qué se solicitan los productos."
                            type="text"
                            name="observaciones"
                            value={solicitud?.observaciones || ""}
                            onChange={handleChanges}
                            multiline
                            fullWidth
                            rows={4}
                          />
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox>
              <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <SoftBox>
                    <SoftTypography variant="h6">Productos</SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                      "& td": {
                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      },
                    },
                  }}
                >
                  {productosSolicitud.length > 0 ? (
                    <TableContainer>
                      <Table>
                        <SoftBox component="thead">
                          <TableRow>
                            {renderColumns(
                              editMode && selectedSolicitud?.estado !== "Verificado"
                                ? columnsVerificacionEdit
                                : selectedSolicitud?.__typename === "SolicitudVerificacion"
                                ? columnsVerificacion
                                : columnsBase
                            )}
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {productosSolicitud.map((material) => {
                            return (
                              <TableItem
                                key={material.id}
                                material={material}
                                tipo={selectedSolicitud?.__typename}
                                editMode={editMode && selectedSolicitud?.estado !== "Verificado"}
                                handleUpdateMaterial={handleUpdateMaterial}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <SinDatos />
                  )}
                </SoftBox>
              </Card>
            </SoftBox>
            {editMode && selectedSolicitud?.estado !== "Verificado" && (
              <SoftBox display="flex" justifyContent="flex-end" mt={2}>
                <SoftButton
                  color="sistemasGris"
                  circular
                  onClick={() => {
                    let detalles = productosSolicitud.map((item) => ({
                      id: item.idDetalle,
                      idProducto: item.id,
                      cantidadVerificada: item.cantidadVerificada,
                    }));
                    verificarSolicitud({
                      variables: {
                        input: {
                          id: selectedSolicitud.id,
                          solicitudVerificacionDetalle: detalles,
                        },
                      },
                    })
                      .then(() => {
                        handleSnackbar("Solicitud verificada correctamente", "success");
                        refetchSolicitudes();
                        handleClose();
                      })
                      .catch((e) => {
                        console.log(e);
                        handleSnackbar("Ocurrió un error al verificar la solicitud", "error");
                      });
                  }}
                  disabled={
                    loadingSave || productosSolicitud.some((item) => !item.cantidadVerificada)
                  }
                >
                  {loadingSave ? (
                    <CircularProgress size={15} color="white" />
                  ) : (
                    <Icon>done_all</Icon>
                  )}
                  &nbsp; Verificar
                </SoftButton>
              </SoftBox>
            )}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalSolicitud.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedSolicitud: PropTypes.object,
  idSucursal: PropTypes.string || PropTypes.number,
  refetchSolicitudes: PropTypes.func,
  editMode: PropTypes.bool,
};
